import type { StandardPaginationParams } from '$lib/types';
import { createMutation, createQuery, useQueryClient } from '@tanstack/svelte-query';
import { QUERY_KEYS } from '$lib/queries/keys';
import { toQueryString } from '$lib/utils';
import * as api from '$lib/api';
import { ProjectContactStatus, ProjectStatus } from '$lib/constants';

type ContributingProject = {
  ref: string;
  name: string;
  status: ProjectStatus;
  deadline?: string;
  description: string;
  type: string;
  meeting_link?: string;
  outlet: string;
  outlet_link?: string;
  outlet_viewership?: number;
  user__ref: string;
  user__name: string;
  user__country: string;
  user__job_title?: string;
  user__company_name?: string;
  user__avatar_url?: string;
  created_at: string;
};

type ContributingProjectContributor = {
  ref: string;
  project?: ContributingProject;
  status: ProjectContactStatus;
  submission?: string;
  decline_reason?: string;
  hide_company_name: boolean;
};

export const getPendingContributions = (
  enabled: boolean,
  { limit, page }: StandardPaginationParams = {
    limit: 5,
    page: 1
  }
) =>
  createQuery({
    enabled,
    queryKey: [...QUERY_KEYS.CONTRIBUTIONS_PENDING, { limit, page }],
    queryFn: async () => {
      const qs = toQueryString({
        limit: limit.toString(),
        page: page.toString()
      });
      return api.get<{
        items: ContributingProjectContributor[];
        total_count: number;
        has_any_requests: boolean;
      }>(`/engagements/projects/contributions/pending?${qs}`);
    }
  });

export const getContributions = ({ limit, page, sort, sort_direction }: StandardPaginationParams) =>
  createQuery({
    queryKey: [...QUERY_KEYS.CONTRIBUTIONS_PENDING, { limit, page, sort, sort_direction }],
    queryFn: async () => {
      const qs = toQueryString({
        limit: limit.toString(),
        page: page.toString(),
        sort,
        sort_direction
      });
      return api.get<{
        items: ContributingProjectContributor[];
        total_count: number;
      }>(`/engagements/projects/contributions?${qs}`);
    }
  });

export const getContributionDetails = (ref: string) =>
  createQuery({
    queryKey: [...QUERY_KEYS.CONTRIBUTIONS_DETAILS, ref],
    queryFn: async () =>
      api.get<ContributingProjectContributor>(`/engagements/projects/contributions/${ref}`)
  });

export const setContributionStatus = (ref: string) => {
  const queryClient = useQueryClient();
  return createMutation({
    mutationKey: [...QUERY_KEYS.CONTRIBUTIONS_DETAILS, ref],
    mutationFn: async (payload: { status: ProjectContactStatus; decline_reason?: string }) => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await api.post<void>(`/engagements/projects/contributions/${ref}/status-change`, payload);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: [...QUERY_KEYS.CONTRIBUTIONS_DETAILS, ref] });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CONTRIBUTIONS_PENDING });
    }
  });
};

export const submitContribution = (ref: string) => {
  const queryClient = useQueryClient();
  return createMutation({
    mutationKey: [...QUERY_KEYS.CONTRIBUTIONS_DETAILS, ref],
    mutationFn: async (payload: { submission: string; hide_company_name: boolean }) =>
      api.post<void>(`/engagements/projects/contributions/${ref}/submission`, payload),
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: [...QUERY_KEYS.CONTRIBUTIONS_DETAILS, ref] });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CONTRIBUTIONS_PENDING });
    }
  });
};

export const sendProjectContributionMessage = (ref: string) => {
  const queryClient = useQueryClient();
  return createMutation({
    mutationKey: QUERY_KEYS.INBOX_SEND_MESSAGE,
    mutationFn: async (content: string) =>
      api.post<void>(`/engagements/projects/contributions/${ref}/message`, {
        content
      }),
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.INBOX_CONVERSATIONS });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.INBOX_USER_MESSAGES });
    }
  });
};
