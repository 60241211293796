<script lang="ts">
  import MainBorder from '$lib/components/MainBorder.svelte';
  import FieldBlock from '$lib/components/FieldBlock.svelte';
  import { getContributorQuery } from '$lib/queries/contributors';

  import { getProjectQuery } from '$lib/queries/projects';
  import Profile from '$lib/components/Profiles/Profile.svelte';

  export let data;
  $: projectRef = data.params.ref;
  $: contributorRef = data.params.contributorRef;
  $: project = getProjectQuery(projectRef);
  $: profile = getContributorQuery(contributorRef);

  $: contributionStatus = $project.data?.shortlist.find(
    (item) => item.ref === `contributor:${contributorRef}`
  )?.status;
  $: backLink = `/projects/${projectRef}/shortlist`;
</script>

{#if $profile.data}
  <Profile userRef={$profile.data?.user__ref} {projectRef} {contributionStatus} {backLink} />
{:else}
  <MainBorder wide>
    <FieldBlock loading />
  </MainBorder>
{/if}
