<script lang="ts">
  import { Label } from '@smui/button';
  import Avatar from '$lib/components/Images/Avatar.svelte';
  import { myProfilePhotoQuery } from '$lib/queries/users';
  import LoadingButton from '$lib/components/LoadingButton.svelte';
  import {
    croppableAvatarFileStore,
    croppableAvatarMutationPending
  } from '$lib/components/Images/utils';

  let photoFiles: FileList | undefined | null;
  const profilePhotoQuery = myProfilePhotoQuery();

  $: if (photoFiles?.length) {
    croppableAvatarFileStore.set(photoFiles[0]);
    photoFiles = null;
  }
</script>

<div class="row" id="avatar">
  <div class="col upload-button-parent">
    <Avatar
      src={$profilePhotoQuery.data}
      hasAvatar={!!$profilePhotoQuery.data}
      forceLoading={$profilePhotoQuery.isLoading || $croppableAvatarMutationPending}
    />
    <LoadingButton
      loading={$croppableAvatarMutationPending}
      variant="outlined"
      tag="label"
      class="upload-button"
      style="margin-left: 1em"
    >
      <Label>
        Upload photo
        <input bind:files={photoFiles} type="file" style="display: none" accept="image/*" /></Label
      >
    </LoadingButton>
  </div>
</div>

<style>
  .upload-button-parent {
    display: flex;
    align-items: center;
  }
</style>
