<script lang="ts">
  import { formatMessageContent, toReadableDateTime } from '$lib/utils';
  import UserTextField from '$lib/components/UserTextField.svelte';
  import LoadingButton from '$lib/components/LoadingButton.svelte';
  import IconButton from '@smui/icon-button';
  import FieldBlock from '$lib/components/FieldBlock.svelte';
  import {
    getUserMessagesQuery,
    markMessagesAsReadMutation,
    type Message
  } from '$lib/queries/messaging';
  import * as auth from '$lib/auth';
  import { getMyUserData } from '$lib/queries/users';
  import { type CreateBaseMutationResult } from '@tanstack/svelte-query';
  import { type Readable } from 'svelte/store';
  import { ApiError } from '$lib/api/errors';
  import Badge from '@smui-extra/badge';

  const myDataQuery = getMyUserData();
  let message: string = '';

  export let topPadding = false;
  export let userRef: string | undefined;
  export let userName: string | undefined;
  export let mutation: Readable<CreateBaseMutationResult<void, Error | ApiError, string>>;

  let lastLoadedUserRef: string | undefined;
  let previousLastMessageRefs: Array<undefined | string> = [];
  let lastMessageRef: string | undefined = undefined;

  $: messagesQuery = getUserMessagesQuery(
    { last_ref: lastMessageRef },
    auth.isUnverifiedUser(userRef) ? undefined : userRef
  );
  $: markAsReadMutation = markMessagesAsReadMutation();

  $: if (userRef !== lastLoadedUserRef) {
    lastLoadedUserRef = userRef;
    previousLastMessageRefs = [];
    lastMessageRef = undefined;
  }

  let unreadMessageToBeCleared: Message | undefined;
  let markAsReadTimeoutId: any | undefined = undefined;
  $: unreadMessage = $messagesQuery.data?.items.find(
    (item) => item.sender_ref === userRef && !item.read_by_recipient
  );
  $: if (unreadMessage && unreadMessage != unreadMessageToBeCleared) {
    unreadMessageToBeCleared = unreadMessage;
    clearTimeout(markAsReadTimeoutId);
    markAsReadTimeoutId = setTimeout(() => {
      if (unreadMessageToBeCleared) {
        $markAsReadMutation.mutate(unreadMessageToBeCleared.ref);
      }
    }, 3500);
  }

  const nextPageMessages = () => {
    const ref = $messagesQuery.data?.items.slice(-1)[0].ref;
    if (!ref) {
      return;
    }
    previousLastMessageRefs = [...previousLastMessageRefs, lastMessageRef];
    lastMessageRef = ref;
    clearTimeout(markAsReadTimeoutId);
  };

  const prevPageMessages = () => {
    lastMessageRef = previousLastMessageRefs.pop();
    previousLastMessageRefs = previousLastMessageRefs;
    clearTimeout(markAsReadTimeoutId);
  };

  const sendMessage = () => {
    $mutation.mutate(message, {
      onSuccess: () => {
        message = '';
        previousLastMessageRefs = [];
        lastMessageRef = undefined;
      }
    });
  };
  const refToName = (ref: string) => {
    if (ref === $myDataQuery.data?.user__ref) {
      return [$myDataQuery.data?.first_name, $myDataQuery.data?.last_name].join(' ');
    }
    return userName;
  };
</script>

<FieldBlock loading={$messagesQuery.isLoading} {topPadding}>
  {#if auth.isUnverifiedUser(userRef)}
    <p>Please verify your email address before you can use this feature.</p>
  {/if}
  {#if $messagesQuery.data}
    <form on:submit={sendMessage}>
      <div style="display:flex;justify-content: flex-end;align-items: flex-end;margin-bottom: 2em">
        <UserTextField
          type="text"
          label="Write a message"
          bind:value={message}
          on:submit={sendMessage}
          disabled={$mutation.isPending}
          required
        />
        <div>
          <LoadingButton loading={$mutation.isPending} class="justify-end">Send</LoadingButton>
        </div>
      </div>
    </form>
  {/if}
  <div class="message-container">
    {#each $messagesQuery.data?.items || [] as item (item.ref)}
      <div class="message">
        <p class="title">
          <strong>{refToName(item.sender_ref)}</strong>
          <span class="created-at">• {toReadableDateTime(item.created_at)}</span>
        </p>
        <p class="content">
          <!-- eslint-disable-next-line svelte/no-at-html-tags -->
          {@html formatMessageContent(item.content)}
        </p>
        {#if userRef === item.sender_ref && !item.read_by_recipient}
          <Badge
            class="unread-badge-identifier"
            style="background-color:rgb(121, 40, 202);left:-12px;bottom:32px;"
            position="inset"
            align="middle-start"
            aria-label="unread notification count"
          />
        {/if}
      </div>
    {/each}
  </div>

  {#if lastMessageRef || $messagesQuery.data?.has_more}
    <nav
      aria-label="Page navigation example"
      class="d-flex justify-content-center align-items-center"
    >
      <IconButton
        class="material-icons"
        action="prev-page"
        title="Prev page"
        on:click={prevPageMessages}
        disabled={previousLastMessageRefs.length === 0}
      >
        chevron_left
      </IconButton>
      <IconButton
        class="material-icons"
        action="next-page"
        title="Next page"
        on:click={nextPageMessages}
        disabled={!$messagesQuery.data?.has_more}
      >
        chevron_right
      </IconButton>
    </nav>
  {/if}
</FieldBlock>

<style lang="scss">
  .created-at {
    margin-top: 0;
    font-size: 0.8rem;
    color: #666;
  }

  .message-container {
    overflow-wrap: break-word;
  }

  .message {
    position: relative;

    .title {
      margin-bottom: 0;
    }

    .content {
      margin-top: 0;
    }
  }
</style>
