<script lang="ts">
  import loadingGif from '$lib/img/loading.gif';
  import { Icon } from '@smui/icon-button';

  export let src: string | undefined | null;
  export let large: boolean = false;
  export let small: boolean = false;
  export let hasAvatar = false;
  export let forceLoading = false;
  export let style = '';

  const alt = 'Your avatar used to show your profile on the site.';
</script>

{#if hasAvatar || forceLoading}
  <div
    style="background-image: url({loadingGif});{style}"
    class="placeholder"
    class:large
    class:small
  >
    {#if !forceLoading}
      <img
        class="avatar"
        {src}
        {alt}
        height="75"
        width="75"
        style="color: transparent;background-color:white"
      />
    {/if}
  </div>
{:else}
  <div {style} class="empty-avatar" class:large class:small>
    <Icon id="test" class="material-icons" style="">person</Icon>
  </div>
{/if}

<style lang="scss">
  .empty-avatar {
    padding: 0;
    margin: 0;
    height: 75px;
    width: 75px;

    :global(.material-icons) {
      border-radius: 999px;
      margin: 0;
      padding: 0;
      font-size: 75px;
      background-color: #e0e0e0;
      color: white;
    }

    &.large {
      width: 85px;
      height: 85px;

      :global(.material-icons) {
        font-size: 85px;
      }
    }
    &.small {
      width: 50px;
      height: 50px;

      :global(.material-icons) {
        font-size: 50px;
      }
    }
  }

  .placeholder {
    padding: 0;
    margin: 0;
    background-repeat: no-repeat;
    background-size: contain;
    height: 75px;
    width: 75px;

    img {
      height: 75px;
      width: 75px;
    }

    &.large {
      width: 85px;
      height: 85px;

      img {
        width: 85px;
        height: 85px;
      }
    }
    &.small {
      width: 50px;
      height: 50px;

      img {
        width: 50px;
        height: 50px;
      }
    }
  }
</style>
