<script>
  import Button, { Label } from '@smui/button';
  import { Content } from '@smui/card';
  import Skeleton from '$lib/components/Skeleton.svelte';
  import { goto } from '$app/navigation';
  import { DELETED_PLACEHOLDER, PROJECT_CONTACT_STATUSES } from '$lib/constants';
  import AQTable from '$lib/components/AQTable.svelte';
  import { onClickAndKeydown } from '$lib/directives';
  import { getMyContributorProfile } from '$lib/queries/contributors';
  import { canSeeProjectManagement, isLoggedIn } from '$lib/auth';
  import { getPendingContributions } from '$lib/queries/contributions';
  import DeletedIfNull from '$lib/components/DeletedIfNull.svelte';

  const contributorQuery = getMyContributorProfile(isLoggedIn());
  $: pendingContributionsQuery = getPendingContributions(canSeeProjectManagement());

  $: loading = $pendingContributionsQuery.isLoading || $contributorQuery.isLoading;
</script>

<Content>
  {#if loading}
    <Skeleton style="margin: 1em auto; width:60%;" />
    <Skeleton />
    <Skeleton />
    <Skeleton style="margin: 2em auto 0; width:60%" />
  {:else}
    <h2 class="pb-2 pt-2">Pending Contributor Requests</h2>
    {#if !$contributorQuery.data}
      <p>Create your contributor <a href="/settings/profile">profile</a> so people can find you.</p>
    {:else if !canSeeProjectManagement() || $pendingContributionsQuery.data?.total_count === 0}
      <p>No pending requests. Don't worry, we'll email you when you get one.</p>
    {:else}
      <AQTable
        currentPage={1}
        totalCount={$pendingContributionsQuery.data?.total_count ?? 0}
        rowsPerPage={5}
        showPagination={false}
        hardError={$pendingContributionsQuery.isError || $pendingContributionsQuery.isError}
      >
        <!-- toDo: add error handling here -->
        <svelte:fragment slot="header">
          <th style="width: 50%">Project Title</th>
          <th style="width: 50%">Status</th>
        </svelte:fragment>
        <svelte:fragment slot="body">
          {#each $pendingContributionsQuery.data?.items || [] as item (item.ref)}
            <tr
              style="cursor:pointer;"
              use:onClickAndKeydown={() => {
                goto(`/requests/${item.ref}`);
              }}
            >
              <td>
                <DeletedIfNull data={item.project?.name} />
              </td>
              <td>{PROJECT_CONTACT_STATUSES[item.status]}</td>
            </tr>
          {/each}
        </svelte:fragment>
      </AQTable>
    {/if}
    {#if $pendingContributionsQuery.data?.has_any_requests}
      <a href="/requests">
        <Button variant="outlined">
          <Label>See all requests</Label>
        </Button>
      </a>
    {:else}
      <a href="/settings/profile">
        <Button variant="outlined">
          <Label>
            {#if $contributorQuery.data}Update{:else}Create{/if} profile
          </Label>
        </Button>
      </a>
    {/if}
    {#if $contributorQuery.data && !$contributorQuery.data.visible}
      <p class="mt-3 note">
        * Your profile is not currently visible in search, update your profile to turn it back on.
      </p>
    {/if}
    <p />
  {/if}
</Content>

<style>
  .note {
    margin: 1em 0;
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.6);
  }
</style>
