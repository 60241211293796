<script lang="ts">
  import IconButton from '@smui/icon-button';
  import { page } from '$app/stores';
  import { goto } from '$app/navigation';
  import ErrorChunk from '$lib/components/ErrorChunk.svelte';
  import { onMount } from 'svelte';

  export let paginationName: string | undefined = undefined;
  export let totalCount: number;
  export let currentPage: number;
  export let rowsPerPage: number;
  export let showPagination: boolean = true;
  export let hardError: boolean = false;

  onMount(() => {
    const pageOnFirstLoad = $page.url?.searchParams.get('page');
    if (pageOnFirstLoad) {
      currentPage = Number.parseInt(pageOnFirstLoad, 10) - 1;
    }
  });

  $: if (currentPage > lastPage) {
    currentPage = lastPage;
  } else if (currentPage < 0) {
    currentPage = 0;
  }

  $: lastPage = Math.max(Math.ceil(totalCount / rowsPerPage) - 1, 0);
  $: startCount = currentPage * rowsPerPage + 1;
  $: endCount = Math.min(totalCount, startCount + rowsPerPage - 1);

  const setCurrentPage = (num: number) => {
    currentPage = num;
    $page.url.searchParams.set('page', (currentPage + 1).toString());
    goto(`?${$page.url.searchParams.toString()}`, { noScroll: true });
  };
</script>

<div class="table-responsive-md">
  <table class="table table-hover">
    <thead>
      <tr>
        {#if !hardError}
          <slot name="header" />
        {/if}
      </tr>
    </thead>
    <tbody>
      {#if hardError}
        <div class="mt-3">
          <ErrorChunk />
        </div>
      {:else}
        <slot name="body" />
      {/if}
    </tbody>
  </table>
</div>
{#if showPagination}
  <nav aria-label="Page navigation example" class="d-flex justify-content-end align-items-center">
    <p style="margin: 0 1em 0 0">
      {startCount}-{endCount}
      {#if paginationName} of {totalCount} {paginationName}{/if}
    </p>
    <IconButton
      class="material-icons"
      action="first-page"
      title="First page"
      on:click={() => setCurrentPage(0)}
      disabled={currentPage === 0}
    >
      first_page
    </IconButton>
    <IconButton
      class="material-icons"
      action="prev-page"
      title="Prev page"
      on:click={() => setCurrentPage(currentPage - 1)}
      disabled={currentPage === 0}
    >
      chevron_left
    </IconButton>
    <IconButton
      class="material-icons"
      action="next-page"
      title="Next page"
      on:click={() => setCurrentPage(currentPage + 1)}
      disabled={currentPage === lastPage}
    >
      chevron_right
    </IconButton>
    <IconButton
      class="material-icons"
      action="last-page"
      title="Last page"
      on:click={() => setCurrentPage(lastPage)}
      disabled={currentPage === lastPage}
      >last_page
    </IconButton>
  </nav>
{/if}

<style>
  table {
    width: 100%;
  }
</style>
