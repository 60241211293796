<script lang="ts">
  import { onMount } from 'svelte';
  import * as auth from '$lib/auth';
  import { ensureVerifiedEmail } from '$lib/warnings';

  const unauthenticatedPaths = /^\/projects\/[0-9a-z]+\/submit\/[0-9a-z]+/;
  onMount(() => {
    if (!auth.canSeeProjectManagement() && !unauthenticatedPaths.test(window.location.pathname)) {
      ensureVerifiedEmail();
    }
  });
</script>

<slot />
