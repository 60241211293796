type ProjectFieldInfo = {
  meetingLinkLabel: string;
  meetingLinkSubtitle: string;
  outletLabel: string;
  outletLinkLabel: string;
  outletViewershipLabel: string | null;
  descriptionTitle: string;
  descriptionSubTitle: string;
  deadlineLabel: string;
  arrangeLabel: string | null;
};
const meetingLinkLabel = 'Public calendar link';
export const getProjectFieldInfo = (projectType?: string | null): ProjectFieldInfo => {
  switch (projectType) {
    case 'podcast':
      return {
        arrangeLabel: 'Arrange the podcast',
        deadlineLabel: 'Response deadline',
        meetingLinkLabel,
        meetingLinkSubtitle:
          'Note: Add a link to your calendar if you want contributors to suggest a time to have an initial chat or record the podcast itself.',
        outletLabel: 'Podcast name',
        outletLinkLabel: 'Podcast link',
        outletViewershipLabel: 'No. of podcast subscribers/episode listeners.',
        descriptionTitle: "Add a description of what you're looking for an want to discuss.",
        descriptionSubTitle:
          'Mention topics/questions. Include any technical recording requirements and list out the process if a contributor accepts your invitation. E.g. Do you want an initial call to prepare for the podcast? How long is the recording, etc?'
      };
    case 'event':
      return {
        arrangeLabel: 'Arrange the speaking slot',
        deadlineLabel: 'Response deadline',
        meetingLinkLabel,
        meetingLinkSubtitle:
          'Note: Add a link to your calendar if you want contributors to suggest a time for an initial chat.',
        outletLabel: 'Event name',
        outletLinkLabel: 'Link to event',
        outletViewershipLabel: 'No. of event attendees',
        descriptionTitle: "Add a description of what you're looking for.",
        descriptionSubTitle:
          "Include information about the event, what type of presentation you're looking for, and whether you'll pay speakers. " +
          'Mention the length of the slot, the event date and location, and information about the audience. ' +
          'List out the process if a contributor accepts your invitation to speak. ' +
          'E.g. do you want an initial call to discuss their talk? When do you want them to send you their presentation slides?'
      };
    default:
      return {
        arrangeLabel: null,
        deadlineLabel: 'Submission deadline',
        meetingLinkLabel,
        meetingLinkSubtitle:
          'Note: Add a link to your calendar if you want contributors to suggest a time to have a chat about the project.',
        outletLabel: 'Publication name',
        outletLinkLabel: 'Link to publication',
        outletViewershipLabel: 'Outlet readership',
        descriptionTitle: 'Add a description of your project.',
        descriptionSubTitle:
          'What question or topic are you focused on? How many words should a quote be? Do you want to see previous speaking examples?'
      };
  }
};
