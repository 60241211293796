<script lang="ts">
  import { onMount } from 'svelte';
  import { logIn } from '$lib/auth';
  import { loginWithGoogleMutation } from '$lib/queries/auth';

  export let context: 'signup' | 'signin';
  export let marketingEmails: boolean | undefined = undefined;

  let titleAction = context === 'signup' ? 'Sign Up' : 'Sign In';

  let loadGoogleScript = false;
  let nextPageBasedOnContext = context === 'signup' ? '/settings/profile' : undefined;

  const mutation = loginWithGoogleMutation();

  onMount(() => {
    window.googleLoginCallback = async ({ credential }) => {
      $mutation.mutate(
        {
          credential,
          marketing_emails: marketingEmails
        },
        {
          onSuccess: (data) => {
            const nextPage = new URLSearchParams(window.location.search).get('next');
            logIn(data, nextPage ?? nextPageBasedOnContext);
          }
        }
      );
    };
    loadGoogleScript = true;
    return () => {
      window.googleLoginCallback = undefined;
    };
  });
</script>

<div
  id="g_id_onload"
  data-client_id="113247430001-2dema72kv0525qfq9o7gmbq8ca36gq69"
  data-context={context}
  data-ux_mode="popup"
  data-callback="googleLoginCallback"
  data-itp_support="true"
/>
<div
  class="g_id_signin"
  data-type="standard"
  data-shape="rectangle"
  data-theme="outline"
  data-text={`${context}_with`}
  data-size="large"
  data-locale="en"
  data-logo_alignment="center"
  data-width="300"
/>

<div class="row seperator-container">
  <div class="seperator">
    <div class="line">or {titleAction} with Email</div>
  </div>
</div>

{#if loadGoogleScript}
  <script src="https://accounts.google.com/gsi/client" async defer></script>
{/if}

<style lang="scss">
  .line {
    width: 50%;
    position: relative;
    top: -0.8em;
    margin: 0 auto;
    text-align: center;
    background-color: white;
  }

  .seperator {
    width: 60%;
    font-size: 0.8em;
    color: #acbdd5;
    justify-content: center;
    border-top: 1px solid #acbdd5;
    margin-top: 3.5em;
  }

  .seperator-container {
    justify-content: center;
  }

  .g_id_signin {
    display: flex;
    justify-content: center;
  }
</style>
