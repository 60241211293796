<script>
  import Card, { Content } from '@smui/card';
  import { Icon } from '@smui/button';
  import { myUserTypeMutation } from '$lib/queries/users';
  import { goto } from '$app/navigation';
  import CircularProgress from '@smui/circular-progress';

  const setUserType = myUserTypeMutation();

  const createMediaProjects = (e) => {
    e.preventDefault();
    $setUserType.mutate(
      { user_type: 'media_person' },
      {
        onSuccess: () => {
          goto('/contributors');
        }
      }
    );
  };

  const boxStyle = 'text-decoration: none; font-size: 4em';
  $: otherIconClass = $setUserType.isPending
    ? 'aq-disabled-icon-button material-icons box-icon'
    : 'aq-purple-icon-button material-icons box-icon';
  $: otherElement = $setUserType.isPending ? 'span' : 'a';
</script>

<div class="container-fluid pb-5" style="background-color: #f8f9fa;height: 100%">
  <div class="container text-center">
    <div class="row pt-5">
      <div class="col">
        <h2 class="mb-5">I want to:</h2>
        <div class="row">
          <div class="col-md-4 mb-3">
            <svelte:element
              this={otherElement}
              href="/settings/profile?visible=true&user-type=contributor"
            >
              <Card>
                <Content>
                  <Icon class={otherIconClass} style={boxStyle}>campaign</Icon>
                  <h5>Be a contributor</h5>
                  <p>
                    List my profile on Antiquoted and get invited to contribute to articles,
                    podcasts or events.
                  </p>
                </Content>
              </Card>
            </svelte:element>
          </div>
          <div class="col-md-4 mb-3">
            <a href="/contributors" on:click={createMediaProjects}>
              <Card>
                <Content>
                  {#if $setUserType.isPending}
                    <CircularProgress
                      style="height: 4em; width: 4em; padding:0; margin: 0"
                      indeterminate
                    />
                  {:else}
                    <Icon class="aq-purple-icon-button material-icons" style={boxStyle}>search</Icon
                    >
                  {/if}
                  <h5>Create media projects</h5>
                  <p>Find people to contribute to my articles, podcasts, and events.</p>
                </Content>
              </Card>
            </a>
          </div>
          <div class="col-md-4">
            <svelte:element
              this={otherElement}
              href="/settings/profile?next=/contributors&visible=true&user-type=both"
            >
              <Card>
                <Content>
                  <Icon class={otherIconClass} style={boxStyle}>merge_type</Icon>
                  <h5>Both</h5>
                  <p>
                    List my profile on Antiquoted & find people to contribute to my articles,
                    podcasts, and events.
                  </p>
                </Content>
              </Card>
            </svelte:element>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  a,
  a h5,
  a p {
    text-decoration: none !important;
    color: black;
  }
</style>
