<script lang="ts">
  import MainBorder from '$lib/components/MainBorder.svelte';
  import FieldBlock from '$lib/components/FieldBlock.svelte';
  import UserField from '$lib/components/UserTextField.svelte';
  import { Label } from '@smui/button';
  import LoadingButton from '$lib/components/LoadingButton.svelte';
  import { getMyUserData, myUserDataMutation } from '$lib/queries/users';
  import CroppableAvatarUpload from '$lib/components/Images/CroppableAvatarUpload.svelte';
  import CroppableAvatarDialog from '$lib/components/Images/CroppableAvatarDialog.svelte';
  import { goto } from '$app/navigation';

  const userDataFields = {
    first_name: '',
    last_name: '',
    job_title: '',
    company_name: ''
  };
  const userQuery = getMyUserData();
  const myUserDataMutationQuery = myUserDataMutation();
  $: loading = $userQuery.isLoading;
  let firstLoad = true;
  $: if (firstLoad && !$userQuery.isLoading) {
    if ($userQuery.data) {
      goto('/settings/profile');
    } else {
      firstLoad = false;
    }
  }

  const submit = () => {
    $myUserDataMutationQuery.mutate(userDataFields, {
      onSuccess: () => {
        goto('/profile/type');
      }
    });
  };
</script>

<svelte:head>
  <title>Antiquoted | Contributor Profile</title>
</svelte:head>

<div class="container">
  <div class="row">
    <div class="offset-md-1 col-md-10">
      <h4 class="mt-5">Your Profile</h4>
    </div>
  </div>
</div>

<form on:submit={submit}>
  <MainBorder>
    <FieldBlock {loading}>
      <h4 slot="title">About You</h4>
      <UserField
        id="first_name"
        label="First Name"
        bind:value={userDataFields.first_name}
        helperText="You can't not have a name you know..."
        type="text"
        required
      />
      <UserField
        id="last_name"
        label="Last Name"
        bind:value={userDataFields.last_name}
        helperText="You can't not have a name you know..."
        type="text"
        required
      />
      <CroppableAvatarUpload />
      <UserField
        id="job_title"
        label="Job Title"
        style="margin-top: 1em;width: 100%;"
        bind:value={userDataFields.job_title}
        helperText="We need to know a little about you."
        type="text"
        required
      />
      <UserField
        id="company_name"
        label="Company Name"
        bind:value={userDataFields.company_name}
        helperText="We need to know a little about you."
        type="text"
        required
      />
    </FieldBlock>
  </MainBorder>
  <div class="text-center mb-5 mt-5">
    <LoadingButton loading={false} type="submit" variant="outlined">
      <Label>Create</Label>
    </LoadingButton>
  </div>
</form>

<CroppableAvatarDialog />
