<script lang="ts">
  import IconButton from '@smui/icon-button';

  export let currentPage: number;
  export let firstRecordCount: number;
  export let lastRecordCount: number;
  export let totalHits: number;
  export let totalPages: number;
  export let setPage: (page: number) => void;
</script>

<nav
  hidden={totalHits === 0}
  aria-label="Page navigation example"
  class="d-flex justify-content-end align-items-center"
>
  <p style="margin: 0 1em 0 0">
    {#if totalPages > 0}
      {firstRecordCount}-{lastRecordCount} Contributors
    {/if}
  </p>
  <IconButton
    class="material-icons aq-purple-icon-button-not-disabled"
    action="first-page"
    title="First page"
    on:click={() => setPage(0)}
    disabled={currentPage === 0}
    style="font-weight: bold"
  >
    first_page
  </IconButton>
  <IconButton
    class="material-icons aq-purple-icon-button-not-disabled"
    action="prev-page"
    title="Prev page"
    on:click={() => setPage(currentPage - 1)}
    disabled={currentPage === 0}
    style="font-weight: bold"
  >
    chevron_left
  </IconButton>
  <IconButton
    color="secondary"
    class="material-icons aq-purple-icon-button-not-disabled"
    action="next-page"
    title="Next page"
    on:click={() => setPage(currentPage + 1)}
    disabled={currentPage >= totalPages - 1}
    style="font-weight: bold"
  >
    chevron_right
  </IconButton>
  <IconButton
    class="material-icons aq-purple-icon-button-not-disabled"
    action="last-page"
    title="Last page"
    on:click={() => setPage(totalPages - 1)}
    disabled={currentPage >= totalPages - 1}
    style="font-weight: bold"
    >last_page
  </IconButton>
</nav>
