<script lang="ts">
  import Select, { Option } from '@smui/select';
  import Dialog, { Actions, Content, Title } from '@smui/dialog';
  import { Label } from '@smui/button';
  import LoadingButton from '$lib/components/LoadingButton.svelte';
  import StretchyTextArea from '$lib/components/StretchyTextArea.svelte';
  import { showErrorToast, showSuccessToast } from '$lib/components/Toaster/stores';
  import type { Readable } from 'svelte/store';
  import type { CreateBaseMutationResult } from '@tanstack/svelte-query';
  import { ApiError } from '$lib/api/errors';
  import { type ProjectContactStatus } from '$lib/constants';

  export let open: boolean;
  export let status: ProjectContactStatus;
  export let asMedia: boolean = false;
  type Mutation = CreateBaseMutationResult<
    void,
    Error | ApiError,
    {
      status: ProjectContactStatus;
      decline_reason?: string;
    }
  >;
  export let mutation: Readable<Mutation> | Mutation;

  const declineReasons = asMedia
    ? [
        'The submission was not up to quality standards',
        'I already have enough submissions for this project'
      ]
    : [
        "I can't meet the deadline",
        "I don't have relevant expertise",
        'I don’t want to feature in this publication'
      ];

  let declineReason: string | undefined;
  let declineReasonOther: string = '';

  const checkDeclineModalClosed = (e: CustomEvent) => {
    const reason = declineReason === 'Other' ? declineReasonOther : declineReason;
    if ((reason === '' && e.detail.action === 'decline') || $mutation.isPending) {
      open = true;
    }
  };

  const declineRequest = (e: Event) => {
    e.preventDefault();
    const reason = declineReason === 'Other' ? declineReasonOther : declineReason;
    if (reason === '') {
      showErrorToast(new Error('Please provide a reason for declining'));
      return;
    }
    $mutation.mutate(
      { status, decline_reason: reason },
      {
        onSuccess: () => {
          showSuccessToast('We have declined this request.');
          declineReason = undefined;
          declineReasonOther = '';
          open = false;
        }
      }
    );
  };
</script>

<Dialog
  bind:open
  aria-labelledby="Decline reason"
  aria-describedby="Give a reason why you're declining this request"
  surface$style="width: 650px; max-width: calc(100vw - 32px);height: 650px; max-width: calc(100vh - 100px);"
  on:SMUIDialog:closed={checkDeclineModalClosed}
>
  <Title>Let us know why you're declining this request</Title>
  <Content>
    {#if asMedia}
      <p>
        Declining this request will notify the contributor that you're not going to use their
        submission on this project. They will see the reason that you provide.
      </p>
    {:else}
      <p>
        Declining this request will notify the project owner that you're not able to contribute to
        this project. They will see the reason that you provide here.
      </p>
    {/if}
    <Select
      bind:value={declineReason}
      label="Reason for declining"
      style="width: 100%;margin-bottom: 2em"
      disabled={$mutation.isPending}
    >
      <Option value={null} />
      {#each declineReasons as reason}
        <Option value={reason}>{reason}</Option>
      {/each}
      <Option value="Other">Other</Option>
    </Select>
    {#if declineReason === 'Other'}
      <StretchyTextArea
        id="declineReasonOther"
        bind:value={declineReasonOther}
        label="Please provide more details"
        maxLength={1000}
        showCharacterCount
        required
        disabled={$mutation.isPending}
      />
    {/if}
  </Content>
  <Actions>
    <LoadingButton loading={false} disabled={$mutation.isPending} action="close">
      <Label>Cancel</Label>
    </LoadingButton>
    <LoadingButton loading={$mutation.isPending} action="decline" on:click={declineRequest}>
      <Label>Decline</Label>
    </LoadingButton>
  </Actions>
</Dialog>
