<script lang="ts">
  import IconButton from '@smui/icon-button';
  import { isLoggedIn } from '$lib/auth';
  import { Icon } from '@smui/button';
  import { page } from '$app/stores';
  import Badge from '@smui-extra/badge';
  import { getUnreadMessagesCountQuery } from '$lib/queries/messaging';

  let color: string;
  export let darkMode: boolean = false;
  const purple = 'rgb(121, 40, 202)';
  let isOnMessagePage = false;

  const unreadMessagesCountQuery = getUnreadMessagesCountQuery(isLoggedIn());

  page.subscribe((value) => {
    isOnMessagePage = value?.url?.pathname === '/inbox';
  });

  $: if (isOnMessagePage) {
    color = darkMode ? purple : 'white';
  } else {
    color = darkMode ? 'white' : purple;
  }
  $: kls = isOnMessagePage ? ' highlighted' : '';
  $: kls += darkMode ? ' dark' : '';
</script>

{#if isLoggedIn()}
  <IconButton
    href="/inbox"
    class="material-icons"
    style="font-size:1em;color:{color};margin-right:0;padding:7px;"
    tag="a"
  >
    <Icon class="material-icons{kls}" style="border-radius:9999px;padding:4px">mail</Icon>
    {#if !isOnMessagePage && $unreadMessagesCountQuery.data && $unreadMessagesCountQuery.data > 0}
      <Badge
        class="unread-badge-identifier message-bell-unread-count"
        style="background-color:{color};"
        position="inset"
        aria-label="unread notification count"
      />
    {/if}
  </IconButton>
{/if}
