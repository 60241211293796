import { createQuery } from '@tanstack/svelte-query';
import { QUERY_KEYS } from '$lib/queries/keys';
import * as api from '$lib/api';
import type { FieldInfo } from '$lib/types';
import { oneHourInMilliseconds } from '$lib/constants';

export const getFieldInfo = () =>
  createQuery({
    queryKey: QUERY_KEYS.COMMON_FIELD_INFO,
    queryFn: async () => api.get<FieldInfo>('/contributors/profile/field-info'),
    gcTime: oneHourInMilliseconds,
    staleTime: oneHourInMilliseconds
  });

export const fieldInfoToIdentifiesAsMap = (fieldInfo?: FieldInfo) => {
  return createQuery({
    queryKey: [QUERY_KEYS.COMMON_FIELD_INFO, 'identifies_as'],
    queryFn: (): Record<string, string> => {
      return fieldInfo!.identifies_as_choices.reduce((acc, { label, value }) => {
        acc[value] = label;
        return acc;
      }, {} as Record<string, string>);
    },
    gcTime: oneHourInMilliseconds,
    staleTime: oneHourInMilliseconds,
    enabled: !!fieldInfo
  });
};

export const getPronounOptions = () =>
  createQuery({
    queryKey: [QUERY_KEYS.COMMON_FIELD_INFO, 'pronouns'],
    queryFn: async () => {
      const { pronoun_choices } = await api.get<FieldInfo>('/contributors/profile/field-info');
      return pronoun_choices.reduce((acc, { label, value }) => {
        acc[value] = label;
        return acc;
      }, {} as Record<string, string>);
    },
    gcTime: oneHourInMilliseconds,
    staleTime: oneHourInMilliseconds
  });
