<script lang="ts">
  import { onMount } from 'svelte';
  import * as auth from '$lib/auth';

  onMount(async () => {
    if (auth.isLoggedIn()) {
      window.location.href = '/';
    }
  });
</script>

<div class="row">
  <div
    class="offset-md-3 col-md-6"
    style="border-radius: 10px; background-color: white; padding: 2em 4em; margin: 50px auto 0; max-width: 500px"
  >
    <slot />
  </div>
</div>
