<script lang="ts">
  import Button, { Label } from '@smui/button';
  import Chip, { Set, Text, TrailingAction } from '@smui/chips';
  import UserField from './UserTextField.svelte';

  export let expertiseArray: Array<string> = [];
  export let maxCount: number;
  export let mustHaveExpertise = false;
  export let valueInvalid: boolean;
  export let showMax: boolean = false;

  let value = '';
  const max = 50;
  const addExpertise = (e?: Event) => {
    e?.preventDefault();
    const trimmedExpertise = value.replaceAll(',', '').trim().slice(0, max);
    if (
      expertiseArray.includes(trimmedExpertise) ||
      expertiseArray.length >= maxCount ||
      value === ''
    ) {
      value = '';
      return;
    }
    expertiseArray = [...expertiseArray, trimmedExpertise];
    value = '';
  };

  let disabled: boolean;
  $: disabled = expertiseArray.length >= maxCount;
  $: required = mustHaveExpertise && expertiseArray.length === 0;

  // As soon as svelte reactivity picks up a `,` in the value we can trigger adding it as an expertise
  $: if (value.includes(',')) {
    addExpertise();
  }
</script>

<div class="row">
  <div class="col-md-5">
    <UserField
      id="expertise"
      label={'Add expertise' + (showMax ? ` (max ${maxCount})` : '')}
      bind:value
      helperText="You must add an expertise"
      type="text"
      {disabled}
      updateInvalid={false}
      {valueInvalid}
      {required}
      {max}
    />
  </div>
  <div class="col-md-2 align-items-center d-flex">
    <Button on:click={addExpertise} {disabled} class="align-self-center">
      <Label>Add</Label>
    </Button>
  </div>
</div>
<Set
  chips={expertiseArray}
  let:chip
  input
  on:SMUIChip:removal={() => {
    expertiseArray = expertiseArray;
  }}
>
  <Chip {chip}>
    <Text>{chip}</Text>
    <TrailingAction icon$class="material-icons">cancel</TrailingAction>
  </Chip>
</Set>
