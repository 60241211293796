<script>
  import { Content } from '@smui/card';
</script>

<Content>
  <h2 class="pb-3 pt-2">Why Join Antiquoted?</h2>
  <div style="text-align: left;padding-left: 2em">
    <p><strong>Build your personal brand</strong></p>
    <ul>
      <li>Be known as the go-to person in your area of expertise.</li>
      <li>Get media opportunities without having media connections.</li>
      <li>Get noticed by potential clients, employers, and peers.</li>
    </ul>

    <p><strong>Find business experts to feature in your content</strong></p>
    <ul>
      <li>Find diverse new voices. #NoMoreManels.</li>
      <li>Produce content no AI can match by including unique quotes and real-life examples.</li>
      <li>Reach new audiences by involving authoritative influencers in your work.</li>
    </ul>
  </div>
</Content>
