<script lang="ts">
  import MainBorder from '$lib/components/MainBorder.svelte';
  import PageTitle from '$lib/components/PageTitle.svelte';
  import FieldBlock from '$lib/components/FieldBlock.svelte';
  import {
    getProjectQuery,
    getProjectSubmissionsQuery,
    markSubmissionsAsReadMutationQuery
  } from '$lib/queries/projects';
  import IconButton from '@smui/icon-button';
  import { Icon, Label } from '@smui/button';
  import { getPronounOptions } from '$lib/queries/common';
  import Accordion, { Content, Header, Panel } from '@smui-extra/accordion';
  import Badge from '@smui-extra/badge';
  import DeclineModal from '$lib/components/DeclineModal.svelte';
  import {
    changeContributorProjectStatus,
    sendProjectContributorMessage
  } from '$lib/queries/contributors';
  import { type ProjectSubmission } from '$lib/types';
  import Dialog, { Actions, Title } from '@smui/dialog';
  import MessageThread from '$lib/components/MessageThread.svelte';
  import LoadingButton from '$lib/components/LoadingButton.svelte';
  import { DELETED_PLACEHOLDER } from '$lib/constants';
  import TooltipIconButton from '$lib/components/Projects/TooltipIconButton.svelte';
  import ChangeStatusTooltipIconButton from '$lib/components/Projects/ChangeStatusTooltipIconButton.svelte';

  export let data;

  const panels: Record<string, boolean> = {};
  const markedRead = new Set<string>();
  let selectedContributorRef: string = '';

  let changeStatusLoading = false;
  let declineModalOpen = false;

  let chatModalOpen = false;
  let selectedChatSubmission: ProjectSubmission;

  $: sendContributorMessageMutation = sendProjectContributorMessage(selectedContributorRef);
  $: changeContributorProjectStatusMutation = changeContributorProjectStatus(
    data.params.ref,
    selectedContributorRef,
    () => {
      delete panels[selectedContributorRef];
    }
  );
  const pronounOptions = getPronounOptions();
  const query = getProjectSubmissionsQuery(data.params.ref);
  const project = getProjectQuery(data.params.ref);
  const markAsReadMutation = markSubmissionsAsReadMutationQuery(data.params.ref);
  $: loading =
    $query.isLoading || $pronounOptions.isLoading || $project.isLoading || changeStatusLoading;

  let firstOpen = false;
  $: if ($query.data && !firstOpen) {
    $query.data.forEach((submission) => {
      panels[submission.ref] = false;
      if (submission.is_read) {
        markedRead.add(submission.ref);
      }
    });

    let contributorRef = new URLSearchParams(window.location.search).get('contributorRef');
    let projectContributorRef: string | undefined;
    if (contributorRef) {
      projectContributorRef = $query.data.find((i) => i.contributor__ref === contributorRef)?.ref;
    } else if ($query.data.length === 1) {
      projectContributorRef = $query.data[0].ref;
    }

    if (projectContributorRef && projectContributorRef in panels) {
      panels[projectContributorRef] = true;
    }
    firstOpen = true;
  }

  let openPanelProjectContributorRef: string | undefined = undefined;
  $: if ($query.data) {
    openPanelProjectContributorRef = Object.entries(panels).find(([_, open]) => open)?.[0];
    selectedContributorRef =
      $query.data.find((i) => i.ref === openPanelProjectContributorRef)?.contributor__ref ?? '';
  }

  $: {
    const openedUnread = Object.entries(panels).find(
      ([ref, open]) => open && ref !== 'null' && !markedRead.has(ref)
    );
    if (openedUnread) {
      markedRead.add(openedUnread[0]);
      $markAsReadMutation.mutate([...markedRead]);
    }
  }

  const toExtras = (submission: ProjectSubmission): string => {
    let response = '';
    if (submission.contributor__pronouns) {
      response += ` (${$pronounOptions.data?.[submission.contributor__pronouns]})`;
    }
    if (submission.contributor__job_title) {
      response += `, ${submission.contributor__job_title}`;
    }
    if (submission.contributor__company_name) {
      response += ` at ${submission.hide_company_name ? '*' : ''}${
        submission.contributor__company_name
      }`;
    }
    return response;
  };

  const openChatModal = (submission: ProjectSubmission) => {
    selectedChatSubmission = submission;
    chatModalOpen = true;
  };

  const closeChatModal = () => {
    chatModalOpen = false;
  };
  const declinedStatuses = new Set(['contributor_declined', 'media_declined']);

  const toPanelTitle = (submission: ProjectSubmission) => {
    const suffix = `submission from ${submission.contributor__first_name ?? DELETED_PLACEHOLDER} ${
      submission.contributor__last_name ?? DELETED_PLACEHOLDER
    }`;
    if (declinedStatuses.has(submission.status)) {
      return `Declined ${suffix}`;
    } else if (submission.status === 'approved') {
      return `Approved ${suffix}`;
    } else {
      return `Pending ${suffix}`;
    }
  };
</script>

<PageTitle name="Project responses" />

<MainBorder backButton="/projects/{data.params.ref}" backButtonTitle="Back to project">
  <FieldBlock {loading}>
    <h3 class="mb-4">{$project.data?.name}</h3>
    <Accordion>
      {#each $query.data || [] as submission}
        <Panel bind:open={panels[submission.ref]}>
          {#if !markedRead.has(submission.ref) && submission.ref}
            <Badge
              color="alert"
              align="middle-start"
              aria-label="Unread content is available"
              style="min-height: 10px; min-width: 10px; padding: 0;"
            />
          {/if}
          <Header>
            <span class:bold={panels[submission.ref]}>{toPanelTitle(submission)} </span>
            <IconButton slot="icon" toggle pressed={panels[submission.ref]}>
              <Icon class="material-icons" on>expand_less</Icon>
              <Icon class="material-icons">expand_more</Icon>
            </IconButton>
          </Header>
          <Content class="approved">
            {#if submission.status === 'approved'}
              <p class="note">You have approved this submission</p>
            {/if}
            {#if submission.submission && !declinedStatuses.has(submission.status)}
              <p style="white-space: pre-wrap;">
                “{submission.submission}”
              </p>
            {/if}
            {#if submission.decline_reason && declinedStatuses.has(submission.status)}
              <p style="white-space: pre-wrap;">
                Decline reason: “{submission.decline_reason}”
              </p>
            {/if}
            by
            {#if submission.contributor__ref}
              <a href="/projects/{data.params.ref}/contributors/{submission.contributor__ref}"
                >{submission.contributor__first_name} {submission.contributor__last_name}</a
              >
            {:else}
              {DELETED_PLACEHOLDER} {DELETED_PLACEHOLDER}
            {/if}

            {toExtras(submission)}
            {#if submission.hide_company_name}
              <p class="note">
                *Important note: The contributor has asked that you don't show their company name in
                relation to this submission
              </p>
            {/if}
            {#if submission.submitted_at}
              <p>
                Submitted on {submission.submitted_at &&
                  new Date(submission.submitted_at).toLocaleDateString()}
              </p>
            {/if}
            {#if submission.contributor__ref}
              <div class="align-items-center d-flex">
                {#if submission.status === 'submitted'}
                  <ChangeStatusTooltipIconButton
                    status="approved"
                    projectRef={data.params.ref}
                    contributorRef={submission.contributor__ref}
                  />
                {/if}
                {#if !declinedStatuses.has(submission.status)}
                  <TooltipIconButton
                    icon="chat"
                    tooltip="Ask the contributor a question about the submission"
                    action={() => {
                      openChatModal(submission);
                    }}
                  />
                {/if}
                {#if !declinedStatuses.has(submission.status)}
                  <TooltipIconButton
                    icon="close"
                    tooltip="Decline submission"
                    action={() => {
                      declineModalOpen = true;
                    }}
                  />
                {/if}
              </div>
            {/if}
          </Content>
        </Panel>
      {:else}
        <p>No responses yet.</p>
      {/each}
    </Accordion>
  </FieldBlock>
</MainBorder>

<Dialog
  bind:open={chatModalOpen}
  aria-labelledby="Ask a Question"
  aria-describedby="Ask a question about the request"
  surface$style="width: 650px; max-width: calc(100vw - 32px);"
>
  <Title>Ask a Question</Title>
  <Content>
    {#if chatModalOpen && $query.data}
      <MessageThread
        userRef={selectedChatSubmission.contributor__user__ref}
        userName="{selectedChatSubmission.contributor__first_name}  {selectedChatSubmission.contributor__last_name}"
        mutation={sendContributorMessageMutation}
      />
    {/if}
  </Content>
  <Actions>
    <LoadingButton
      loading={false}
      disabled={$sendContributorMessageMutation.isPending}
      on:click={closeChatModal}
    >
      <Label>Close</Label>
    </LoadingButton>
  </Actions>
</Dialog>

<DeclineModal
  bind:open={declineModalOpen}
  mutation={changeContributorProjectStatusMutation}
  status="media_declined"
  asMedia
/>

<style>
  .bold {
    font-weight: bold;
  }

  .note {
    font-size: 0.8rem;
    color: #666;
  }
</style>
