export class AsyncLock {
  private readonly queue: Array<() => void> = [];
  private acquired = false;

  private async acquire(): Promise<void> {
    if (this.acquired) {
      return new Promise<void>((resolve) => {
        this.queue.push(resolve);
      });
    }
    this.acquired = true;
  }

  private async release(): Promise<void> {
    const waitingAcquirerPromise = this.queue.shift();
    if (waitingAcquirerPromise) {
      return new Promise((resolve) => {
        waitingAcquirerPromise();
        resolve();
      });
    }
    this.acquired = false;
  }

  public async runSerially<T>(fn: () => Promise<T>): Promise<T> {
    await this.acquire();
    try {
      return await fn();
    } finally {
      await this.release();
    }
  }
}
