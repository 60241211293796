<script lang="ts">
  import Dialog, { Actions, Content, Title } from '@smui/dialog';
  import { Label } from '@smui/button';
  import LoadingButton from '$lib/components/LoadingButton.svelte';
  import { paywallModalStore } from '$lib/components/WarningModal/stores';
</script>

<Dialog
  bind:open={$paywallModalStore}
  aria-labelledby="Paywall modal"
  aria-describedby="A Modal used to show the paywall"
  surface$style="width: 1050px; max-width: calc(100vw - 32px);max-width: calc(100vh - 50px);"
>
  <Title>Upgrade to Premium</Title>
  <Content>
    <p>PAYWALL</p>
  </Content>
  <Actions>
    <LoadingButton loading={false} action="close">
      <Label>Maybe later</Label>
    </LoadingButton>
  </Actions>
</Dialog>
