<script lang="ts">
  import Button, { Label } from '@smui/button';
  import { Content } from '@smui/card';
  import Skeleton from '$lib/components/Skeleton.svelte';
  import { getMyUserData, myProfilePhotoQuery } from '$lib/queries/users';
  import Avatar from '$lib/components/Images/Avatar.svelte';
  import { getFieldInfo } from '$lib/queries/common';
  import { capitalise, debounce, formOptionsToMap } from '$lib/utils';
  import Chip, { Set, Text } from '@smui/chips';
  import CopyToClipboardIcon from '$lib/components/CopyToClipboardIcon.svelte';
  import { toExtras } from '$lib/components/Profiles/utils';
  import {
    getMyContributorProfile,
    putMyContributorVisibilityQuery
  } from '$lib/queries/contributors';
  import { goto } from '$app/navigation';
  import { canChangeVisibility } from '$lib/domain/contributor';

  const myData = getMyUserData(true);
  const myContributorProfile = getMyContributorProfile();
  const myProfilePhoto = myProfilePhotoQuery();
  const visibilityMutation = putMyContributorVisibilityQuery();
  $: switchVisible = debounce((selected: boolean) => {
    $visibilityMutation.mutate(selected);
  }, 250);

  $: fieldInfo = getFieldInfo();
  $: companySizes = formOptionsToMap($fieldInfo.data?.company_sizes || []);
  $: pronouns = formOptionsToMap($fieldInfo.data?.pronoun_choices || []);
  $: extras = toExtras($myData.data, $fieldInfo.data, companySizes);

  $: profileLink = `/profiles/${$myData.data?.user__ref}`;
  $: prettyPronouns = $myData.data?.pronouns
    ? '(' + (pronouns[$myData.data.pronouns] ?? $myData.data.pronouns) + ')'
    : '';

  $: chips = [
    ...($myData.data ? [capitalise($myData.data.user_type)] : []),
    ...($myContributorProfile.data
      ? [`Visibility: ${$myContributorProfile.data.visible ? 'on' : 'off'}`]
      : [])
  ];
  let selectedChip: string | null = null;
  $: {
    const lowerSelectedChip = selectedChip?.toLowerCase();
    if (lowerSelectedChip) {
      if (lowerSelectedChip.startsWith('visibility:')) {
        if (
          $myContributorProfile.data &&
          $myData.data &&
          canChangeVisibility($myContributorProfile.data, $myData.data)
        ) {
          switchVisible(!$myContributorProfile.data.visible);
          selectedChip = null;
        } else {
          goto('/settings/profile?visible=true');
        }
      } else {
        goto('/settings/profile');
      }
    }
  }

  $: loading = $myData.isLoading || $fieldInfo.isLoading;
</script>

<Content>
  {#if loading}
    <Skeleton style="margin: 1em auto; width:60%;" />
    <Skeleton />
    <Skeleton />
    <Skeleton style="margin: 2em auto 0; width:60%" />
  {:else}
    <a href={profileLink}><h2 class="pb-2 pt-2">My Profile</h2></a>
    <div class="d-flex align-items-center justify-content-start mb-3 gap-3 gap-lg-4">
      <Avatar src={$myProfilePhoto.data} hasAvatar={!!$myProfilePhoto.data} />
      <div class="d-flex flex-column flex-grow-1 align-items-start gap-1">
        <div>
          <Set {chips} let:chip style="padding-left:0" choice bind:selected={selectedChip}>
            <Chip {chip} class="pr-1" style="margin-left:0">
              <Text>{chip}</Text>
            </Chip>
          </Set>
        </div>
        <p class="text-start">
          {$myData.data?.first_name}
          {$myData.data?.last_name}
          {prettyPronouns}
          {#if extras}
            <br />{extras}
          {/if}
        </p>
      </div>
    </div>
    <div style="line-height: 2.5em">
      <a href="/settings/profile" class="pb-2">
        <Button variant="outlined">
          <Label>Update</Label>
        </Button>
      </a>
      <CopyToClipboardIcon
        href={profileLink}
        tooltip="Send this link to people who aren't on Antiquoted so they can see your profile to your project."
        fullWidth={false}
      >
        Public link
      </CopyToClipboardIcon>
    </div>
  {/if}
</Content>

<style>
  a {
    color: black;
    text-decoration: none !important;
  }
</style>
