<script lang="ts">
  import TopAppBar, { Row, Section, Title } from '@smui/top-app-bar';
  import IconButton from '@smui/icon-button';
  import Menu from '@smui/menu';
  import NotificationBell from '$lib/components/NotificationBell.svelte';
  import MessageBell from '$lib/components/MessageBell.svelte';
  import EmailVerificationTopBar from '$lib/components/EmailVerificationTopBar/index.svelte';
  import { menuStore } from '$lib/menu';

  let menu: Menu;
  menuStore.subscribe((val: Menu) => {
    menu = val;
  });
</script>

<div class="top-app-bar">
  <TopAppBar
    variant="static"
    color="primary"
    style="background-image: linear-gradient(310deg, rgb(121, 40, 202), rgb(255, 0, 128));"
  >
    <Row>
      <Section style="width:33%">
        <IconButton on:click={() => menu.setOpen(true)} class="menu-toggle material-icons">
          menu
        </IconButton>
      </Section>
      <Section style="justify-content: center; width:34%">
        <a href="/">
          <Title style="padding-left: 0; font-size: 1.5em">Antiquoted</Title>
        </a>
      </Section>
      <Section style="width:33%; display:flex; justify-content: flex-end">
        <MessageBell darkMode />
        <NotificationBell darkMode />
      </Section>
    </Row>
  </TopAppBar>
  <EmailVerificationTopBar />
</div>

<slot />

<style lang="scss">
  .top-app-bar a {
    color: white;
    text-decoration: none;
  }
</style>
