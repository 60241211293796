<script lang="ts">
  import 'normalize.css';
  import TopAppBar, { Row, Section, Title } from '@smui/top-app-bar';
  import IconButton from '@smui/icon-button';
  import Menu from '@smui/menu';
  import backgroundImage from '$lib/img/bkgrnd.png';
  import { page } from '$app/stores';
  import { menuStore } from '$lib/menu';

  let menu: Menu;
  menuStore.subscribe((val: Menu) => {
    menu = val;
  });
</script>

<div class="top-app-bar">
  <TopAppBar variant="static" color="primary" style="background-color: white">
    <Row>
      <Section style="width:33%">
        <IconButton
          on:click={() => menu.setOpen(true)}
          class="menu-toggle material-icons"
          style="color: rgb(121, 40, 202);"
        >
          menu
        </IconButton>
      </Section>
      <Section style="justify-content: center; width:34%">
        <a href="/">
          <Title
            style="padding-left: 0; background-image: linear-gradient(310deg, rgb(121, 40, 202), rgb(255, 0, 128));-webkit-background-clip: text;
-webkit-text-fill-color: transparent; font-size: 1.5em"
          >
            Antiquoted
          </Title>
        </a>
      </Section>
      <Section style="width:33%" />
    </Row>
  </TopAppBar>
</div>
<div
  class="container-fluid main-container"
  style="background-image: url({backgroundImage}), linear-gradient(310deg, rgb(121, 40, 202), rgb(255, 0, 128));"
>
  <div class="row">
    <div
      class="offset-md-3 col-md-6"
      style="border-radius: 10px; background-color: white; padding: 2em 4em; margin: 50px auto 0; max-width: 500px"
    >
      <h1>{$page.error?.message || 'Error'}</h1>
      <p>Hmmm, something went wrong, let's get you back to the <a href="/">home page</a>.</p>
    </div>
  </div>
</div>

<style lang="scss">
  .top-app-bar a {
    color: white;
    text-decoration: none;
  }

  .main-container {
    padding: 0 2em 2em;
    background-size: contain;
    background-repeat: repeat;
    flex-grow: 1;
  }
</style>
