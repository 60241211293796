<script lang="ts">
  import Button from '@smui/button';
  import CircularProgress from '@smui/circular-progress';

  export let disabled: boolean = false;
  export let loading: boolean;
  $: isDisabled = disabled || loading;
</script>

<Button on:click disabled={isDisabled} style="height: auto; min-height: 2.5em;" {...$$restProps}>
  {#if loading}
    <CircularProgress style="height: 1.75em; width: 1.75em; padding:0; margin: 0" indeterminate />
  {:else}
    <slot />
  {/if}
</Button>
