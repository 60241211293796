<script lang="ts">
  import Dialog, { Content, Title } from '@smui/dialog';
  import { Label } from '@smui/button';
  import StretchyTextArea from '$lib/components/StretchyTextArea.svelte';
  import LoadingButton from '$lib/components/LoadingButton.svelte';
  import { putUserProfileNoteMutation } from '$lib/queries/profiles';

  export let userRef: string;
  export let note: string | null | undefined;
  export let open: boolean;
  let editingNotes = '';
  $: mutation = putUserProfileNoteMutation(userRef);

  const closeNotes = () => {
    open = false;
  };

  const saveNotes = () => {
    $mutation.mutate(editingNotes, {
      onSuccess: () => {
        closeNotes();
      }
    });
  };
</script>

<Dialog
  fullscreen
  bind:open
  aria-labelledby="Update private notes"
  aria-describedby="Update private notes about a contributor"
  surface$style="width: 850px; max-width: calc(100vw - 32px);"
  on:SMUIDialog:opening={() => {
    editingNotes = note ?? '';
  }}
>
  <Title id="simple-title" class="mb-2">
    <span style="padding-left: 1em">Update private notes</span>
  </Title>
  <Content>
    {#if $mutation.isError}
      <p class="error-text">{$mutation.error.message}</p>
    {/if}
    <StretchyTextArea id="{userRef}-notes-textarea" bind:value={editingNotes} />
    <p class="mt-0 mb-0"><em><small>Only you can see these notes</small></em></p>
    <div class="buttons">
      <LoadingButton
        on:click={closeNotes}
        loading={$mutation.isPending}
        disabled={$mutation.isPending}
      >
        <Label>Cancel</Label>
      </LoadingButton>
      <LoadingButton on:click={saveNotes} loading={$mutation.isPending}>
        <Label>Update</Label>
      </LoadingButton>
    </div>
  </Content>
</Dialog>
