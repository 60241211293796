<script lang="ts">
  import { ApiError } from '$lib/api/errors';
  import * as auth from '$lib/auth';
  import MainBorder from '$lib/components/MainBorder.svelte';
  import PageTitle from '$lib/components/PageTitle.svelte';
  import FieldBlock from '$lib/components/FieldBlock.svelte';
  import ErrorChunk from '$lib/components/ErrorChunk.svelte';
  import { capitalise, toMachineDate, toReadableDate } from '$lib/utils';
  import ExternalLink from '$lib/components/ExternalLink.svelte';
  import Avatar from '$lib/components/Images/Avatar.svelte';
  import LoadingButton from '$lib/components/LoadingButton.svelte';
  import { getMyContributorProfile } from '$lib/queries/contributors';
  import { getMyUserData } from '$lib/queries/users';
  import Dialog, { Actions, Content, Title } from '@smui/dialog';
  import { Label } from '@smui/button';
  import { getFieldInfo } from '$lib/queries/common';
  import { getProjectFieldInfo } from '$lib/project-utils';
  import { acceptPublicProjectMutation, getPublicProjectQuery } from '$lib/queries/projects';

  export let data;
  const { ref: projectRef } = data.params;

  const statusesAcceptingSubmissions = new Set<string | undefined>([
    'open',
    'collecting_responses'
  ]);

  const query = getPublicProjectQuery(projectRef);
  const fieldInfoQuery = getFieldInfo();
  const acceptMutation = acceptPublicProjectMutation(projectRef);
  $: userDataQuery = getMyUserData(true, auth.isLoggedIn());
  $: contributorQuery = getMyContributorProfile(!!$userDataQuery.data);

  $: noLongerAcceptingSubmissions = !statusesAcceptingSubmissions.has($query.data?.status);
  $: notFound = $query.error instanceof ApiError && $query.error.status === 404;
  $: deadlineHasPassed = $query.data?.deadline && $query.data?.deadline < toMachineDate(new Date());
  $: projectFieldInfo = getProjectFieldInfo($query.data?.type);
  $: isOwnProject = $userDataQuery.data && $userDataQuery.data.user__ref === $query.data?.user__ref;
  $: canAccept = !!$contributorQuery.data && !isOwnProject && !$query.data?.project_contributor_ref;
  $: loading =
    $query.isLoading ||
    $userDataQuery.isLoading ||
    $contributorQuery.isLoading ||
    $fieldInfoQuery.isLoading;
  $: mutationLoading = loading;
  $: showMissingInfoModal = !loading && !$contributorQuery;
</script>

<PageTitle name="Public Project{$query.data ? ` | ${$query.data.name}` : ''}" />

<MainBorder backButton="/projects/public" backButtonTitle="Public projects">
  <FieldBlock {loading}>
    {#if notFound}
      <h3 class="mb-3">Not found</h3>
      <p>
        We couldn't find what you were looking for 😬. Either it doesn't exist, or it could not
        longer be publicly visible. Sorry about that.
      </p>
    {:else if $query.error instanceof ApiError && $query.error?.status < 500}
      <h3 class="mb-3">{$query.error.title || 'Uh oh'}</h3>
      <p>
        {$query.error.message}
      </p>
      <p>
        {#if $query.error.id === 'already_invited'}
          <a href="/requests/{$query.error.data?.project_contributor_ref}">Go to request</a>
        {/if}
      </p>
    {:else if $query.isError}
      <ErrorChunk />
    {:else if deadlineHasPassed}
      <h3>Deadline passed</h3>
      <p>
        The deadline for this project has passed. You can no longer submit a contribution for this
        project.
      </p>
    {:else if noLongerAcceptingSubmissions}
      <h3>No longer accepting submissions</h3>
      <p>
        This project is no longer accepting submissions. Please <a href="/projects/public"
          >have a look at some other public projects</a
        > to find something that is still accepting submissions.
      </p>
    {:else}
      <div class="row">
        <div class="col">
          <h3>
            {$query.data?.name}
          </h3>
          {#if $query.data?.deadline}
            <p>
              <strong>{projectFieldInfo.deadlineLabel}:</strong>
              {toReadableDate($query.data?.deadline)}
            </p>
          {/if}
          <p>
            <strong>{projectFieldInfo.outletLabel}:</strong>
            {$query.data?.outlet}
          </p>
          {#if $query.data?.outlet_link}
            <p>
              <strong>{projectFieldInfo.outletLinkLabel}:</strong>
              <ExternalLink href={$query.data.outlet_link}>{$query.data.outlet_link}</ExternalLink>
            </p>
          {/if}
          {#if $query.data?.outlet_viewership}<p>
              <strong
                >{projectFieldInfo.outletViewershipLabel}
                :</strong
              >
              {$query.data?.outlet_viewership}
            </p>
          {/if}
          {#if $query.data?.meeting_link}
            <p>
              <ExternalLink href={$query.data?.meeting_link}
                >{projectFieldInfo.meetingLinkLabel}</ExternalLink
              >
            </p>
          {/if}
          {#if $query.data?.type}
            <p>
              <strong>Type:</strong>
              {capitalise($query.data.type)}
            </p>
          {/if}
          <p style="white-space: pre-line">{$query.data?.description ?? ''}</p>
        </div>
      </div>
      <div class="d-flex mt-3">
        <Avatar src={$query.data?.user__avatar_url} hasAvatar={!!$query.data?.user__avatar_url} />
        <div style="margin-left: 1.25em">
          <p>
            <strong>Name:</strong>
            {$query.data?.user__name}
          </p>
          {#if $query.data?.user__company_name}
            <p>
              <strong>Company:</strong>
              {$query.data.user__company_name}
            </p>
          {/if}
          <p>
            <strong>Job Title:</strong>
            {$query.data?.user__job_title}
          </p>
        </div>
      </div>
    {/if}
  </FieldBlock>
  <FieldBlock
    loading={mutationLoading}
    hidden={!canAccept && !$query.data?.project_contributor_ref}
  >
    {#if canAccept}
      <p class="text-center mt-0 mb-0">
        <LoadingButton loading={false} on:click={() => $acceptMutation.mutate()}>
          ✅ Accept Invitation
        </LoadingButton>
      </p>
    {/if}
    {#if $query.data?.project_contributor_ref}
      <p>
        You've already interacted with this public project, visit the <a
          href="/requests/{$query.data.project_contributor_ref}">request page</a
        >.
      </p>
    {/if}
  </FieldBlock>
</MainBorder>

<Dialog
  bind:open={showMissingInfoModal}
  aria-labelledby="Warning modal"
  aria-describedby="A Modal used to warn things"
  surface$style="width: 650px; max-width: calc(100vw - 32px);max-width: calc(100vh - 100px);"
  scrimClickAction=""
  escapeKeyAction=""
>
  <Title>Your profile is missing some information</Title>
  <Content>
    You'll need to fill out your profile including your contributor information in order to be able
    to accept this invitation. Please head to the profile page using the button below in order to
    update your information.
  </Content>
  <Actions>
    <a href="/settings/profile?next=/projects/public/{projectRef}">
      <LoadingButton loading={false}>
        <Label>Update my settings</Label>
      </LoadingButton>
    </a>
  </Actions>
</Dialog>

<style>
  :global(.mdc-select) {
    width: 100%;
    margin-bottom: 1em;
  }
</style>
