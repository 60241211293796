<script lang="ts">
  import Dialog, { Actions, Content, Title } from '@smui/dialog';
  import { Label } from '@smui/button';
  import LoadingButton from '$lib/components/LoadingButton.svelte';
  import { type Warning, warningStore } from '$lib/components/WarningModal/stores';
  import { goto } from '$app/navigation';

  let open: boolean = false;
  let content: string = '';
  let title: string = '';
  let close_url: string | undefined;
  let cta_url: string | undefined;
  let cta_title: string | undefined;

  warningStore.subscribe((warning: Warning) => {
    content = warning.message;
    close_url = warning.close_url;
    cta_url = warning.cta_url;
    cta_title = warning.cta_title;
    open = !!warning.message;
    title = warning.title;
  });

  const closeHandler = (e) => {
    if (close_url && e.detail.action === 'close') {
      goto(close_url);
    }
  };
</script>

<Dialog
  bind:open
  aria-labelledby="Warning modal"
  aria-describedby="A Modal used to warn things"
  surface$style="width: 650px; max-width: calc(100vw - 32px);max-width: calc(100vh - 100px);"
  on:SMUIDialog:closed={closeHandler}
>
  <Title>{title}</Title>
  <Content>
    {content}
  </Content>
  <Actions>
    {#if cta_url}
      <LoadingButton
        action="cta"
        loading={false}
        on:click={() => {
          if (cta_url) {
            goto(cta_url);
          }
        }}
      >
        <Label>{cta_title ?? 'Go to page'}</Label>
      </LoadingButton>
    {/if}
    <LoadingButton loading={false} action="close">
      <Label>Close</Label>
    </LoadingButton>
  </Actions>
</Dialog>
