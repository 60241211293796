<script lang="ts">
  import Card, { Content } from '@smui/card';
  import Button, { Label } from '@smui/button';
  import BecomeAContributor from './BecomeAContributor.svelte';
</script>

<div class="col-md-6 mb-5">
  <Card>
    <Content>
      <h2 class="pb-3 pt-2">Hello, we're Antiquoted</h2>
      <p>
        We connect business writers, podcasters, and event organizers with new voices and fresh
        faces.
      </p>

      <p>
        <strong>It's free,</strong> so come on inside.
      </p>

      <a href="/register">
        <Button>
          <Label>Sign Up</Label>
        </Button>
      </a>
      /
      <a href="/login">
        <Button>
          <Label>Log In</Label>
        </Button>
      </a>
    </Content>
  </Card>
</div>
<div class="col-md-6 mb-5">
  <Card>
    <BecomeAContributor />
  </Card>
</div>
