<script lang="ts">
  import Accordion, { Content, Header, Panel } from '@smui-extra/accordion/';
  import IconButton, { Icon } from '@smui/icon-button';
  import { getFAQs } from '$lib/queries/content.js';

  const query = getFAQs();
  const panels: Record<string, boolean> = {};
</script>

{#if $query.data}
  <Accordion class="mt-3">
    {#each $query.data as faq (faq.id)}
      <Panel bind:open={panels[faq.title]}>
        <Header style="text-align: left">
          <strong>{faq.title}</strong>
          <IconButton
            slot="icon"
            toggle
            pressed={panels[faq.title]}
            disabled
            style="margin-right:0"
          >
            <Icon class="material-icons" on>remove</Icon>
            <Icon class="material-icons">add</Icon>
          </IconButton>
        </Header>
        <Content style="text-align: left;white-space: pre-line;">
          {faq.content}
        </Content>
      </Panel>
    {/each}
  </Accordion>
{/if}
