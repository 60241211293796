<script lang="ts">
  import MainBorder from '$lib/components/MainBorder.svelte';
  import FieldBlock from '$lib/components/FieldBlock.svelte';
  import UserField from '$lib/components/UserTextField.svelte';
  import Button, { Label } from '@smui/button';
  import Select, { Option } from '@smui/select';
  import type { Project } from '$lib/types';
  import { getProjectQuery, updateProjectQuery } from '$lib/queries/projects';
  import DateField from '$lib/components/DateField.svelte';
  import { goto } from '$app/navigation';
  import StretchyTextArea from '$lib/components/StretchyTextArea.svelte';
  import PageTitle from '$lib/components/PageTitle.svelte';
  import { showErrorToast } from '$lib/components/Toaster/stores';
  import { PROJECT_STATUSES, type ProjectStatus } from '$lib/constants';
  import ErrorChunk from '$lib/components/ErrorChunk.svelte';
  import Dialog, { Actions, Content, Title } from '@smui/dialog';
  import { ApiError } from '$lib/api/errors';
  import { getProjectFieldInfo } from '$lib/project-utils';
  import { capitalise } from '$lib/utils';
  import * as auth from '$lib/auth';

  export let data;
  let project: Project;
  let originalProjectStatus: ProjectStatus;
  let open = false;

  const projectTypes = ['event', 'article', 'podcast'];
  const projectStatuses = Object.entries(PROJECT_STATUSES).map(([key, val]) => ({ key, val }));
  let errorStates: Record<string, boolean> = {};

  const query = getProjectQuery(data.params.ref);
  const mutation = updateProjectQuery();
  $: {
    if ($query.data && !project) {
      project = {
        ...$query.data,
        outlet_link: $query.data.outlet_link ?? '',
        outlet_viewership: $query.data.outlet_viewership ?? null,
        deadline: $query.data.deadline ?? '',
        description: $query.data.description ?? ''
      };
      originalProjectStatus = project.status;
    }
  }
  $: loading = $query.isLoading || $mutation.isPending || $mutation.isSuccess;

  const submit = async () => {
    errorStates = {};
    $mutation.mutate(
      {
        ref: data.params.ref,
        data: {
          ...project,
          outlet_viewership:
            project.type === 'podcast' || project.type === 'event'
              ? project.outlet_viewership
              : null,
          outlet_link: project.outlet_link ? project.outlet_link : null,
          deadline: project.deadline ? project.deadline : null
        }
      },
      {
        onSuccess: () => goto(`/projects/${project.ref}`),
        onError: (e: Error) => {
          showErrorToast(e);
          if (e instanceof ApiError) {
            const theId = e.errors?.[0]?.loc[2];
            errorStates[theId || ''] = true;
          }
        }
      }
    );
  };

  $: projectFieldInfo = getProjectFieldInfo(project?.type);
</script>

<PageTitle name="Edit project details" />

<form on:submit={submit}>
  <MainBorder backButton="/projects/{data.params.ref}" backButtonTitle="Back to project">
    <FieldBlock {loading}>
      {#if $query.error instanceof ApiError && $query.error.status === 404}
        <h3>Not found</h3>
        <p>
          We couldn't find the project you were looking for 😬. You can check your projects <a
            href="/projects">here</a
          >.
        </p>
      {:else if $query.isError}
        <ErrorChunk />
      {:else if $query.isSuccess}
        <h3>Edit your project:</h3>
        <p>
          Give as much detail as you can about the help or expertise you need. This will be sent to
          the contributors you invite to respond to your pitch.
        </p>
        <UserField
          id="name"
          label="Project Headline"
          bind:value={project.name}
          valueInvalid={!!errorStates.name}
          helperText="Projects must have a name."
          type="text"
          required
        />
        <div class="d-flex mb-2 justify-content-between gap-4">
          <Select
            class="flex-fill"
            bind:value={project.type}
            label="Request Type"
            id="type"
            invalid={!!errorStates.type}
            disabled
          >
            {#each projectTypes as option}
              <Option value={option}>{capitalise(option)}</Option>
            {/each}
          </Select>
          <Select
            class="flex-fill"
            bind:value={project.visibility}
            label="Visibility"
            id="visibility"
            invalid={!!errorStates.visiblity}
            required
          >
            <Option value="public">Public</Option>
            <Option value="private">Private</Option>
          </Select>
        </div>

        <UserField
          id="outlet"
          label={projectFieldInfo.outletLabel}
          bind:value={project.outlet}
          valueInvalid={!!errorStates.outlet}
          helperText="Name of the website, newspaper, podcast, or event where the contributor will be featured."
          type="text"
          required
        />
        <UserField
          id="outlet_link"
          label={projectFieldInfo.outletLinkLabel}
          bind:value={project.outlet_link}
          valueInvalid={!!errorStates.outlet_link}
          type="url"
        />
        <UserField
          id="outlet_viewership"
          label={projectFieldInfo.outletViewershipLabel ?? ''}
          bind:value={project.outlet_viewership}
          valueInvalid={!!errorStates.outlet_viewership}
          type="number"
          min={0}
          step={1}
        />
        <UserField
          id="meeting_link"
          label={projectFieldInfo.meetingLinkLabel}
          bind:value={project.meeting_link}
          valueInvalid={!!errorStates.meeting_link}
          type="url"
        />
        <p class="field-heading" style="margin: 0 0 1.5em;color:rgba(0, 0, 0, 0.6)">
          {projectFieldInfo.meetingLinkSubtitle}
        </p>
        <Select
          bind:value={project.status}
          label="Project Status"
          id="type"
          invalid={!!errorStates.type}
          on:SMUISelect:change={() => {
            if (project.status === 'completed') {
              open = true;
            }
          }}
        >
          {#each projectStatuses as option}
            <Option value={option.key}>{option.val}</Option>
          {/each}
        </Select>
        <DateField
          id="deadline"
          label={projectFieldInfo.deadlineLabel}
          bind:value={project.deadline}
          helperText="Please set a valid date for the deadline."
          switchIsChecked={!!project.deadline}
          disabledMessage="This is an ongoing project."
        />
        <p class="field-heading" style="margin: 0 0 1.5em;color:rgba(0, 0, 0, 0.6)">
          Note: Contributors won't be able to respond to your project once it passes its deadline.
        </p>
        <p class="field-heading" style="margin-bottom: 0.2em">
          {projectFieldInfo.descriptionTitle} <br />
          <span style="color:rgba(0, 0, 0, 0.6);font-size: .9em">
            {projectFieldInfo.descriptionSubTitle}
          </span>
        </p>
        <StretchyTextArea
          label="Project description"
          invalid={!!errorStates.description}
          id="submission"
          bind:value={project.description}
          maxLength={1000}
          showCharacterCount
        />
      {/if}
    </FieldBlock>
  </MainBorder>
  <div class="text-center mb-5 mt-5">
    <Button type="submit" variant="outlined" disabled={loading}>
      <Label>Update Project</Label>
    </Button>
  </div>
</form>

<Dialog bind:open aria-labelledby="simple-title" aria-describedby="simple-content">
  <!-- Title cannot contain leading whitespace due to mdc-typography-baseline-top() -->
  <Title id="simple-title">Great work!</Title>
  <Content id="simple-content">
    Bear in mind: Changing the status to ‘completed’ means contributors won’t be able to submit any
    more responses to this project.
  </Content>
  <Actions>
    <Button
      on:click={() => {
        project.status = originalProjectStatus;
      }}
    >
      <Label>Back</Label>
    </Button>
    <Button>
      <Label>OK</Label>
    </Button>
  </Actions>
</Dialog>
