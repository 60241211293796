import { createMutation, createQuery, useQueryClient } from '@tanstack/svelte-query';
import { goto } from '$app/navigation';
import { QUERY_KEYS } from '$lib/queries/keys';
import * as api from '$lib/api';
import { ProjectStatus } from '$lib/constants';
import { ApiError } from '$lib/api/errors';

type InvitationDetails = {
  ref: string;
  name: string;
  status: ProjectStatus;
  deadline?: string;
  description?: string;
  type: string;
  meeting_link?: string;
  outlet: string;
  outlet_link?: string;
  outlet_viewership?: number;
  user__ref: string;
  user__name: string;
  user__country: string;
  user__job_title?: string;
  user__company_name?: string;
  user__avatar_url?: string;
  created_at: string;
};

export const getInvitationDetails = (projectRef: string, projectToken: string) =>
  createQuery({
    queryKey: [...QUERY_KEYS.INVITATION_DETAILS, projectRef],
    queryFn: async () =>
      api.get<InvitationDetails>(`/engagements/invitations/${projectRef}/token/${projectToken}`)
  });

export const acceptInvitationMutation = (projectRef: string, token: string) => {
  const queryClient = useQueryClient();
  return createMutation<{ project_contributor_ref: string }, Error | ApiError>({
    mutationKey: QUERY_KEYS.INVITATION_DETAILS,
    mutationFn: async () =>
      api.post(`/engagements/invitations/${projectRef}/token/${token}/acceptance`, {}),
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: [...QUERY_KEYS.INVITATION_DETAILS, projectRef]
      });
    },
    onSuccess: async ({ project_contributor_ref }) => {
      return goto(`/requests/${project_contributor_ref}`);
    }
  });
};

export type Submission = {
  email: string;
  first_name: string;
  last_name: string;
  pronouns?: string;

  job_title: string;
  company_name: string;
  hide_company_name: boolean;

  submission: string;
  marketing_emails: boolean;
};
type SendingSubmission = Submission | { country: string };
export const submitInvitationMutation = (projectRef: string, token: string) => {
  const queryClient = useQueryClient();
  return createMutation<
    { uid: string; token: string; email: string },
    Error | ApiError,
    SendingSubmission
  >({
    mutationKey: QUERY_KEYS.INVITATION_DETAILS,
    mutationFn: async (payload) =>
      api.post(`/engagements/invitations/${projectRef}/token/${token}/submission`, payload),
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: [...QUERY_KEYS.INVITATION_DETAILS, projectRef]
      });
    },
    onSuccess: async ({ email, uid, token: passwordToken }) => {
      const b64Token = btoa(`${uid}|${passwordToken}|${email}`);
      return goto(`/invitations/${projectRef}/r/${token}/set-password/${b64Token}`);
    }
  });
};

// ToDo: New mutation to set password using the b64Token.
