<script lang="ts">
  import Textfield from '@smui/textfield';
  import HelperText from '@smui/textfield/helper-text';
  import { Label } from '@smui/button';
  import { logIn } from '$lib/auth';
  import Checkbox from '@smui/checkbox';
  import FormField from '@smui/form-field';
  import GoogleLogin from '../google-login.svelte';
  import { loginMutation, registerMutation } from '$lib/queries/auth';
  import LoadingButton from '$lib/components/LoadingButton.svelte';
  import { clearToaster, showErrorToast } from '$lib/components/Toaster/stores';

  let email: string | null = null;
  let emailInvalid = false;
  let password: string | null = null;
  let wantsMarketingEmails = true;
  let runningGoogleCallback = false;

  const mutation = registerMutation();
  const loginMutationQuery = loginMutation();

  const submit = async () => {
    if (!email || emailInvalid || !password) {
      showErrorToast(new Error('Please enter a valid email and password.'));
      return;
    }
    $mutation.mutate(
      { email, password, marketing_emails: wantsMarketingEmails },
      {
        onSuccess: async () => {
          $loginMutationQuery.mutate(
            { email: email!, password: password! },
            {
              onSuccess: (data) => {
                logIn(data, '/profile');
              }
            }
          );
        }
      }
    );
  };
  $: loading = $mutation.isPending || $loginMutationQuery.isPending || runningGoogleCallback;
</script>

<svelte:head>
  <title>Antiquoted | Sign Up</title>
</svelte:head>

<form on:submit={submit}>
  <h2 class="pb-4 pt-2 text-center">Join Antiquoted — it's free</h2>

  <GoogleLogin context="signup" marketingEmails={wantsMarketingEmails} bind:runningGoogleCallback />

  <Textfield
    style="width: 100%;"
    type="email"
    bind:value={email}
    bind:invalid={emailInvalid}
    label="Email"
    updateInvalid
    input$autocomplete="email"
    on:keyup={clearToaster}
    disabled={loading}
  >
    <HelperText validationMsg slot="helper">That's not a valid email address.</HelperText>
  </Textfield>
  <Textfield
    style="width: 100%;"
    type="password"
    bind:value={password}
    label="Password"
    input$autocomplete="password"
    input$minlength={10}
    on:keyup={clearToaster}
    disabled={loading}
  >
    <HelperText persistent slot="helper">Passwords must be at least 10 characters long.</HelperText>
  </Textfield>
  <div class="row mt-3 mb-3">
    <FormField class="text-center">
      <Checkbox bind:checked={wantsMarketingEmails} />
      <span slot="label">Let me know about marketing stuff.</span>
    </FormField>
  </div>
  <LoadingButton {loading} type="submit">
    <Label>Join</Label>
  </LoadingButton>
</form>
