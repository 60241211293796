import { createQuery, useQueryClient } from '@tanstack/svelte-query';
import { QUERY_KEYS } from '$lib/queries/keys';
import algoliasearch from 'algoliasearch/lite';
import { getEnvName, thirtyMinutesInMs } from '$lib/constants';
import * as api from '$lib/api';
import type { ContributorSearchResult, PaginationResponse } from '$lib/types';
import { toQueryString } from '$lib/utils';

type SearchProps = {
  applicationId: string;
  hitsPerPage: number;
  index: string;
  page: number;
  projectRef?: string;
  query: string;
  searchFilters: Record<string, string[]>;
  searchToken?: string;
};

export const getSearchToken = () =>
  createQuery({
    queryKey: QUERY_KEYS.PROJECT_CONTACTS_SEARCH_TOKEN,
    queryFn: async () => api.get<string>('/engagements/search/token'),
    staleTime: thirtyMinutesInMs,
    gcTime: thirtyMinutesInMs
  });

const isEmptySearch = ({ query, searchFilters }: SearchProps) => {
  return query === '' && Object.values(searchFilters).every((v) => v.length === 0);
};

export const getSearch = (params: SearchProps) => {
  const { applicationId, hitsPerPage, index, page, projectRef, query, searchFilters, searchToken } =
    params;
  const queryClient = useQueryClient();
  return createQuery({
    queryKey: [...QUERY_KEYS.SEARCH_CONTRIBUTORS, params],
    queryFn: async () => {
      if (!searchToken) {
        return null;
      }
      if (isEmptySearch(params)) {
        const baseUrl = projectRef
          ? `/engagements/projects/${projectRef}/contributors`
          : '/engagements/projects/contributors';
        const fromServer = await api.get<PaginationResponse<ContributorSearchResult>>(
          `${baseUrl}?${toQueryString({
            page: page + 1,
            limit: hitsPerPage
          })}`
        );
        return {
          hits: fromServer.items,
          nbHits: fromServer.total_count,
          nbPages: Math.ceil(fromServer.total_count / hitsPerPage)
        };
      }
      try {
        return await algoliasearch(applicationId, searchToken)
          .initIndex(`${getEnvName()}_${index}`)
          .search<ContributorSearchResult>(query, {
            hitsPerPage,
            page: page,
            filters: Object.entries(searchFilters)
              .filter(([key, values]) => values.length > 0)
              .map(([key, values]) => {
                const chunk = values.map((v) => `${key}:${v}`).join(' OR ');
                return `(${chunk})`;
              })
              .join(' AND ')
          });
      } catch (error: any) {
        if (error.status === 400) {
          await queryClient.invalidateQueries({
            queryKey: QUERY_KEYS.PROJECT_CONTACTS_SEARCH_TOKEN
          });
          return null;
        } else {
          throw error;
        }
      }
    },
    enabled: !!searchToken,
    staleTime: thirtyMinutesInMs,
    gcTime: thirtyMinutesInMs
  });
};
