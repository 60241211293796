<script lang="ts">
  import { goto } from '$app/navigation';

  export let wide = false;
  export let full = false;
  export let backButton: string | null = null;
  export let backButtonTitle = 'Back';
  export let forceShowTop = false;

  $: offset = full || wide ? '' : 'offset-md-1';
  $: leftChunkSize = wide ? 9 : 8;
  $: rightChunkSize = wide ? 3 : 2;

  const back = (e: Event) => {
    e.preventDefault();
    if (backButton) {
      goto(backButton);
    }
  };
</script>

<div class:container={!full} class:container-fluid={full} class:pt-3={!full} class:mb-5={!full}>
  <slot name="top" />
  {#if backButton || forceShowTop}
    <div class="row">
      <div class="{offset} col-md-{leftChunkSize} top-chunk">
        <slot name="top-left">
          <a on:click={back} href={backButton}>← {backButtonTitle} </a>
        </slot>
      </div>
      <div class="col-md-{rightChunkSize} top-chunk">
        <slot name="top-right" />
      </div>
    </div>
  {/if}

  <div class="row">
    <div
      class:non-rounded={full}
      class:col={wide || full}
      class:offset-md-1={!wide && !full}
      class:col-md-10={!wide && !full}
      class="main"
    >
      <slot />
    </div>
  </div>
</div>

<style lang="scss">
  .main {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 0 0.75em 2em;
    filter: drop-shadow(0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.2));

    @media (min-width: 576px) {
      border-radius: 1em;
      padding: 0 2.2em 2em;
    }

    &.non-rounded {
      border-radius: 0;
    }
  }

  .top-chunk {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
    @media (min-width: 768px) {
      margin-bottom: 2em;
      &:nth-child(1) {
        justify-content: left;
      }

      &:nth-child(2) {
        justify-content: right;
      }
    }
  }
</style>
