<script lang="ts">
  import List, { Item, Separator, Text } from '@smui/list';
  import Menu from '@smui/menu';
  import { menuStore } from '$lib/menu';
  import { onMount } from 'svelte';
  import * as auth from '$lib/auth';
  import * as utils from '$lib/utils';
  import { getMyContributorProfile } from '$lib/queries/contributors';
  import { getMyUser, getMyUserData } from '$lib/queries/users';

  let menu: Menu;
  $: {
    menuStore.set(menu);
  }

  let canSeeProjectManagement = false;
  let isAdmin = false;
  let isLoggedIn = false;

  const myUserData = getMyUserData(true, isLoggedIn);

  onMount(async () => {
    isLoggedIn = auth.isLoggedIn();
    isAdmin = auth.isAdmin();
    canSeeProjectManagement = auth.canSeeProjectManagement();
  });

  const logout = (e: Event) => {
    e.preventDefault();
    utils.logout();
  };
</script>

<Menu bind:this={menu} anchorCorner="BOTTOM_LEFT" anchor={false}>
  <List>
    <Item href="/" tag="a">
      <Text>Dashboard</Text>
    </Item>
    <Separator />
    {#if isLoggedIn}
      {#if canSeeProjectManagement}
        <Item href="/projects" tag="a">
          <Text>Projects</Text>
        </Item>
      {/if}
      <Item href="/contributors" tag="a">
        <Text>Contributor Search</Text>
      </Item>
      <Item href="/requests" tag="a">
        <Text>Contribution Requests</Text>
      </Item>
      <Separator />
      {#if $myUserData.data}
        <Item href="/profiles/{$myUserData.data.user__ref}" tag="a">
          <Text>My Profile</Text>
        </Item>
      {:else}
        <Item href="/settings/profile" tag="a">
          <Text>My Profile</Text>
        </Item>
      {/if}
      {#if isAdmin}
        <Item href="/admin" tag="a" rel="external">
          <Text>Admin</Text>
        </Item>
      {/if}
      <Item href="/settings" tag="a">
        <Text>Settings</Text>
      </Item>
      <Item href="/" tag="a" on:click={logout}>
        <Text>Logout</Text>
      </Item>
    {:else}
      <Item href="/login" tag="a">
        <Text>Login</Text>
      </Item>
      <Item href="/register" tag="a">
        <Text>Sign Up</Text>
      </Item>
    {/if}
  </List>
</Menu>
