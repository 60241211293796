<script lang="ts">
  import Textfield from '@smui/textfield';
  import HelperText from '@smui/textfield/helper-text';
  import Button, { Label } from '@smui/button';
  import Snackbar, { Actions } from '@smui/snackbar';
  import IconButton from '@smui/icon-button';
  import * as api from '$lib/api';
  import { resetPasswordMutation } from '$lib/queries/auth';
  import { showErrorToast } from '$lib/components/Toaster/stores';

  export let data;

  let password1: string | null = null;
  let password1Invalid = false;
  let password2: string | null = null;
  let password2Invalid = false;
  let resetComplete = false;

  const mutation = resetPasswordMutation(data.params.uid, data.params.token);

  const submit = async () => {
    if (!password1 || password1Invalid || !password2 || password2Invalid) {
      showErrorToast(new Error('Please enter a valid password.'));
      return;
    }
    $mutation.mutate(
      {
        new_password_1: password1,
        new_password_2: password2
      },
      {
        onSuccess: () => {
          resetComplete = true;
        }
      }
    );
  };
</script>

<svelte:head>
  <title>Antiquoted | Password Reset</title>
</svelte:head>

{#if resetComplete}
  <div class="row">
    <div class="col">
      <h1>Password Reset</h1>
      <p>Done! Hit the link below to login with your new password.</p>
      <p>
        <a href="/login">Login</a>
      </p>
    </div>
  </div>
{:else}
  <form on:submit={submit}>
    <div class="row">
      <div class="col-12">
        <h1>Password Reset</h1>
        <p>
          Almost there, enter your new password twice below (just to be sure) and we'll update it
          for you.
        </p>
      </div>
      <div class="col-12">
        <Textfield
          style="width: 100%;"
          type="password"
          bind:value={password1}
          bind:invalid={password1Invalid}
          label="New Password"
          updateInvalid
          input$autocomplete="password"
          input$minlength={10}
        >
          <HelperText validationMsg slot="helper">Password is not valid.</HelperText>
        </Textfield>
      </div>
      <div class="col-12">
        <Textfield
          style="width: 100%;"
          type="password"
          bind:value={password2}
          bind:invalid={password2Invalid}
          label="New Password again"
          updateInvalid
          input$autocomplete="password"
          input$minlength={10}
        >
          <HelperText persistent slot="helper"
            >Passwords must be at least 10 characters long.
          </HelperText>
        </Textfield>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3">
        <Button type="submit">
          <Label>Submit</Label>
        </Button>
      </div>
    </div>
  </form>
{/if}
