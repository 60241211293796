<script lang="ts">
  import { capitalise } from '$lib/utils.js';
  import { goto } from '$app/navigation';
  import LoadingButton from '$lib/components/LoadingButton.svelte';
  import Button, { Label } from '@smui/button';
  import { Content } from '@smui/card';
  import Dialog, { Actions, Title } from '@smui/dialog';
  import Separator from '$lib/components/Separator.svelte';
  import { getAvailableProjectsQuery, getProjectsQuery } from '$lib/queries/projects';
  import Select, { Option } from '@smui/select';
  import { putProjectContributor } from '$lib/queries/contributors';
  import { showErrorToast, showSuccessToast } from '$lib/components/Toaster/stores';

  let existingProject: string;
  const projects = getAvailableProjectsQuery();
  const mutation = putProjectContributor();

  export let modalData: {
    open: boolean;
    contributorRef: string;
    action: 'shortlist' | 'invite';
  };

  const submit = () => {
    if (!existingProject) {
      showErrorToast(new Error('Please select a project'));
      return;
    }
    const status = modalData.action === 'invite' ? 'invited' : 'shortlisted';
    $mutation.mutate(
      {
        ref: modalData.contributorRef,
        status,
        projectRefOverride: existingProject
      },
      {
        onSuccess: () => {
          modalData.open = false;
          showSuccessToast(`Successfully ${status} contributor to the project.`);
          goto(`/projects/${existingProject}`);
        }
      }
    );
  };
</script>

<Dialog
  bind:open={modalData.open}
  aria-labelledby="Project modal"
  aria-describedby="A Modal used to select a project"
  surface$style="width: 650px; max-width: calc(100vw - 32px);margin-top: -10em;overflow: visible"
>
  <Title>{capitalise(modalData.action)} this contributor</Title>
  <Content class="text-center">
    <Button
      variant="outlined"
      on:click={() => {
        modalData.open = false;
        goto(`/projects/add?${modalData.action}Contributor=${modalData.contributorRef}`);
      }}
      >Add them to a new project
    </Button>
    <Separator style="width: 80%;">or</Separator>
    <p class:disabled-text={$projects.data?.length === 0}>Add them to an existing project:</p>
    <div class="mt-1">
      <Select
        disabled={$projects.data?.length === 0}
        bind:value={existingProject}
        label="Projects"
        id="projects"
      >
        <Option value={undefined} />
        {#each $projects.data ?? [] as option}
          <Option value={option.ref}>{option.name}</Option>
        {/each}
      </Select>
      <LoadingButton
        disabled={$projects.data?.length === 0}
        loading={$mutation.isPending}
        on:click={submit}
        action="none"
      >
        <Label>Add</Label>
      </LoadingButton>
      {#if $projects.data?.length === 0}
        <p class="warning-text mt-3">You don’t have any open projects, please create one.</p>
      {/if}
    </div>
  </Content>
  <Actions>
    <LoadingButton loading={$mutation.isPending} action="close">
      <Label>Close</Label>
    </LoadingButton>
  </Actions>
</Dialog>
