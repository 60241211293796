<script lang="ts">
  import IconButton from '@smui/icon-button';
  import { isLoggedIn } from '$lib/auth';
  import { getUnreadNotificationCountQuery } from '$lib/queries/notifications';
  import { Icon } from '@smui/button';
  import Badge from '@smui-extra/badge';
  import { page } from '$app/stores';

  export let darkMode: boolean = false;
  const purple = 'rgb(121, 40, 202)';
  let isOnNotificationsPage = false;
  page.subscribe((value) => {
    isOnNotificationsPage = value?.url?.pathname === '/notifications';
  });

  $: unreadCount = getUnreadNotificationCountQuery(isLoggedIn());
  $: color = darkMode ? 'white' : purple;
  $: if (isOnNotificationsPage) {
    color = darkMode ? 'white' : purple;
  }
  $: kls = isOnNotificationsPage ? ' highlighted' : '';
  $: kls += darkMode ? ' dark' : '';
</script>

{#if isLoggedIn()}
  <IconButton
    href="/notifications"
    class="material-icons"
    style="font-size:1em;color:{color};margin-right: .5em;padding:7px;"
    tag="a"
  >
    <Icon class="material-icons{kls}" style="border-radius:9999px;padding:4px">notifications</Icon>
    {#if !isOnNotificationsPage && $unreadCount.data && $unreadCount.data > 0}
      <Badge
        class="unread-badge-identifier notification-unread-count"
        style="background-color:{color};"
        position="inset"
        aria-label="unread notification count"
      />
    {/if}
  </IconButton>
{/if}
