<script lang="ts">
  import UserField from './UserTextField.svelte';
  import Select, { Option } from '@smui/select';
  import SocialSvg from '$lib/img/social-svg.svelte';
  import type { Social } from '$lib/types';
  import { getSocialTitle, getSvgTag } from '$lib/utils';
  import { socialOptions } from '$lib/constants';

  export let data: Social;
  export let canEditTitle: boolean;
  export let isRequired = false;

  const iconColour = 'rgba(0, 0, 0, 0.4)';

  const svgTag = getSvgTag(data.name);
  $: required = Boolean(
    isRequired && (data.url || data.follower_count || (canEditTitle && data.name))
  );
</script>

<div class="row social-row">
  {#if canEditTitle}
    <div class="col-lg-2 title">
      <UserField
        label="Name"
        bind:value={data.name}
        type="text"
        {required}
        helperText="The name of the social network."
        valueInvalid={required && !data.name}
      />
    </div>
  {:else}
    <div class="col-lg-2 d-flex align-items-center">
      <SocialSvg fill={iconColour} svg={svgTag} />
      {getSocialTitle(data.name)}
    </div>
  {/if}

  <div class="col-lg-5">
    <UserField
      label="Profile URL"
      bind:value={data.url}
      type="text"
      {required}
      helperText="This is the url to your profile on the social network."
      valueInvalid={required && !data.url}
    />
  </div>
  <div class="col-lg-5">
    <Select
      bind:value={data.follower_count}
      label="Followers/visits per month"
      {required}
      invalid={required && !data.follower_count}
      style="width: 100%"
    >
      <Option value="" />
      {#each Object.entries(socialOptions) as [key, val] (key)}
        <Option value={key}>{val}</Option>
      {/each}
    </Select>
  </div>
</div>

<style lang="scss">
  .social-row {
    margin: 2em 0 0;
  }

  @media (min-width: 991px) {
    .title {
      padding-left: 1.2em;
    }
    .social-row {
      margin: 0;
    }
  }
</style>
