import { writable } from 'svelte/store';
import { ApiError } from '$lib/api/errors';

export type Warning = {
  title: string;
  message: string;
  close_url?: string;
  cta_url?: string;
  cta_title?: string;
};

export const warningStore = writable<Warning>({ title: '', message: '' });
export const paywallModalStore = writable<boolean>(false);

export const showPaywallModal = () => {
  paywallModalStore.update(() => true);
};

export function showWarning(e?: ApiError) {
  warningStore.update((): Warning => {
    if (e) {
      return {
        message: e.message,
        title: e.title ?? 'Something went wrong',
        close_url: e.data?.close_url,
        cta_url: e.data?.cta_url,
        cta_title: e.data?.cta_title
      };
    } else {
      return { message: '', title: '' };
    }
  });
}

export function clearWarning() {
  showWarning();
}
