import { createMutation } from '@tanstack/svelte-query';
import * as api from '$lib/api';
import { ApiError } from '$lib/api/errors';
import { tryRefresh } from '$lib/api';
import { getSessionToken } from '$lib/auth';
import { emailVerificationMessageSentStore } from '$lib/components/EmailVerificationTopBar/stores';
import { goto } from '$app/navigation';

export const tiggerVerificationMutation = () => {
  return createMutation({
    mutationFn: async () => {
      return api.post(`/auth/verification/token-message`, {});
    },
    retry: 0
  });
};

export const verifyMutation = (next?: string | null) => {
  return createMutation<
    { already_verified: boolean },
    Error | ApiError,
    { userRef: string; token: string }
  >({
    mutationFn: async ({ userRef, token }) => {
      return api.post(`/auth/verification/${userRef}/${token}`, {});
    },
    onSuccess: async (data) => {
      if (!data.already_verified) {
        await tryRefresh(getSessionToken());
        emailVerificationMessageSentStore.set(true);
        emailVerificationMessageSentStore.set(false);
      }
      if (next?.startsWith('/')) {
        await goto(next);
      } else if (!data.already_verified) {
        window.location.reload();
      }
    }
  });
};
