<script lang="ts">
  import MainBorder from '$lib/components/MainBorder.svelte';
  import FieldBlock from '$lib/components/FieldBlock.svelte';
  import PageTitle from '$lib/components/PageTitle.svelte';
  import { getConversationsQuery, sendMessageMutation } from '$lib/queries/messaging';
  import { onClickAndKeydown } from '$lib/directives';

  import IconButton, { Icon } from '@smui/icon-button';
  import { toReadableDate } from '$lib/utils';
  import MessageThread from '$lib/components/MessageThread.svelte';
  import ErrorChunk from '$lib/components/ErrorChunk.svelte';
  import Badge from '@smui-extra/badge';
  import Avatar from '$lib/components/Images/Avatar.svelte';

  let previousLastConversationUserRefs: Array<string | undefined> = [];
  let lastConversationUserRef: string | undefined;
  let selectedUserRef: string | undefined;
  let selectedUserName: string | undefined;
  let firstLoadComplete = false;
  let showConversationList = false;

  $: conversationsQuery = getConversationsQuery({
    last_ref: lastConversationUserRef
  });
  $: messageMutation = sendMessageMutation(selectedUserRef);
  $: if (!firstLoadComplete && $conversationsQuery.data?.items.length) {
    selectedUserRef = $conversationsQuery.data?.items[0].user__ref;
    selectedUserName = $conversationsQuery.data?.items[0].info.name;
    firstLoadComplete = true;
  }

  const nextPageConversations = (e: Event) => {
    selectedUserRef = undefined;
    firstLoadComplete = false;
    previousLastConversationUserRefs = [
      ...previousLastConversationUserRefs,
      lastConversationUserRef
    ];
    lastConversationUserRef = $conversationsQuery.data?.items.slice(-1)[0].user__ref;
  };
  const prevPageConversations = (e: Event) => {
    selectedUserRef = undefined;
    firstLoadComplete = false;
    lastConversationUserRef = previousLastConversationUserRefs.pop();
    previousLastConversationUserRefs = previousLastConversationUserRefs;
  };

  const selectUser = (ref: string) => {
    selectedUserRef = ref;
    selectedUserName = $conversationsQuery.data?.items.find((item) => item.user__ref === ref)?.info
      .name;
  };
</script>

<PageTitle name="Inbox" />

<MainBorder wide>
  <div slot="top">
    <div class="row">
      <div class="col">
        <h3 class="mt-3">Inbox</h3>
      </div>
    </div>
  </div>
  <div class="row">
    {#if $conversationsQuery.error}
      <FieldBlock>
        <div class="col">
          <ErrorChunk />
        </div>
      </FieldBlock>
    {:else if $conversationsQuery.data?.items.length === 0}
      <div class="col">
        <FieldBlock>
          <p class="text-center">
            No conversations yet. Once you start contributing or creating projects, you’ll be able
            to message people.
          </p>
        </FieldBlock>
      </div>
    {:else}
      <div class="col-lg-3">
        <FieldBlock loading={$conversationsQuery.isLoading}>
          <div
            class="conversation-title"
            class:toggled-on={showConversationList}
            use:onClickAndKeydown={() => {
              showConversationList = !showConversationList;
            }}
          >
            <h5>
              {selectedUserName}
              <IconButton slot="icon" toggle disabled class="filters-toggle">
                <Icon class="material-icons" on={!showConversationList}>expand_less</Icon>
                <Icon class="material-icons" on={showConversationList}>expand_more</Icon>
              </IconButton>
            </h5>
          </div>
          <div class="conversation-list" class:toggled-on={showConversationList}>
            {#each $conversationsQuery.data?.items || [] as item (item.user__ref)}
              <div
                class="notification"
                class:active={item.user__ref === selectedUserRef}
                use:onClickAndKeydown={() => {
                  selectUser(item.user__ref);
                }}
              >
                <div
                  class="user-name"
                  style="display: flex;justify-content: flex-start;align-items: flex-start; align-content: flex-start;position:relative"
                >
                  {#if item.has_unread_messages}
                    <Badge
                      class="unread-badge-identifier"
                      style="background-color:rgb(121, 40, 202);left:-8px;bottom:40px;"
                      position="inset"
                      align="middle-start"
                      aria-label="unread notification count"
                    />
                  {/if}
                  <Avatar
                    src={item.info.avatar_url}
                    hasAvatar={!!item.info.avatar_url}
                    small
                    style="margin: 0 1.5em 1em 0.5em"
                  />
                  <div style="padding-top: .5em;word-wrap: anywhere; margin-left: 1em">
                    {item.info.name}
                  </div>
                </div>
                <p class="created-at">{toReadableDate(item.last_message_date)}</p>
              </div>
            {/each}
          </div>
          {#if lastConversationUserRef || $conversationsQuery.data?.has_more}
            <nav
              aria-label="Page navigation example"
              class="d-flex justify-content-center align-items-center"
              class:toggled-on={showConversationList}
            >
              <IconButton
                class="material-icons"
                action="prev-page"
                title="Prev page"
                on:click={prevPageConversations}
                disabled={!previousLastConversationUserRefs.length}
              >
                chevron_left
              </IconButton>
              <IconButton
                class="material-icons"
                action="next-page"
                title="Next page"
                on:click={nextPageConversations}
                disabled={!$conversationsQuery.data?.has_more}
              >
                chevron_right
              </IconButton>
            </nav>
          {/if}
        </FieldBlock>
      </div>
      <div class="col-lg-9">
        {#if selectedUserRef}
          <MessageThread
            userRef={selectedUserRef}
            userName={selectedUserName}
            mutation={messageMutation}
            topPadding
          />
        {:else}
          <FieldBlock loading={$conversationsQuery.isLoading}>
            <p>Select a conversation to get started.</p>
          </FieldBlock>
        {/if}
      </div>
    {/if}
  </div>
</MainBorder>

<style lang="scss">
  .notification {
    padding: 1em 0.5em 0.75em;
    border-bottom: 1px solid #ddd;
    cursor: pointer;

    &:first-child {
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
    }

    &:last-child {
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
    }

    &.active {
      background-color: #f9f9f9;
    }
  }

  .conversation-title {
    cursor: pointer;
    @media (max-width: 991px) {
      display: block;
    }
    display: none;

    h5 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0;
    }
  }

  .conversation-list {
    &.toggled-on {
      display: block;
    }

    @media (min-width: 992px) {
      display: block;
    }
    display: none;

    .notification:last-child {
      border-bottom: none;
    }
  }

  nav {
    @media (max-width: 991px) {
      display: none !important;
    }
    @media (min-width: 992px) {
      display: flex !important;
    }

    &.toggled-on {
      display: flex !important;
    }
  }

  .user-name {
    margin-bottom: 0;
    font-size: 1.2rem;
  }

  .created-at {
    margin: 0;
    font-size: 0.8rem;
    color: #666;
  }
</style>
