<script lang="ts">
  import IconButton, { Icon } from '@smui/icon-button';
  import { Content, Header, Panel } from '@smui-extra/accordion';
  import type { FormOption } from '$lib/types';
  import Autocomplete from '@smui-extra/autocomplete';

  export let name: string;
  export let options: FormOption[];
  export let open: boolean;
  export let value: { value: string; label: string } | null;
  export let change: () => void;

  let previousValue = value;
  $: if (value !== previousValue) {
    previousValue = value;
    change();
  }
</script>

<Panel bind:open>
  <Header>
    <strong>{name}</strong>
    <IconButton slot="icon" toggle pressed={open} disabled style="margin-right:0">
      <Icon class="material-icons" on>expand_less</Icon>
      <Icon class="material-icons">expand_more</Icon>
    </IconButton>
  </Header>
  <Content>
    <Autocomplete
      id="country"
      {options}
      bind:value
      on:focusin={() => {
        value = null;
      }}
      label="Country"
      getOptionLabel={(option) => option?.label ?? ''}
      textfield$style="width: 100%;"
      textfield$input$autocomplete="off"
    />
  </Content>
</Panel>

<style>
  :global(
      .smui-accordion .smui-accordion__panel > .smui-accordion__header .smui-accordion__header__icon
    ) {
    margin-right: 0;
  }

  :global(
      .smui-accordion
        .smui-accordion__panel
        > .smui-accordion__header
        .smui-accordion__header__title
    ) {
    padding-right: 0;
  }
</style>
