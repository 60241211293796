<script lang="ts">
  import MainBorder from '$lib/components/MainBorder.svelte';
  import FieldBlock from '$lib/components/FieldBlock.svelte';
  import { getContributorQuery } from '$lib/queries/contributors';
  import Profile from '$lib/components/Profiles/Profile.svelte';

  export let data;
  $: contributorRef = data.params.ref;
  $: profile = getContributorQuery(contributorRef);
</script>

{#if $profile.data}
  <Profile userRef={$profile.data?.user__ref} backLink="/contributors" />
{:else}
  <MainBorder wide>
    <FieldBlock loading />
  </MainBorder>
{/if}
