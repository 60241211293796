import * as Sentry from '@sentry/sveltekit';
import { handleErrorWithSentry } from '@sentry/sveltekit';
import { HandleClientError, HandleServerError, RequestEvent } from '@sveltejs/kit';
import { dev } from '$app/environment';

Sentry.init({
  dsn: 'https://4c0927cb804db0fcdd838d2d58c54a58@o337866.ingest.sentry.io/4505753626542080',
  tracesSampleRate: 0.1,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // If you don't want to use Session Replay, just remove the line below:
  integrations: [Sentry.replayIntegration()],
  enabled: !dev,
  environment: window.location.hostname
});

const errHash = '#erreload';

const sentryErrorHandler = handleErrorWithSentry();
// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError: HandleServerError | HandleClientError = (input: {
  error: unknown;
  event: RequestEvent<Partial<Record<string, string>>, string | null>;
  status: number;
  message: string;
}) => {
  const { error, event } = input;
  console.log(event);
  sentryErrorHandler(input);
  // We're getting errors due to some flakey-ness in R2 on first fetch it seems,
  if (
    error instanceof Error &&
    (error.message?.includes('error loading dynamically imported module') ||
      error.message?.includes('Unable to preload CSS') ||
      error.message?.includes('Failed to fetch dynamically imported module'))
  ) {
    if (window.location.hash !== errHash) {
      console.log('Reloading window due to error loading dynamically imported module');
      window.location.hash = '#erreload';
      window.location.reload();
    } else {
      sentryErrorHandler({
        ...input,
        error: new Error('Reload did not work')
      });
    }
  }
};
