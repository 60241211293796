<script lang="ts">
  import Card from '@smui/card';
  import MediaProjects from './MediaProjects.svelte';
  import ContributorRequests from './ContributorRequests.svelte';
  import { getMyUserData } from '$lib/queries/users';
  import { UserType } from '$lib/constants';
  import { getDashboardProjectsQuery } from '$lib/queries/projects';
  import * as auth from '$lib/auth';
  import { getPendingContributions } from '$lib/queries/contributions';
  import { canSeeProjectManagement } from '$lib/auth';
  import UserProfile from './UserProfile.svelte';

  let components: Array<typeof MediaProjects | typeof ContributorRequests> = [];
  $: myUserData = getMyUserData(true);
  $: userType = $myUserData.data?.user_type;

  $: myProjects = getDashboardProjectsQuery(auth.canSeeProjectManagement());
  $: pendingContributions = getPendingContributions(canSeeProjectManagement());

  // ToDo: add UserProfile
  $: switch (userType) {
    case UserType.contributor:
      components = [ContributorRequests, ...($myProjects.data?.total_count ? [MediaProjects] : [])];
      break;
    case UserType.media_person:
      components = [
        MediaProjects,
        ...($pendingContributions.data?.total_count ? [ContributorRequests] : [])
      ];
      break;
    default:
      components = [MediaProjects, ContributorRequests];
      break;
  }
</script>

{#each components as component}
  <div class="mb-5" class:col-md-6={components.length > 1} class:col-md-8={components.length === 1}>
    <Card>
      <svelte:component this={component} />
    </Card>
  </div>
{/each}
{#if components.length === 1}
  <div class="col-md-4">
    <Card>
      <UserProfile />
    </Card>
  </div>
{/if}
