<script lang="ts">
  import HelperText from '@smui/textfield/helper-text';
  import Textfield from '@smui/textfield';
  import { Icon } from '@smui/icon-button';

  export let value: undefined | string | null | number;
  export let type: string;
  export let valueInvalid = false;
  export let updateInvalid = true;
  export let label: string;
  export let helperText = '';
  export let required = false;
  export let max: number | undefined = undefined;
  export let min: number | undefined = undefined;
  export let step: number | undefined = undefined;
  export let disabled = false;
  export let icon: string | undefined = undefined;
  export let variant: 'filled' | 'outlined' | 'standard' = 'standard';
  export let onChange: (() => void) | undefined = undefined;

  export let id: string | undefined = undefined;

  export let style = 'width: 100%;';
</script>

<Textfield
  {id}
  {style}
  {type}
  bind:value
  bind:invalid={valueInvalid}
  {label}
  {updateInvalid}
  input$autocomplete={type}
  input$max={max}
  input$maxlength={max}
  input$min={min}
  input$step={step}
  {required}
  {disabled}
  {variant}
  on:keyup={onChange}
>
  <HelperText validationMsg slot="helper">
    {helperText}
  </HelperText>
  {#if icon}
    <Icon color="rgba(0, 0, 0, 0.6)" class="material-icons" slot="leadingIcon">{icon}</Icon>
  {/if}
</Textfield>
