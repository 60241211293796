import { capitalise } from '../utils';

type ValidationError = {
  msg: string;
  loc: Array<string>;
};

export class ApiError extends Error {
  status: number;
  errors?: ValidationError[];
  title?: string;
  id?: string;
  data?: Record<string, any>;

  constructor(
    status: number,
    message: string,
    errors?: ValidationError[],
    title?: string,
    id?: string,
    data?: Record<string, any>
  ) {
    super(message, data);
    this.status = status;
    this.errors = errors;
    this.title = title;
    this.id = id;
    this.data = data;
  }

  toToastMessage() {
    if (this.errors) {
      return this.errors
        .map((err) => `${capitalise(err.loc[err.loc.length - 1])}: ${err.msg}`)
        .join(', ');
    }
    return this.message;
  }

  getErrorFields(): Record<string, boolean> {
    if (!this.errors) {
      return {};
    }
    return this.errors.reduce((acc, err) => {
      acc[err.loc[err.loc.length - 1]] = true;
      return acc;
    }, {} as Record<string, boolean>);
  }
}
