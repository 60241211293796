<script>
  import MainBorder from '$lib/components/MainBorder.svelte';
  import PageTitle from '$lib/components/PageTitle.svelte';
  import FieldBlock from '$lib/components/FieldBlock.svelte';
  import Textfield from '@smui/textfield';
  import HelperText from '@smui/textfield/helper-text';
  import LoadingButton from '$lib/components/LoadingButton.svelte';
  import { Label } from '@smui/button';
  import { loginMutation, resetPasswordMutation } from '$lib/queries/auth';
  import { showErrorToast } from '$lib/components/Toaster/stores';
  import * as auth from '$lib/auth';

  export let data;
  const { b64Token } = data.params;
  const decoded = atob(b64Token);
  const [uid, token, email] = decoded.split('|');
  const mutation = resetPasswordMutation(uid, token);
  const loginMutationQuery = loginMutation();

  let password1 = '';
  let password2 = '';
  let password1Invalid = false;
  let password2Invalid = false;
  let loading = false;

  const submit = async () => {
    if (!password1 || password1Invalid || !password2 || password2Invalid) {
      showErrorToast(new Error('Please enter a valid password.'));
      return;
    }
    $mutation.mutate(
      {
        new_password_1: password1,
        new_password_2: password2
      },
      {
        onSuccess: async () => {
          $loginMutationQuery.mutate(
            {
              email,
              password: password1
            },
            {
              onSuccess: (data) => {
                auth.logIn(data, '/settings/profile?invitation=true');
              }
            }
          );
        }
      }
    );
  };
</script>

<PageTitle name="Invitation Submitted" />

<MainBorder>
  <FieldBlock>
    <h2>Thanks for your submission</h2>
    <p>
      If you want to receive updates about your submission then please set your password below. This
      will grant you access to your dashboard where you can see your submission status and message
      the project owner.
    </p>
    <form on:submit={submit}>
      <Textfield
        style="width: 100%;"
        type="password"
        bind:value={password1}
        bind:invalid={password1Invalid}
        label="Password"
        updateInvalid
        input$autocomplete="password"
        input$minlength={10}
      >
        <HelperText validationMsg slot="helper">Password is not valid.</HelperText>
      </Textfield>
      <Textfield
        style="width: 100%;"
        type="password"
        bind:value={password2}
        bind:invalid={password2Invalid}
        label="Password again"
        updateInvalid
        input$autocomplete="password"
        input$minlength={10}
      >
        <HelperText persistent slot="helper"
          >Passwords must be at least 10 characters long.
        </HelperText>
      </Textfield>
      <div class="row text-center mt-5 mb-3">
        <div class="col">
          <LoadingButton {loading} type="submit" variant="raised">
            <Label>Set a password</Label>
          </LoadingButton>
        </div>
      </div>
    </form>

    <!--    ToDo: split out user ref and token from b64Token -->
    <!--    ToDo: input to set password here using userref + token -->
    <!--    ToDo: submit button -->
  </FieldBlock>
</MainBorder>
