<script lang="ts">
  import { onClickAndKeydown } from '$lib/directives';

  import { goto } from '$app/navigation';
  import MainBorder from '$lib/components/MainBorder.svelte';
  import FieldBlock from '$lib/components/FieldBlock.svelte';
  import PageTitle from '$lib/components/PageTitle.svelte';
  import AQTable from '$lib/components/AQTable.svelte';
  import SortableTh from '$lib/components/SortableTh.svelte';
  import { getMyContributorProfile } from '$lib/queries/contributors';
  import { getContributions } from '$lib/queries/contributions';
  import { capitalise } from '$lib/utils';
  import { PROJECT_CONTACT_STATUSES } from '$lib/constants';
  import Button, { Label } from '@smui/button';
  import DeletedIfNull from '$lib/components/DeletedIfNull.svelte';
  import { getPublicProjectsQuery } from '$lib/queries/projects';

  let rowsPerPage = 10;
  let currentPage = 0;
  let sort: string;
  let order: 'asc' | 'desc' = 'desc';

  const contributorProfileQuery = getMyContributorProfile();
  $: query = getPublicProjectsQuery({
    sort,
    sort_direction: order,
    limit: rowsPerPage,
    page: currentPage + 1
  });
  $: projects = $query.data?.items ?? [];
  $: loading = $query.isLoading || $contributorProfileQuery.isLoading;
</script>

<PageTitle name="Request History" />

<MainBorder wide>
  <div slot="top">
    <h3 class="mt-3">Public Projects</h3>
    <p>This is a list of projects on antiquoted that currently have public visibility.</p>
    <a href="/settings/profile">
      <Button variant="outlined" class="mb-4">
        <Label>Update your profile before accepting</Label>
      </Button>
    </a>
  </div>

  <FieldBlock {loading}>
    <AQTable
      hardError={$query.isError}
      bind:currentPage
      totalCount={$query.data?.total_count ?? 0}
      {rowsPerPage}
      paginationName="Projects"
      showPagination={Number($query.data?.total_count) > 0}
    >
      <svelte:fragment slot="header">
        <th>Media person</th>
        <SortableTh bind:direction={order} fieldName="outlet" bind:sort>Media Outlet</SortableTh>
        <SortableTh bind:direction={order} fieldName="name" bind:sort>Project Name</SortableTh>
        <SortableTh bind:direction={order} fieldName="deadline" bind:sort>Deadline</SortableTh>
        <SortableTh bind:direction={order} fieldName="type" bind:sort>Type</SortableTh>
      </svelte:fragment>
      <svelte:fragment slot="body">
        {#if projects.length === 0}
          <tr>
            <td colspan={5}>
              <p class="text-center">
                No public projects right now, come back later or maybe create one yourself?
              </p>
            </td>
          </tr>
        {/if}
        {#each projects as item (item.ref)}
          <tr
            style="cursor:pointer;"
            use:onClickAndKeydown={() => {
              goto(`/projects/public/${item.ref}`);
            }}
          >
            <td>
              <DeletedIfNull data={item?.user__name} />
            </td>
            <td>
              <DeletedIfNull data={item?.outlet} />
            </td>
            <td>
              <DeletedIfNull data={item?.name} />
            </td>
            <td>{item?.deadline ?? 'None'}</td>
            <td>{item?.type ? capitalise(item.type) : 'None'}</td>
          </tr>
        {/each}
      </svelte:fragment>
    </AQTable>
  </FieldBlock>
</MainBorder>
