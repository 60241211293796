<script lang="ts" xmlns="http://www.w3.org/1999/html">
  import Textfield from '@smui/textfield';
  import HelperText from '@smui/textfield/helper-text';
  import { Label } from '@smui/button';
  import { logIn } from '$lib/auth';
  import GoogleLogin from '../google-login.svelte';
  import { loginMutation } from '$lib/queries/auth';
  import LoadingButton from '$lib/components/LoadingButton.svelte';
  import { clearToaster, showErrorToast } from '$lib/components/Toaster/stores';

  let email: string | null = null;
  let emailInvalid = false;
  let password: string | null = null;
  let runningGoogleCallback = false;

  const mutation = loginMutation();

  const submit = async (e: Event) => {
    e.preventDefault();
    if (!email || emailInvalid || !password) {
      showErrorToast(new Error('Please enter a valid email and password.'));
      return;
    }
    $mutation.mutate(
      { email, password },
      {
        onSuccess: (data) => {
          const nextPage = new URLSearchParams(window.location.search).get('next');
          logIn(data, nextPage ?? undefined);
        }
      }
    );
  };
  $: loading = $mutation.isPending || runningGoogleCallback;
</script>

<svelte:head>
  <title>Antiquoted | Login</title>
</svelte:head>

<form on:submit={submit}>
  <h2 class="pb-4 pt-2 text-center">Welcome back — it's good to see you</h2>

  <GoogleLogin context="signin" bind:runningGoogleCallback />

  <Textfield
    style="width: 100%;"
    type="email"
    bind:value={email}
    bind:invalid={emailInvalid}
    label="Email"
    updateInvalid
    input$autocomplete="email"
    on:keyup={clearToaster}
    disabled={loading}
  >
    <HelperText validationMsg slot="helper">That's not a valid email address.</HelperText>
  </Textfield>
  <Textfield
    style="width: 100%;"
    type="password"
    bind:value={password}
    label="Password"
    input$autocomplete="password"
    input$minlength={1}
    on:keyup={clearToaster}
    disabled={loading}
  >
    <HelperText persistent slot="helper"
      ><a href="/login/forgotten-password">Forgot your password?</a></HelperText
    >
  </Textfield>
  <LoadingButton {loading} type="submit" class="mt-3">
    <Label>Submit</Label>
  </LoadingButton>
  <div class="row text-center">
    <p>Or need to <a href="/register">Sign Up?</a></p>
  </div>
</form>
