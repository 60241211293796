<script>
  import Button, { Label } from '@smui/button';
  import { getDashboardProjectsQuery } from '$lib/queries/projects';
  import { Content } from '@smui/card';
  import Skeleton from '$lib/components/Skeleton.svelte';
  import { goto } from '$app/navigation';
  import { PROJECT_STATUSES } from '$lib/constants';
  import AQTable from '$lib/components/AQTable.svelte';
  import { onClickAndKeydown } from '$lib/directives';
  import { getMyUserData } from '$lib/queries/users';
  import AddProjectButton from '$lib/components/AddProjectButton.svelte';
  import * as auth from '$lib/auth';

  const myData = getMyUserData(true, auth.isLoggedIn());
  $: projects = getDashboardProjectsQuery(auth.canSeeProjectManagement());

  $: loading = $projects.isLoading || $myData.isLoading;
</script>

<Content>
  {#if loading}
    <Skeleton style="margin: 1em auto; width:60%;" />
    <Skeleton />
    <Skeleton />
    <Skeleton style="margin: 2em auto 0; width:60%" />
  {:else if !auth.canSeeProjectManagement()}
    <h2 class="pb-2 pt-2">Media Projects</h2>
    <p>Please verify your email before you can create a new project.</p>
  {:else}
    <h2 class="pb-2 pt-2">Media Projects</h2>
    {#if $projects.data?.total_count === 0}
      <p>
        No projects yet. Create a new project if you’d like to find contributors to speak at an
        event, on a podcast or provide a quote to feature in an article you're writing.
      </p>
      <AddProjectButton />
    {:else}
      <AQTable
        currentPage={1}
        totalCount={$projects.data?.total_count ?? 0}
        rowsPerPage={5}
        showPagination={false}
        hardError={$projects.isError}
      >
        <!-- toDo: add error handling here -->
        <svelte:fragment slot="header">
          <th>Title</th>
          <th>Status</th>
          <th>Responses</th>
        </svelte:fragment>
        <svelte:fragment slot="body">
          {#each $projects.data?.items || [] as item (item.ref)}
            <tr
              style="cursor:pointer;"
              use:onClickAndKeydown={() => {
                goto(`/projects/${item.ref}`);
              }}
            >
              <td>{item.name}</td>
              <td>{PROJECT_STATUSES[item.status]}</td>
              <td>{item.response_count}</td>
            </tr>
          {/each}
        </svelte:fragment>
      </AQTable>
      <a href="/projects">
        <Button variant="outlined">
          <Label>See all</Label>
        </Button>
      </a>
      <a href="/projects/add">
        <Button variant="outlined">
          <Label>Add Project</Label>
        </Button>
      </a>
    {/if}
  {/if}
</Content>
