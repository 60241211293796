import { createMutation, createQuery, useQueryClient } from '@tanstack/svelte-query';
import { ApiError } from '$lib/api/errors';
import { QUERY_KEYS } from '$lib/queries/keys';
import * as api from '$lib/api';
import * as auth from '$lib/auth';

export type EmailPreferences = Record<string, boolean> & {
  submission_received: boolean;
  contributor_accepted: boolean;
  contributor_declined: boolean;
  owner_invited: boolean;
  owner_approved: boolean;
  owner_declined: boolean;
  unread_message: boolean;
  project_deadline_approaching: boolean;
};
type PartialEmailPreference = Partial<EmailPreferences>;
const emailNotificationPreferencesUrl = '/notifications/email/preferences';
const userUrl = '/users/self';
export const getMyEmailNotificationPreferences = () =>
  createQuery<unknown, Error | ApiError, EmailPreferences>({
    queryKey: QUERY_KEYS.SETTINGS_EMAIL_NOTIFICATION_PREFERENCES,
    queryFn: () => api.get<EmailPreferences>(emailNotificationPreferencesUrl)
  });

export const setMyEmailNotificationPreferences = () => {
  const queryClient = useQueryClient();
  return createMutation<EmailPreferences, Error | ApiError, PartialEmailPreference>({
    mutationFn: async (preferences: PartialEmailPreference) =>
      api.patch<EmailPreferences>(emailNotificationPreferencesUrl, preferences),
    onError: async () =>
      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.SETTINGS_EMAIL_NOTIFICATION_PREFERENCES
      }),
    onSuccess: async (data) =>
      queryClient.setQueryData(QUERY_KEYS.SETTINGS_EMAIL_NOTIFICATION_PREFERENCES, data)
  });
};

export const deleteMyUserMutation = () =>
  createMutation<void, Error | ApiError, string>({
    mutationFn: async (password) => api.del<void>(userUrl, { password }),
    onSuccess: async () => {
      auth.logout();
    }
  });
