import { thirtyMinutesInMs } from '../constants';

type StorageItem = {
  expiry: number;
  data: Record<string, any>;
};
const cachePrefix = 'aq_query_cache';

export const getData = (key: string): Record<string, any> | null => {
  const cacheKey = `${cachePrefix}:${key}`;
  const val = window.sessionStorage.getItem(cacheKey);
  if (!val) {
    return null;
  }
  const { expiry, data } = JSON.parse(val) as StorageItem;
  if (expiry < Date.now()) {
    return null;
  }
  return data;
};

export const setData = (key: string, data: Record<string, any>) => {
  const cacheKey = `${cachePrefix}:${key}`;
  window.sessionStorage.setItem(
    cacheKey,
    JSON.stringify({
      data,
      expiry: Date.now() + thirtyMinutesInMs
    })
  );
};
