<script lang="ts">
  import Snackbar, { Actions } from '@smui/snackbar';
  import IconButton from '@smui/icon-button';
  import { Label } from '@smui/button';
  import type { Toast } from './stores';
  import { toastStore } from './stores';

  let snackbar: Snackbar;
  let content = '';
  let kls = 'error';

  toastStore.subscribe((toast: Toast) => {
    kls = toast.kls;
    content = toast.message;
    if (toast.message) {
      snackbar.forceOpen();
    } else {
      snackbar?.close();
    }
  });
</script>

<Snackbar bind:this={snackbar} class={kls}>
  <Label>{content}</Label>
  <Actions>
    <IconButton class="material-icons" title="Dismiss">close</IconButton>
  </Actions>
</Snackbar>
