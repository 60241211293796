<script lang="ts">
  import { toMachineDate } from '$lib/utils';
  import { DatePicker } from 'date-picker-svelte';
  import UserField from '$lib/components/UserTextField.svelte';
  import { onMount } from 'svelte';
  import Switch from '@smui/switch';

  export let helperText: string;
  export let id: string;
  export let label: string;
  export let value: string;
  export let disabledMessage: string | undefined = undefined;
  export let switchIsChecked = true;

  let selectionDate = new Date();
  // let switchIsChecked = true;

  let showPicker = false;
  const hidePicker = (e?: Event) => {
    if (!e || !document.querySelector('#deadline')?.contains(e.target as Node)) {
      showPicker = false;
    }
  };

  const keyup = (e: KeyboardEvent) => {
    if (e.key === 'Delete' || e.key === 'Backspace') {
      hidePicker();
    }
  };

  onMount(() => {
    document.querySelector('body')?.addEventListener('click', hidePicker);
    document.querySelector('form')?.addEventListener('focus', hidePicker);
    return () => {
      document.querySelector('body')?.removeEventListener('click', hidePicker);
      document.querySelector('for')?.removeEventListener('focus', hidePicker);
    };
  });
  const toggleSwitch = (e) => {
    if (!switchIsChecked) {
      value = '';
    }
  };
</script>

<div
  {id}
  style="position: relative; margin-top: 1em"
  on:focus={() => {
    if (switchIsChecked) {
      showPicker = true;
    }
  }}
  on:keyup={keyup}
  role="textbox"
  tabindex="-1"
>
  {#if disabledMessage}
    <div style="display: flex; justify-content: flex-end;">
      <div style="flex-grow: 1">
        {#if switchIsChecked}
          <UserField
            {label}
            bind:value
            {helperText}
            type={value ? 'date' : 'text'}
            style="width: 100%;"
            required
          />
        {:else}
          <p style="line-height: 3.5em;color: rgba(0, 0, 0, 0.6);">{disabledMessage}</p>
        {/if}
      </div>
      <Switch bind:checked={switchIsChecked} on:SMUISwitch:change={toggleSwitch} />
    </div>
  {:else}
    <UserField {label} bind:value {helperText} type={value ? 'date' : 'text'} style="width: 100%" />
  {/if}
  <div hidden={!showPicker} style="position: absolute; top: 0; right: 0; z-index: 100;">
    <DatePicker
      bind:value={selectionDate}
      on:select={() => {
        value = toMachineDate(selectionDate);
        showPicker = false;
      }}
      min={new Date(1900, 0, -1)}
      max={new Date(2200, 0, -1)}
    />
  </div>
</div>
