<script lang="ts">
  import { showSuccessToast } from './Toaster/stores';
  import Button, { Icon } from '@smui/button';
  import CustomTooltip from '$lib/components/CustomTooltip.svelte';

  export let href: string;
  export let tooltip = 'Click here to copy.';
  export let toastText = 'Copied to clipboard!';
  export let iconName = 'content_copy';
  export let fullWidth = true;

  let linkElement: HTMLAnchorElement;

  const copyFullLink = async (text?: string) => {
    await navigator.clipboard.writeText(text ?? '');
    showSuccessToast(toastText);
  };

  const cliq = (e: MouseEvent) => {
    e.preventDefault();
    copyFullLink(linkElement.href as string);
  };

  const theWidth = fullWidth ? '100%' : 'auto';
  const theDisplay = fullWidth ? 'flex' : 'inline-flex';
</script>

<CustomTooltip
  content={tooltip}
  style="display: {theDisplay}; align-items: center; width: {theWidth}"
  {...$$restProps}
>
  <a {href} on:click={cliq} bind:this={linkElement} style="width: {theWidth}">
    <Button variant="outlined" style="width: 100%;height:auto;min-height:36px;line-height:2em">
      <Icon
        class="aq-purple-icon-button material-icons"
        style="text-decoration: none"
        on:click={() => copyFullLink(linkElement.href)}
      >
        {iconName}
      </Icon>
      <slot />
    </Button>
  </a>
</CustomTooltip>
