<script lang="ts">
  import { onMount } from 'svelte';
  import * as auth from '$lib/auth';

  onMount(() => {
    if (!auth.isLoggedIn()) {
      window.location.href = `/login?next=${window.location.pathname}`;
    }
  });
</script>

<slot />
