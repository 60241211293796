<script lang="ts">
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  import Button, { Label } from '@smui/button';
  import Snackbar, { Actions } from '@smui/snackbar';
  import IconButton from '@smui/icon-button';
  import Autocomplete from '@smui-extra/autocomplete';
  import UserField from '$lib/components/UserTextField.svelte';
  import type { ContributorOnlyProfile, Profile, UserDataOnlyProfile } from '$lib/types';
  import FormField from '@smui/form-field';
  import Checkbox from '@smui/checkbox';
  import Textfield from '@smui/textfield';
  import CharacterCounter from '@smui/textfield/character-counter';
  import Select, { Option } from '@smui/select';
  import SocialDetails from '$lib/components/SocialDetails.svelte';
  import { goto } from '$app/navigation';
  import FieldBlock from '$lib/components/FieldBlock.svelte';
  import MainBorder from '$lib/components/MainBorder.svelte';
  import { getMyContributorProfile, putMyContributorProfileQuery } from '$lib/queries/contributors';
  import { getFieldInfo } from '$lib/queries/common';
  import Expertise from '$lib/components/Expertise.svelte';
  import { ApiError } from '$lib/api/errors';
  import { getMyUserData } from '$lib/queries/users';
  import { showSuccessToast } from '$lib/components/Toaster/stores';
  import LoadingButton from '$lib/components/LoadingButton.svelte';
  import CroppableAvatarDialog from '$lib/components/Images/CroppableAvatarDialog.svelte';
  import CroppableAvatarUpload from '$lib/components/Images/CroppableAvatarUpload.svelte';
  import Pronouns from '$lib/components/Pronouns.svelte';
  import { UserType } from '$lib/constants';
  import Dialog, { Content, Title, Actions as DialogActions } from '@smui/dialog';
  import { onMount } from 'svelte';

  let invitationOnboardingModal = false;
  const defaultHelpText = 'We need to know this to find you the right kind of opportunities.';

  let snackbarContent: string | null = null;
  let snackbar: Snackbar;
  let countries: Array<Record<string, string>> = [];

  const addSocial = (e: Event) => {
    e.preventDefault();
    contributorOnlyFields.socials = [
      ...contributorOnlyFields.socials,
      {
        name: '',
        url: '',
        follower_count: null
      }
    ];
  };

  let userDataFields: Omit<UserDataOnlyProfile, 'country'> & {
    country: { label: string; id: string } | null;
  } = {
    first_name: '',
    last_name: '',
    country: null,
    city: '',
    pronouns: '',

    job_title: '',
    company_name: '',
    company_size: undefined,
    years_of_experience: '',
    user_type: 'contributor'
  };
  let contributorOnlyFields: Omit<ContributorOnlyProfile, 'ref'> = {
    visible: true,
    expertise: [],
    contribution_types: [],
    talking_points: '',
    identifies_as: [],

    contributor_experience: [],
    socials: [
      { name: 'linkedin', url: '', follower_count: null },
      { name: 'twitter', url: '', follower_count: null },
      { name: 'blog', url: '', follower_count: null },
      { name: 'newsletter', url: '', follower_count: null }
    ]
  };

  let fieldErrorStates: Record<string, boolean> = {};

  const userQuery = getMyUserData();
  const contributorQuery = getMyContributorProfile();
  const fieldInfoQuery = getFieldInfo();

  $: if ($fieldInfoQuery.data && countries.length === 0) {
    countries = Object.entries($fieldInfoQuery.data!.countries).map(([id, label]) => ({
      id,
      label
    }));
  }

  let hasUpdatedUserFields = false;
  $: if (!hasUpdatedUserFields && $userQuery.data && $fieldInfoQuery.data) {
    hasUpdatedUserFields = true;
    const userTypeFromSearch = new URLSearchParams(window.location.search).get('user-type');
    userDataFields = {
      first_name: $userQuery.data.first_name,
      last_name: $userQuery.data.last_name,
      city: $userQuery.data.city,
      country: $userQuery.data.country
        ? {
            id: $userQuery.data.country,
            label: $fieldInfoQuery.data!.countries[$userQuery.data.country]
          }
        : null,
      pronouns: $userQuery.data.pronouns ?? '',
      job_title: $userQuery.data.job_title,
      company_name: $userQuery.data.company_name || '',
      company_size: $userQuery.data.company_size || '',
      years_of_experience: $userQuery.data.years_of_experience,
      user_type: userTypeFromSearch ?? $userQuery.data.user_type
    };
  }

  let hasUpdatedContributorFields = false;
  $: if ($contributorQuery.data && !hasUpdatedContributorFields) {
    hasUpdatedContributorFields = true;
    contributorOnlyFields = {
      visible:
        $contributorQuery.data.visible ||
        window.location.search.includes('visible=true') ||
        window.location.search.includes('invitation=true'),
      expertise: $contributorQuery.data.expertise,
      contribution_types: $contributorQuery.data.contribution_types,
      talking_points: $contributorQuery.data.talking_points,
      identifies_as: $contributorQuery.data.identifies_as,
      contributor_experience: $contributorQuery.data.contributor_experience,
      socials: [
        ...contributorOnlyFields.socials.map(
          (f) =>
            ($contributorQuery.data as Profile).socials!.find(({ name }) => f.name === name) || f
        ),
        ...$contributorQuery.data.socials.filter(
          ({ name }) => !contributorOnlyFields.socials.find((f) => f.name === name)
        )
      ]
    };
  }
  let isLoadedButNoContributorData = false;
  $: if (
    !$contributorQuery.isLoading &&
    !$userQuery.isLoading &&
    $contributorQuery.data == null &&
    $userQuery.data &&
    !isLoadedButNoContributorData
  ) {
    isLoadedButNoContributorData = true;
    contributorOnlyFields = {
      ...contributorOnlyFields,
      visible: window.location.search.includes('visible=true')
    };
  }

  const putProfileMutation = putMyContributorProfileQuery();
  const submit = async (event: Event) => {
    event.preventDefault();
    fieldErrorStates = {};
    const visible = userDataFields.user_type !== UserType.media_person;
    let data: UserDataOnlyProfile | Profile = {
      ...userDataFields,
      pronouns: userDataFields.pronouns || undefined,
      country: userDataFields.country?.id || '',
      visible
    };
    if (visible) {
      data = {
        ...data,
        contribution_types: contributorOnlyFields.contribution_types,
        identifies_as: contributorOnlyFields.identifies_as,
        talking_points: contributorOnlyFields.talking_points,
        contributor_experience: contributorOnlyFields.contributor_experience,
        expertise: [...contributorOnlyFields.expertise],
        socials: [
          ...contributorOnlyFields.socials
            .filter((s) => s.url !== '' && s.name !== '')
            .map((s) => ({
              ...s,
              follower_count: s.follower_count === '' ? null : s.follower_count
            }))
        ]
      };
    }
    $putProfileMutation.mutate(data, {
      onSuccess: ({ user_ref }) => {
        const urlParams = new URLSearchParams(window.location.search);
        const nextPage = urlParams.get('next');
        if ($userQuery.data) {
          showSuccessToast('Profile updated!');
        } else {
          showSuccessToast('Profile created!');
        }

        if (nextPage) {
          goto(nextPage);
        } else {
          goto(`/profiles/${user_ref}?first-visit=${isLoadedButNoContributorData}`, {
            noScroll: false
          });
        }
      },
      onError: (e) => {
        if (e instanceof ApiError) {
          e.errors?.forEach((err) => {
            fieldErrorStates[err.loc.slice(-1)[0] as string] = true;
          });
          fieldErrorStates = fieldErrorStates;
          snackbarContent = `Incorrect or missing fields!`;
          snackbar.open();
          const firstError = e.errors?.[0];
          if (firstError) {
            document
              .getElementById(firstError.loc.slice(-1)[0])
              ?.scrollIntoView({ behavior: 'smooth' });
          }
        } else {
          snackbarContent = `Something went wrong`;
          snackbar.open();
        }
      }
    });
  };
  $: loading = $userQuery.isLoading || $contributorQuery.isLoading || $fieldInfoQuery.isLoading;

  onMount(() => {
    invitationOnboardingModal = window.location.search.includes('invitation=true');
  });
</script>

<svelte:head>
  <title>Antiquoted | Contributor Profile</title>
</svelte:head>

<div class="container">
  <div class="row">
    <div class="offset-md-1 col-md-10">
      <h4 class="mt-5">Your Profile</h4>
    </div>
  </div>
</div>

<form on:submit={submit}>
  <MainBorder>
    <FieldBlock {loading}>
      <h4 slot="title">About You</h4>
      <Select bind:value={userDataFields.user_type} label="I want to be a" required id="user_type">
        <Option value="contributor">Contributor</Option>
        <Option value="media_person">Media Person</Option>
        <Option value="both">Contributor & Media Person</Option>
      </Select>
      <UserField
        id="first_name"
        label="First Name"
        bind:value={userDataFields.first_name}
        helperText="You can't not have a name you know..."
        type="text"
        required
      />
      <UserField
        id="last_name"
        label="Last Name"
        bind:value={userDataFields.last_name}
        helperText="You can't not have a name you know..."
        type="text"
        required
      />
      {#if !$userQuery.isLoading}
        <Pronouns bind:value={userDataFields.pronouns} />
      {/if}
      <Autocomplete
        id="country"
        options={countries}
        bind:value={userDataFields.country}
        label="Country"
        getOptionLabel={(option) => option?.label ?? ''}
        on:focusin={() => (userDataFields.country = null)}
        style="width: 100%;margin-bottom: 1.5em"
        textfield$style="width: 100%;"
        textfield$required
        textfield$input$autocomplete="off"
      />
      <UserField id="city" label="City" bind:value={userDataFields.city} type="text" />
      <CroppableAvatarUpload />
    </FieldBlock>

    <!-- Your work section -->
    <FieldBlock {loading}>
      <h4 slot="title">Your Job</h4>
      <UserField
        id="job_title"
        label="Job Title"
        bind:value={userDataFields.job_title}
        helperText={defaultHelpText}
        type="text"
        required
      />
      <UserField
        id="company_name"
        label="Company Name"
        bind:value={userDataFields.company_name}
        helperText={defaultHelpText}
        type="text"
      />
      <Select bind:value={userDataFields.company_size} label="Company Size" id="company_size">
        {#each $fieldInfoQuery.data?.company_sizes || [] as option}
          <Option value={option.value}>{option.label}</Option>
        {/each}
      </Select>

      <Select
        bind:value={userDataFields.years_of_experience}
        label="Years of Experience"
        required
        invalid={fieldErrorStates.years_of_experience}
        id="years_of_experience"
      >
        {#each $fieldInfoQuery.data?.years_of_experience || [] as option}
          <Option value={option.value}>{option.label}</Option>
        {/each}
      </Select>
    </FieldBlock>

    {#if userDataFields.user_type !== UserType.media_person}
      <FieldBlock {loading}>
        <div slot="title">
          <h4>Contributor Information</h4>
        </div>
        <p
          class="field-heading"
          id="contribution_types"
          class:error={fieldErrorStates.contribution_types}
        >
          What would you like to contribute to? *
        </p>
        {#each $fieldInfoQuery.data?.contribution_types || [] as option}
          <FormField class="checkbox-wrapper">
            <Checkbox bind:group={contributorOnlyFields.contribution_types} value={option.value} />
            <span slot="label">{option.label}</span>
          </FormField>
        {/each}
        <p
          class="field-note error"
          style={fieldErrorStates.contribution_types ? '' : 'display: none;'}
        >
          You must pick at least one contribution type.
        </p>

        <p id="identifies_as" class="field-heading">Do you identify as any of the following?</p>
        {#each $fieldInfoQuery.data?.identifies_as_choices || [] as option}
          <FormField class="checkbox-wrapper">
            <Checkbox bind:group={contributorOnlyFields.identifies_as} value={option.value} />
            <span slot="label">{option.label}</span>
          </FormField>
        {/each}
        <p class="field-note">
          Note: It’s totally your choice to answer this question. If you select any options they
          will be visible to other users on Antiquoted.
        </p>

        <p class="field-heading">What are your main areas of expertise? (max 20)</p>
        <Expertise
          bind:expertiseArray={contributorOnlyFields.expertise}
          maxCount={20}
          mustHaveExpertise
          valueInvalid={fieldErrorStates.expertise}
          showMax={true}
        />

        <p class="field-heading">If you had to give a talk what would the title be?</p>
        <Textfield
          id="talking_points"
          style="width: 100%;"
          helperLine$style="width: 100%;"
          textarea
          input$maxlength={1000}
          bind:value={contributorOnlyFields.talking_points}
          label="What knowledge or opinions do you want to share with the world?"
        >
          <CharacterCounter slot="internalCounter">0 / 1000</CharacterCounter>
        </Textfield>

        <p id="contributor_experience" class="field-heading">
          What type of contributor experience do you have?
        </p>
        {#each $fieldInfoQuery.data?.contribution_experiences || [] as option}
          <FormField class="checkbox-wrapper">
            <Checkbox
              bind:group={contributorOnlyFields.contributor_experience}
              value={option.value}
            />
            <span slot="label">{option.label}</span>
          </FormField>
        {/each}
        <p class="field-note">Note: Don’t worry if you’re a newbie, we’re here to help</p>

        <p class="field-heading" id="socials">Where can people find you talking online?</p>
        {#each contributorOnlyFields.socials as social, i}
          <SocialDetails data={social} canEditTitle={i > 3} />
        {/each}
        <Button on:click={addSocial} disabled={contributorOnlyFields.socials.length >= 10}>
          <Label>Add another</Label>
        </Button>
      </FieldBlock>
    {/if}
  </MainBorder>
  <div class="text-center mb-5 mt-5">
    <LoadingButton loading={$putProfileMutation.isPending} type="submit" variant="outlined">
      <Label>{$userQuery.data ? 'Update' : 'Create'}</Label>
    </LoadingButton>
  </div>
</form>

<Snackbar bind:this={snackbar} class="error">
  <Label>{snackbarContent}</Label>
  <Actions>
    <IconButton class="material-icons" title="Dismiss">close</IconButton>
  </Actions>
</Snackbar>

<CroppableAvatarDialog />

<Dialog
  bind:open={invitationOnboardingModal}
  aria-labelledby="Onboarding modal"
  aria-describedby="A Modal used to explain the first visit"
  surface$style="width: 650px; max-width: calc(100vw - 32px);max-height: calc(100vh - 100px);"
  on:SMUIDialog:closed={(e) => {
    e.preventDefault();
    if (e.detail.action === 'requests') {
      goto('/requests');
    }
    invitationOnboardingModal = false;
  }}
>
  <Title>Account set up!</Title>
  <Content>
    <p>Awesome, you can now access your account to check the status of your contributions.</p>
    <p>Next steps:</p>
    <ul style="color: black">
      <li>
        <strong>Complete the rest of your profile</strong>, this way other media people will be able
        to search for you and reach out about other contribution opportunities
      </li>
      <li>
        Check your email and <strong>confirm your email address!</strong> We only show people with confirmed
        email addresses in our search.
      </li>
    </ul>
  </Content>
  <DialogActions>
    <LoadingButton loading={false} action="complete_profile">
      <Label>Complete my profile</Label>
    </LoadingButton>
    <LoadingButton loading={false} action="requests">
      <Label>See my requests</Label>
    </LoadingButton>
  </DialogActions>
</Dialog>

<style lang="scss">
  .field-heading {
    margin: 2em 0 1em;
  }

  .field-note {
    margin: 1em 0;
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.6);
  }

  .error {
    color: rgb(183, 28, 28);
  }

  // ToDo: Move this to a global style
  :global(.checkbox-wrapper) {
    padding-right: 1em;
  }

  :global(.mdc-select) {
    width: 100%;
    margin-bottom: 1em;
  }

  :global(.mdc-notched-outline__notch) {
    overflow-x: clip;
    padding-right: 1em;
  }
</style>
