<script lang="ts">
  import type { UserProfile } from '$lib/queries/profiles';
  import { capitalise, formOptionsToMap } from '$lib/utils';
  import { getFieldInfo } from '$lib/queries/common';

  export let profile: UserProfile | undefined;

  $: fieldInfo = getFieldInfo();
  $: yearsOfExperience = formOptionsToMap($fieldInfo.data?.years_of_experience || []);
  $: contributionExperiences = formOptionsToMap($fieldInfo.data?.contribution_experiences || []);
</script>

<h5>About</h5>

{#if profile?.user_type === 'contributor' || profile?.user_type === 'both'}
  <ul>
    {#if profile.years_of_experience}
      <li>{yearsOfExperience[profile.years_of_experience]} of experience</li>
    {/if}
    {#if profile.expertise.length}
      <li>Expertise: {profile.expertise.join(', ')}</li>
    {/if}
    <li>
      Open to contribute to: {profile.contribution_types?.map(capitalise).join(', ')}
    </li>
    {#if profile.contributor_experience?.length}
      <li>
        Has previously:
        <ul>
          {#each profile.contributor_experience?.map((c) => contributionExperiences[c]) || [] as experience}
            <li>{experience}</li>
          {/each}
        </ul>
      </li>
    {/if}
  </ul>
  {#if profile.talking_points}
    <p>
      Loves to talk about: {profile.talking_points}
    </p>
  {/if}
{:else}
  <ul>
    {#if profile?.years_of_experience}
      <li>{yearsOfExperience[profile.years_of_experience]} of experience</li>
    {/if}
  </ul>
{/if}
