<script lang="ts">
  import { PROJECT_CONTACT_STATUSES, type ProjectContactStatus } from '$lib/constants';
  import LoadingButton from '$lib/components/LoadingButton.svelte';
  import {
    deleteProjectContributorMutation,
    putProjectContributor
  } from '$lib/queries/contributors';
  import { SearchContexts } from '$lib/components/Search/constants';
  import { getContext } from 'svelte';
  import * as auth from '$lib/auth';
  import CustomTooltip from '$lib/components/CustomTooltip.svelte';

  export let modelType: 'contact' | 'contributor';
  export let itemRef: string;
  export let projectRef: string | undefined;
  export let contributionStatus: ProjectContactStatus | undefined;
  export let disabled: boolean = false;
  const nonProjectFn = getContext<(ref: string) => void>(SearchContexts.ShortlistNoProjectFunction);

  const baseRef = itemRef.split(':')[1];
  const contributorMutation = putProjectContributor(projectRef ?? '');
  const deleteContributorMutation = deleteProjectContributorMutation(projectRef ?? '');

  $: canUnShortlist = contributionStatus === 'shortlisted';

  const shortlist = () => {
    if (!auth.isLoggedIn()) {
      window.location.href = `/login?next=${window.location.pathname}`;
    } else if (!projectRef) {
      nonProjectFn(baseRef);
    } else if (modelType === 'contributor') {
      $contributorMutation.mutate({ ref: baseRef });
    }
  };

  const unShortlist = () => {
    if (modelType === 'contributor') {
      $deleteContributorMutation.mutate(baseRef);
    }
  };
  $: tooltip = disabled
    ? "This user cannot be shortlisted right now as they're not currently accepting new projects."
    : "Shortlist a user to come back to them later, this won't trigger any invites to your project.";
</script>

<CustomTooltip content={!contributionStatus || canUnShortlist ? tooltip : null} style="width: 100%">
  {#if !contributionStatus}
    <LoadingButton
      style="break-inside: avoid; width: 100%"
      class="d-block mb-2"
      variant="raised"
      loading={$contributorMutation.isPending}
      on:click={shortlist}
      {disabled}
      {...$$restProps}
    >
      +Shortlist
    </LoadingButton>
  {:else}
    <LoadingButton
      style="break-inside: avoid; width: 100%"
      on:click={unShortlist}
      loading={$contributorMutation.isPending}
      class="d-block mb-2"
      variant="raised"
      disabled={!canUnShortlist || disabled}
      {...$$restProps}
      >{canUnShortlist
        ? 'Un-Shortlist'
        : PROJECT_CONTACT_STATUSES[contributionStatus]}</LoadingButton
    >
  {/if}
</CustomTooltip>
