<script lang="ts">
  export let href: string | null = null;
  export let ugc = true;
  export let nofollow = true;

  const rel = ['noopener', 'noreferrer'];
  if (nofollow) {
    rel.push('nofollow');
  }
  if (ugc) {
    rel.push('ugc');
  }
</script>

{#if href}
  <a
    {...$$restProps}
    href={href.startsWith('http://') || href.startsWith('https://') || href.startsWith('mailto:')
      ? href
      : `https://${href}`}
    target="_blank"
    rel={rel.join(' ')}
  >
    <slot />
  </a>
{:else}
  <span><slot /></span>
{/if}
