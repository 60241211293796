<script lang="ts">
  import TopAppBar, { Row, Section } from '@smui/top-app-bar';
  import { getSessionToken, isLoggedIn, isUnverifiedUser } from '$lib/auth';
  import { getMyUser, getMyUserData } from '$lib/queries/users';
  import { tiggerVerificationMutation } from '$lib/queries/verification';
  import LoadingButton from '$lib/components/LoadingButton.svelte';
  import { oneMinuteInMs } from '$lib/constants';
  import { emailVerificationMessageSentStore } from '$lib/components/EmailVerificationTopBar/stores';
  import { page } from '$app/stores';
  import { tryRefresh } from '$lib/api';

  let messageSent = false;
  let isProfilePage = false;
  page.subscribe((value) => {
    isProfilePage = value && value.url?.pathname === '/settings/profile';
  });

  $: userData = getMyUserData(true, isLoggedIn());
  $: myUser = getMyUser(isLoggedIn());
  $: settingsWithoutUserData = isProfilePage && !$userData.data;
  emailVerificationMessageSentStore.subscribe((value) => {
    messageSent = value;
  });
  $: isUnverified = isUnverifiedUser(messageSent);
  $: if ($myUser.data?.email_verified === isUnverified) {
    tryRefresh(getSessionToken()).then(() => {
      window.location.reload();
    });
  }

  const triggerVerification = tiggerVerificationMutation();

  const send = () => {
    $triggerVerification.mutate(undefined, {
      onSuccess: () => {
        emailVerificationMessageSentStore.set(true);
        setTimeout(() => {
          emailVerificationMessageSentStore.set(false);
        }, oneMinuteInMs);
      }
    });
  };
</script>

{#if isUnverified && !settingsWithoutUserData}
  <TopAppBar variant="static" style="background-color:rgb(103, 116, 142);overflow:hidden;">
    <Row style="min-height:2em;height:auto;max-height:auto">
      <Section style="display: flex;justify-content: center">
        {#if !messageSent}
          You need to verify your email to use antiquoted.
          <LoadingButton
            style="margin: 0; height:2em"
            loading={$triggerVerification.isPending}
            on:click={send}
            >Resend email
          </LoadingButton>
        {:else}
          Email sent! Please check your email and click the link to verify your account.
        {/if}
      </Section>
    </Row>
  </TopAppBar>
{/if}
