<script lang="ts">
  import { onClickAndKeydown } from '../directives';
  import type { Direction } from '$lib/types';

  export let fieldName: string;
  export let direction: Direction = 'asc';
  export let sort: string;
  $: sortIcon = direction === 'asc' || sort !== fieldName ? '▲' : '▼';
  const toggleFieldSort = () => {
    if (fieldName === sort) {
      direction = direction === 'asc' ? 'desc' : 'asc';
    } else {
      sort = fieldName;
      direction = 'asc';
    }
  };
</script>

<th use:onClickAndKeydown={toggleFieldSort} {...$$restProps}>
  <slot />
  {#if sort === fieldName}
    <span class="selected">{sortIcon}</span>
  {:else}
    <span class="not-selected">{sortIcon}</span>
  {/if}
</th>

<style lang="scss">
  @use '@material/theme';

  th {
    cursor: pointer;
  }

  span {
    font-size: 0.8em;
    margin-left: 0.2em;

    &.not-selected {
      color: #aaa;
    }

    &.selected {
      color: theme.$primary;
    }
  }
</style>
