<script lang="ts">
  import { goto } from '$app/navigation';
  import IconButton from '@smui/icon-button';
  import CircularProgress from '@smui/circular-progress';
  import CustomTooltip from '$lib/components/CustomTooltip.svelte';

  export let disabled: boolean = false;
  export let action: (() => void) | undefined = undefined;
  export let url: string | undefined = undefined;
  export let tooltip: string;
  export let icon: string;
  export let loading: boolean = false;
</script>

<CustomTooltip content={tooltip} style="width:48px; height:48px">
  {#if loading}
    <CircularProgress style="height: 24px; width: 24px;margin: 10px 12px" indeterminate />
  {:else}
    <IconButton
      {disabled}
      class="aq-purple-icon-button material-icons"
      on:click={() => {
        url ? goto(url) : action?.();
      }}
      aria-label={tooltip}
    >
      {icon}
    </IconButton>
  {/if}
</CustomTooltip>
