<script lang="ts">
  import TooltipIconButton from '$lib/components/Projects/TooltipIconButton.svelte';
  import { changeContributorProjectStatus } from '$lib/queries/contributors';

  type Statuses = 'invited' | 'approved';

  export let disabled: boolean = false;
  export let contributorRef: string;
  export let projectRef: string;
  export let status: Statuses;

  const mutation = changeContributorProjectStatus(projectRef, contributorRef);

  const icons: Record<Statuses, string> = {
    approved: 'done',
    invited: 'send'
  };
  const tooltips: Record<Statuses, string> = {
    approved: 'Approve contributor',
    invited: 'Invite them to contribute'
  };
</script>

<TooltipIconButton
  {disabled}
  tooltip={tooltips[status]}
  action={() => {
    $mutation.mutate({ status });
  }}
  icon={icons[status]}
  loading={$mutation.isPending}
/>
