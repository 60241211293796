<script lang="ts">
  import MainBorder from '$lib/components/MainBorder.svelte';
  import FieldBlock from '$lib/components/FieldBlock.svelte';
  import { tiggerVerificationMutation } from '$lib/queries/verification';
  import { onMount } from 'svelte';
  import ErrorChunk from '$lib/components/ErrorChunk.svelte';
  import PageTitle from '$lib/components/PageTitle.svelte';

  const mutation = tiggerVerificationMutation();
  onMount(async () => {
    $mutation.mutate();
  });
</script>

<PageTitle name="Email verify" />

<MainBorder>
  <FieldBlock loading={$mutation.isPending}>
    {#if $mutation.isError}
      <ErrorChunk />
    {:else}
      <h3>Verification email sent!</h3>
      <p>
        Please go to your email client and click the verification link to continue (you can close
        this page later).
      </p>
    {/if}
  </FieldBlock>
</MainBorder>
