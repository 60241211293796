<script lang="ts">
  import Textfield from '@smui/textfield';
  import HelperText from '@smui/textfield/helper-text';
  import CharacterCounter from '@smui/textfield/character-counter';

  export let id: string;
  export let value: string | undefined | null;
  export let required = false;
  export let invalid = false;
  export let label: string | undefined = undefined;
  export let maxLength = 5000;
  export let helperText: string | undefined = undefined;
  export let showWordCount = false;
  export let showCharacterCount = false;
  export let disabled = false;

  let rows = 4;
  let wordCount = 0;
  $: rows = Math.min(Math.max((value?.match(/\n/g)?.length || 0) + 3, 4), 20);
  $: wordCount = value?.match(/\S+/g)?.length ?? 0;
</script>

<Textfield
  {id}
  {invalid}
  {label}
  {required}
  style="width: 100%"
  helperLine$style="width: 100%;"
  textarea
  input$maxlength={maxLength}
  bind:value
  updateInvalid={false}
  input$rows={rows}
  {disabled}
>
  {#if helperText}
    <HelperText
      class="mdc-text-field-character-counter"
      style="position:absolute; bottom:0; left: 0"
      persistent
      slot="helper"
    >
      {helperText}
    </HelperText>
  {/if}
  <div class="mdc-text-field-character-counter" style="max-height:2.5em" slot="internalCounter">
    {#if showWordCount}
      Word count: {wordCount}
    {:else if showCharacterCount}
      <CharacterCounter>0 / {maxLength}</CharacterCounter>
    {/if}
  </div>
</Textfield>
