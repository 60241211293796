<script lang="ts">
  import { DELETED_PLACEHOLDER } from '$lib/constants';
  import CustomTooltip from '$lib/components/CustomTooltip.svelte';

  export let data: string | number | null | undefined;
</script>

<CustomTooltip
  content={data ? null : 'This has been deleted by the owner.'}
  style="display: inline-block"
>
  <span>{data ?? DELETED_PLACEHOLDER}</span>
</CustomTooltip>
