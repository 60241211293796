<script lang="ts">
  import type { UserProfile } from '$lib/queries/profiles';
  import { fieldInfoToIdentifiesAsMap, getFieldInfo } from '$lib/queries/common';
  import { formOptionsToMap } from '$lib/utils';
  import { toExtras } from '$lib/components/Profiles/utils';

  export let profile: UserProfile | undefined;

  $: fieldInfo = getFieldInfo();
  $: identifiesAsMap = fieldInfoToIdentifiesAsMap($fieldInfo.data);
  $: companySizes = formOptionsToMap($fieldInfo.data?.company_sizes || []);
  $: pronouns = formOptionsToMap($fieldInfo.data?.pronoun_choices || []);
  $: extras = toExtras(profile, $fieldInfo.data, companySizes);

  $: prettyPronouns = profile?.pronouns
    ? '(' + (pronouns[profile.pronouns] ?? profile.pronouns) + ')'
    : '';
</script>

<p>
  <strong>
    {profile?.first_name}
    {profile?.last_name}
    {prettyPronouns} <br />
    {#if profile?.user_type === 'contributor' || profile?.user_type === 'both'}
      {profile?.identifies_as?.map((i) => $identifiesAsMap.data?.[i]).join(', ')}
    {/if}
  </strong>
</p>
<p class="mb-0">{extras}</p>
