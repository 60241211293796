<script lang="ts">
  import Button, { Label } from '@smui/button';
  import { getMyUserData } from '$lib/queries/users';

  export let variant = 'outlined';
  const myData = getMyUserData(true);
</script>

{#if $myData.data}
  <a href="/projects/add">
    <Button {variant} {...$$restProps}>
      <Label>Start a new Project</Label>
    </Button>
  </a>
{:else}
  <a href="/settings/profile?next=/projects/add">
    <Button {variant} {...$$restProps}>
      <Label>Start a new Project</Label>
    </Button>
  </a>
{/if}
