import * as auth from '$lib/auth';
import { showWarning } from '$lib/components/WarningModal/stores';
import { ApiError } from '$lib/api/errors';

export const ensureVerifiedEmail = () => {
  if (!auth.canSeeProjectManagement()) {
    showWarning(
      new ApiError(
        403,
        'Please check your email for a link to verify your email address. ' +
          'To resend one, please hit the resend button in the banner on the dashboard',
        [],
        'You must verify your email address to use this page',
        undefined,
        {
          close_url: '/',
          cta_url: '/settings/verify',
          cta_title: 'Resend verification email'
        }
      )
    );
    // goto('/');
  }
};
