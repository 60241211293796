<script lang="ts">
  import Skeleton from '$lib/components/Skeleton.svelte';
  import IconButton from '@smui/icon-button';

  export let editLink: string | undefined = undefined;
  export let editSymbol = 'edit';
  export let loading = false;
  export let hidden = false;
  export let topPadding = true;
  export let style = '';
</script>

<div class="field-block" class:top-padding={topPadding} {hidden} {style}>
  <slot name="title" />
  {#if loading}
    <Skeleton style="margin-top: 2em" />
    <Skeleton />
    <Skeleton />

    <Skeleton style="width: 60%" />
  {:else}
    {#if editLink}
      <a href={editLink} class="edit-button">
        <IconButton class="material-icons">{editSymbol}</IconButton>
      </a>
    {/if}
    <slot />
  {/if}
</div>

<style lang="scss">
  .field-block {
    background-color: white;
    border-radius: 1em;
    padding: 1.5em 1.2em 1.2em;
    position: relative;

    &.top-padding {
      margin-top: 2em;
    }
  }

  .edit-button {
    position: absolute;
    @media (min-width: 768px) {
      top: 1em;
      right: -1.75em;
    }
    @media (max-width: 767px) {
      top: -1.5em;
      right: 1em;
    }
    background-color: rgba(240, 240, 240);
    border-radius: 9999em;
  }
</style>
