<script lang="ts">
  import { onClickAndKeydown } from '$lib/directives';

  import { goto } from '$app/navigation';
  import MainBorder from '$lib/components/MainBorder.svelte';
  import FieldBlock from '$lib/components/FieldBlock.svelte';
  import PageTitle from '$lib/components/PageTitle.svelte';
  import AQTable from '$lib/components/AQTable.svelte';
  import SortableTh from '$lib/components/SortableTh.svelte';
  import { getMyContributorProfile } from '$lib/queries/contributors';
  import { getContributions } from '$lib/queries/contributions';
  import { capitalise } from '$lib/utils';
  import { PROJECT_CONTACT_STATUSES } from '$lib/constants';
  import Button, { Label } from '@smui/button';
  import DeletedIfNull from '$lib/components/DeletedIfNull.svelte';

  let rowsPerPage = 10;
  let currentPage = 0;
  let sort: string;
  let order: 'asc' | 'desc';

  const contributorProfileQuery = getMyContributorProfile();
  $: query = getContributions({
    sort,
    sort_direction: order,
    limit: rowsPerPage,
    page: currentPage + 1
  });
  $: requests = $query.data?.items ?? [];
  $: loading = $query.isLoading || $contributorProfileQuery.isLoading;
</script>

<PageTitle name="Request History" />

<MainBorder wide>
  <div slot="top">
    <h3 class="mt-3">Request History</h3>
    <p>This is your list of the requests that you've received.</p>
    <a href="/settings/profile">
      <Button variant="outlined" class="mb-4">
        <Label>Update your profile</Label>
      </Button>
    </a>
  </div>

  <FieldBlock {loading}>
    <AQTable
      hardError={$query.isError}
      bind:currentPage
      totalCount={$query.data?.total_count ?? 0}
      {rowsPerPage}
      paginationName="Projects"
      showPagination={Number($query.data?.total_count) > 0}
    >
      <svelte:fragment slot="header">
        <SortableTh bind:direction={order} fieldName="project__name" bind:sort
          >Requester
        </SortableTh>
        <SortableTh bind:direction={order} fieldName="project__name" bind:sort
          >Media Outlet
        </SortableTh>
        <SortableTh bind:direction={order} fieldName="project__name" bind:sort
          >Project Name
        </SortableTh>
        <SortableTh bind:direction={order} fieldName="status" bind:sort>Status</SortableTh>
        <SortableTh bind:direction={order} fieldName="project__deadline" bind:sort
          >Deadline
        </SortableTh>
        <SortableTh bind:direction={order} fieldName="project__type" bind:sort>Type</SortableTh>
      </svelte:fragment>
      <svelte:fragment slot="body">
        {#if $contributorProfileQuery.data == null}
          <tr>
            <td colspan={6} class="text-center">
              <p class="mt-3 mb-3">
                You haven't set up your contributor profile yet, you can do that by updating <a
                  href="/settings/profile">your profile</a
                >.
              </p>
            </td>
          </tr>
        {:else if requests.length === 0}
          <tr>
            <td colspan={4}>No requests found.</td>
          </tr>
        {/if}
        {#each requests as item (item.ref)}
          <tr
            style="cursor:pointer;"
            use:onClickAndKeydown={() => {
              goto(`/requests/${item.ref}`);
            }}
          >
            <td>
              <DeletedIfNull data={item.project?.user__name} />
            </td>
            <td>
              <DeletedIfNull data={item.project?.outlet} />
            </td>
            <td>
              <DeletedIfNull data={item.project?.name} />
            </td>
            <td>{PROJECT_CONTACT_STATUSES[item.status]}</td>
            <td>{item.project?.deadline ?? 'None'}</td>
            <td>{item.project?.type ? capitalise(item.project.type) : 'None'}</td>
          </tr>
        {/each}
      </svelte:fragment>
    </AQTable>
  </FieldBlock>
</MainBorder>
