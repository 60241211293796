<script lang="ts">
  import MainBorder from '$lib/components/MainBorder.svelte';
  import FieldBlock from '$lib/components/FieldBlock.svelte';
  import * as api from '$lib/api';
  import Button, { Label } from '@smui/button';
  import Dialog, { Actions, Content, Title } from '@smui/dialog';
  import { getProjectQuery, getProjectShortlistQuery } from '$lib/queries/projects';
  import { goto } from '$app/navigation';
  import { DELETED_PLACEHOLDER, PROJECT_CONTACT_STATUSES, PROJECT_STATUSES } from '$lib/constants';
  import { onClickAndKeydown } from '$lib/directives';
  import type { ShortlistEntry } from '$lib/types';
  import ErrorChunk from '$lib/components/ErrorChunk.svelte';
  import { capitalise, toReadableDate } from '$lib/utils';
  import PageTitle from '$lib/components/PageTitle.svelte';
  import AQTable from '$lib/components/AQTable.svelte';
  import SortableTh from '$lib/components/SortableTh.svelte';
  import { ApiError } from '$lib/api/errors';
  import {
    changeContributorProjectStatus,
    deleteProjectContributorMutation,
    sendProjectContributorMessage
  } from '$lib/queries/contributors';
  import DeclineModal from '$lib/components/DeclineModal.svelte';
  import MessageThread from '$lib/components/MessageThread.svelte';
  import LoadingButton from '$lib/components/LoadingButton.svelte';
  import CopyToClipboardIcon from '$lib/components/CopyToClipboardIcon.svelte';
  import TooltipIconButton from '$lib/components/Projects/TooltipIconButton.svelte';
  import ChangeStatusTooltipIconButton from '$lib/components/Projects/ChangeStatusTooltipIconButton.svelte';
  import { getProjectFieldInfo } from '$lib/project-utils';
  import FormField from '@smui/form-field';
  import Checkbox from '@smui/checkbox';
  import StretchyTextArea from '$lib/components/StretchyTextArea.svelte';
  import { sendBulkProjectMessageMutation } from '$lib/queries/messaging';
  import { showSuccessToast } from '$lib/components/Toaster/stores';

  export let data;
  let openDeleteDialog = false;
  let selectedContributorRef = '';
  let declineModalOpen = false;
  let chatModalOpen = false;
  let retractModalOpen = false;
  let broadcastModalOpen = false;
  let selectedBroadcastStatuses: string[] = [];
  let broadcastMessage = '';
  let selectedShortlistEntry: ShortlistEntry;

  const broadcastableProjectContactStatuses = (({ accepted, submitted, approved }) => ({
    accepted,
    submitted,
    approved
  }))(PROJECT_CONTACT_STATUSES);

  $: sendContributorMessageMutation = sendProjectContributorMessage(
    selectedShortlistEntry?.ref ?? ''
  );

  // Shortlist query
  let rowsPerPage = 10;
  let currentPage = 0;
  let sort = 'created_at';
  let order: 'asc' | 'desc' = 'desc';
  let apiPage = 1;
  $: apiPage = currentPage + 1;
  $: shortlistQuery = getProjectShortlistQuery(data.params.ref, {
    limit: rowsPerPage,
    page: apiPage,
    sort,
    sort_direction: order
  });

  $: removeProjectContributorMutation = deleteProjectContributorMutation(data.params.ref);
  $: sendBulkMessage = sendBulkProjectMessageMutation(data.params.ref);
  $: query = getProjectQuery(data.params.ref);
  $: changeContributorProjectStatusMutation = changeContributorProjectStatus(
    data.params.ref,
    selectedContributorRef
  );

  const deleteProject = async () => {
    // ToDo: Switch this to mutate and a goto.
    await api.del<void>(`/engagements/projects/${data.params.ref}`);
    window.location.href = '/projects';
  };

  const goToShortlistEntry = async ({ ref, type }: ShortlistEntry) => {
    if (!ref) {
      return;
    }
    if (type === 'contact') {
      return goto(`/contacts/${ref}/edit`);
    } else if (type === 'contributor') {
      return goto(`/projects/${data.params.ref}/contributors/${ref}`);
    }
    throw new Error('Something went wrong!');
  };

  const openChatModal = (shortlistEntry: ShortlistEntry) => {
    selectedShortlistEntry = shortlistEntry;
    chatModalOpen = true;
  };

  let changeStatusLoading = false;
  $: loading = $query.isLoading || $shortlistQuery?.isLoading || changeStatusLoading;

  const canInviteStatuses = new Set(['shortlisted']);
  const canViewSubmissionStatuses = new Set([
    'submitted',
    'approved',
    'contributor_declined',
    'media_declined'
  ]);
  const canDeclineSubmissionStatuses = new Set(['accepted', 'submitted', 'approved']);
  const canRetract = (shortlistEntry: ShortlistEntry): boolean => {
    const threeDaysAgo = new Date();
    threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
    return !!shortlistEntry.user__ref && new Date(shortlistEntry.updated_at) < threeDaysAgo;
  };
  const canDecline = (shortlistEntry: ShortlistEntry): boolean => {
    return canDeclineSubmissionStatuses.has(shortlistEntry.status);
  };
  const canRemoveStatuses = new Set(['shortlisted']);
  const canApproveShortlistEntry = (item: ShortlistEntry) =>
    item.status === 'accepted' &&
    ($query.data?.type === 'podcast' || $query.data?.type === 'event');
  const canChatToSubmissionStatuses = new Set(['accepted']);

  $: projectFieldInfo = getProjectFieldInfo($query.data?.type);
</script>

<PageTitle name="Project details" />

<MainBorder wide backButton="/projects" backButtonTitle="Back to projects">
  <Button
    slot="top-right"
    type="button"
    variant="outlined"
    on:click={() => {
      openDeleteDialog = true;
    }}
    class="d-none d-md-block"
    style="min-height: 3em"
  >
    <Label>Delete Project</Label>
  </Button>
  <FieldBlock
    {loading}
    editLink={$query.isSuccess ? `/projects/${$query.data?.ref}/edit` : undefined}
  >
    {#if $query.error instanceof ApiError && $query.error.status === 404}
      <h3>Not found</h3>
      <p>
        We couldn't find the project you were looking for 😬. You can check your projects <a
          href="/projects">here</a
        >.
      </p>
    {:else if $query.isError}
      <ErrorChunk />
    {:else if $query.isSuccess}
      <h3 class="mb-3">{$query.data.name}</h3>
      <div class="row">
        <div class="col-md-9">
          <p>
            <strong>Status:</strong>
            {PROJECT_STATUSES[$query.data.status] ?? $query.data.status}
          </p>
          {#if $query.data.deadline}
            <p>
              <strong>{projectFieldInfo.deadlineLabel}:</strong>
              {toReadableDate($query.data.deadline)}
            </p>
          {/if}
          <p><strong>{projectFieldInfo.outletLabel}:</strong> {$query.data.outlet}</p>
          {#if $query.data.outlet_link}<p>
              <strong>{projectFieldInfo.outletLabel}:</strong>
              {$query.data.outlet_link}
            </p>
          {/if}
          {#if $query.data.outlet_viewership}<p>
              <strong
                >{projectFieldInfo.outletViewershipLabel}
                :</strong
              >
              {$query.data.outlet_viewership}
            </p>
          {/if}
          {#if $query.data.meeting_link}<p>
              <strong>{projectFieldInfo.meetingLinkLabel}:</strong>
              {$query.data.meeting_link}
            </p>
          {/if}
          {#if $query.data.type}
            <p>
              <strong>Type:</strong>
              {capitalise($query.data.type)}
            </p>
          {/if}
          <p>
            <strong>Visibility:</strong>
            {capitalise($query.data.visibility)}
          </p>

          <p style="white-space: pre-line">{$query.data.description ?? ''}</p>
        </div>
        <div class="col-md-3">
          <p>
            {#if $query.data.response_count > 0}
              <a href="/projects/{$query.data?.ref}/responses">
                <Button variant="raised" style="width: 100%">Responses</Button>
              </a>
            {:else}
              <Button disabled variant="raised" style="width: 100%">No responses yet</Button>
            {/if}
            <LoadingButton
              loading={$sendBulkMessage.isPending}
              on:click={() => {
                broadcastModalOpen = true;
              }}
              class="mt-2"
              variant="outlined"
              style="height: auto; min-height: 2.5em;width: 100%"
              >Message contributors
            </LoadingButton>
          </p>
        </div>
      </div>
    {/if}
  </FieldBlock>

  <FieldBlock
    {loading}
    editLink={$shortlistQuery.isSuccess ? `/projects/${$query.data?.ref}/shortlist` : undefined}
    editSymbol="add"
  >
    <div class="row" slot="title">
      <div class="col-md-8">
        <h3>Shortlist</h3>
        <p>
          Use the + symbol to search for contributors and select those you’d like to shortlist for
          this pitch.
        </p>
      </div>
      <div class="col-md-4 d-flex align-items-center justify-content-md-end mb-3 mt-3">
        <CopyToClipboardIcon
          href="/invitations/{$query.data?.ref}/r/{$query.data?.token}"
          tooltip="Send this link to people who aren't on Antiquoted so they can respond to your project."
        >
          Invite external contributors
        </CopyToClipboardIcon>
      </div>
    </div>

    <AQTable
      bind:currentPage
      totalCount={$shortlistQuery?.data?.total_count ?? 0}
      {rowsPerPage}
      paginationName="Contributors"
    >
      <svelte:fragment slot="header">
        <SortableTh
          bind:direction={order}
          fieldName="contributor__user__user_data__first_name"
          bind:sort
          >Name
        </SortableTh>
        <SortableTh
          bind:direction={order}
          fieldName="contributor__user__user_data__job_title"
          bind:sort
          >Job Title
        </SortableTh>
        <SortableTh
          bind:direction={order}
          fieldName="contributor__user__user_data__company_name"
          bind:sort
          >Company
        </SortableTh>
        <SortableTh bind:direction={order} fieldName="status" bind:sort class="text-center"
          >Status
        </SortableTh>
        <th scope="col" class="text-center">Actions</th>
      </svelte:fragment>
      <svelte:fragment slot="body">
        {#each $shortlistQuery?.data?.items || [] as item (item.submission_token)}
          <tr style="vertical-align: middle">
            <td use:onClickAndKeydown={() => goToShortlistEntry(item)}
              >{item.first_name ?? DELETED_PLACEHOLDER} {item.last_name ?? ''}</td
            >
            <td use:onClickAndKeydown={() => goToShortlistEntry(item)}
              >{item.job_title ?? DELETED_PLACEHOLDER}</td
            >
            <td use:onClickAndKeydown={() => goToShortlistEntry(item)}>{item.company_name ?? ''}</td
            >
            <td use:onClickAndKeydown={() => goToShortlistEntry(item)}>
              <div class="action-items justify-content-center">
                {PROJECT_CONTACT_STATUSES[item.status]}
              </div>
            </td>
            <td>
              {#if item.user__ref}
                <div class="action-items justify-content-center">
                  {#if canViewSubmissionStatuses.has(item.status)}
                    <TooltipIconButton
                      url="/projects/{data.params.ref}/responses?contributorRef={item.ref}"
                      icon="visibility"
                      tooltip="View response"
                    />
                  {/if}
                  {#if canChatToSubmissionStatuses.has(item.status)}
                    <TooltipIconButton
                      action={() => {
                        openChatModal(item);
                      }}
                      icon="chat"
                      tooltip="Ask the contributor a question about the submission"
                    />
                  {/if}
                  {#if canDecline(item)}
                    <TooltipIconButton
                      action={() => {
                        selectedContributorRef = item.ref;
                        declineModalOpen = true;
                      }}
                      icon="close"
                      tooltip="Decline submission"
                    />
                  {/if}
                  {#if item.status === 'invited'}
                    {#if canRetract(item)}
                      <TooltipIconButton
                        action={() => {
                          selectedContributorRef = item.ref;
                          retractModalOpen = true;
                        }}
                        icon="close"
                        tooltip="Retract submission"
                      />
                    {:else}
                      <TooltipIconButton
                        disabled
                        action={() => {}}
                        icon="close"
                        tooltip="You can't retract this submission until 72 hours have passed without
                      response"
                      />
                    {/if}
                  {/if}
                  {#if canInviteStatuses.has(item.status)}
                    <ChangeStatusTooltipIconButton
                      contributorRef={item.ref}
                      projectRef={data.params.ref}
                      status="invited"
                    />
                  {/if}
                  {#if canRemoveStatuses.has(item.status)}
                    <TooltipIconButton
                      icon="delete"
                      action={() => {
                        $removeProjectContributorMutation.mutate(item.ref);
                      }}
                      tooltip="Remove shortlisted contributor"
                      loading={$removeProjectContributorMutation.isPending}
                    />
                  {/if}
                  {#if canApproveShortlistEntry(item)}
                    <ChangeStatusTooltipIconButton
                      contributorRef={item.ref}
                      projectRef={data.params.ref}
                      status="approved"
                    />
                  {/if}
                </div>
              {:else}
                <div />
              {/if}
            </td>
          </tr>
        {/each}
      </svelte:fragment>
    </AQTable>
  </FieldBlock>
</MainBorder>
<div class="container pt-3 mb-5 text-center">
  <Button
    type="button"
    variant="outlined"
    on:click={() => {
      openDeleteDialog = true;
    }}
    class="d-inline-block d-md-none"
    style="min-height: 3em; background-color: white"
  >
    <Label>Delete Project</Label>
  </Button>
</div>

<Dialog
  bind:open={openDeleteDialog}
  aria-labelledby="simple-title"
  aria-describedby="simple-content"
>
  <Title id="simple-title">DANGER!</Title>
  <Content id="simple-content">
    <p>Are you <i>absolutely</i> sure you want to delete this project?</p>
    <p>There are no take-backs you know 😬... One more click and *poof* it's gone forever.</p>
  </Content>
  <Actions>
    <Button on:click={() => (openDeleteDialog = false)}>
      <Label>Back</Label>
    </Button>
    <Button on:click={deleteProject}>
      <Label>Delete it now!</Label>
    </Button>
  </Actions>
</Dialog>

<DeclineModal
  bind:open={declineModalOpen}
  mutation={changeContributorProjectStatusMutation}
  status="media_declined"
  asMedia
/>

<Dialog
  bind:open={chatModalOpen}
  aria-labelledby="Ask a Question"
  aria-describedby="Ask a question about the request"
  surface$style="width: 650px; max-width: calc(100vw - 32px);"
>
  <Title>Ask a Question</Title>
  <Content>
    {#if chatModalOpen && selectedShortlistEntry}
      <MessageThread
        userRef={selectedShortlistEntry.user__ref}
        userName="{selectedShortlistEntry.first_name} {selectedShortlistEntry.last_name}"
        mutation={sendContributorMessageMutation}
      />
    {/if}
  </Content>
  <Actions>
    <LoadingButton
      loading={false}
      disabled={$sendContributorMessageMutation.isPending}
      on:click={() => {
        chatModalOpen = false;
      }}
    >
      <Label>Close</Label>
    </LoadingButton>
  </Actions>
</Dialog>

<Dialog
  bind:open={retractModalOpen}
  aria-labelledby="Retract this invitation?"
  aria-describedby="Are you sure you want to retract this invitation?"
  surface$style="width: 650px; max-width: calc(100vw - 32px);"
>
  <Title>Do you want to retract this invitation?</Title>
  <Content>
    <p>
      If you retract this invitation you won't be able to re-invite this contributor to this
      project.
    </p>
  </Content>
  <Actions>
    <LoadingButton
      loading={false}
      disabled={$sendContributorMessageMutation.isPending}
      on:click={() => {
        retractModalOpen = false;
      }}
    >
      <Label>Close</Label>
    </LoadingButton>
    <LoadingButton
      loading={$sendContributorMessageMutation.isPending}
      disabled={$sendContributorMessageMutation.isPending}
      on:click={() => {
        $changeContributorProjectStatusMutation.mutate({
          status: 'retracted',
          contributorRef: selectedContributorRef
        });
      }}
    >
      <Label>Retract</Label>
    </LoadingButton>
  </Actions>
</Dialog>

<Dialog
  bind:open={broadcastModalOpen}
  aria-labelledby="Broadcast a message"
  aria-describedby="Broadcast a message to the project contributors"
  surface$style="width: 650px; max-width: calc(100vw - 32px);"
>
  <Title>Message Contributors</Title>
  <Content>
    <p>
      Broadcast a message to all contributors on this project. They'll then be able to reply
      directly to you in DMs which you can see in the <a href="/inbox">messages page</a>.
    </p>
    <p class="mb-0 small">Choose at least 1 contribution status to broadcast to:</p>
    {#each Object.entries(broadcastableProjectContactStatuses) as [key, val]}
      <FormField class="mb-3 mt-0">
        <Checkbox bind:group={selectedBroadcastStatuses} value={key} />
        <span slot="label">{val}</span>
      </FormField>
    {/each}
    <FormField style="width: 100%">
      <StretchyTextArea
        id="broadcastMessage"
        bind:value={broadcastMessage}
        showCharacterCount
        maxLength={1000}
        label="Write a message that's relevant to all the people you're sending it to"
        helperText="Min length: 10 characters"
      />
    </FormField>
  </Content>
  <Actions>
    <LoadingButton loading={$sendBulkMessage.isPending} disabled={false} action="close">
      <Label>Close</Label>
    </LoadingButton>
    <LoadingButton
      loading={$sendBulkMessage.isPending}
      disabled={selectedBroadcastStatuses.length < 1 || broadcastMessage.length < 10}
      action="broadcast"
      on:click={() => {
        $sendBulkMessage.mutate(
          {
            content: broadcastMessage,
            statuses: selectedBroadcastStatuses
          },
          {
            onSuccess: ({ broadcast_count }) => {
              selectedBroadcastStatuses = [];
              broadcastMessage = '';
              showSuccessToast(`Message sent to ${broadcast_count} Contributors`);
            }
          }
        );
      }}
    >
      <Label>Broadcast</Label>
    </LoadingButton>
  </Actions>
</Dialog>

<style lang="scss">
  td {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      cursor: pointer;
      @media (max-width: 767px) {
        min-width: 7em;
      }
    }

    word-break: break-word;
  }

  .action-items {
    display: flex;
    flex-wrap: nowrap;
    word-break: normal;
  }
</style>
