export enum AnalyticsDecision {
  NONE = 'None',
  YES = 'YES',
  NO = 'NO'
}

export const getCookie = <T = undefined>(name: string, ifEmptyReturn: T): string | T => {
  return (
    document.cookie
      ?.split(';')
      .find((cookie: string) => cookie.trim().startsWith(`${name}=`))
      ?.trim()
      .substring(name.length + 1) ?? ifEmptyReturn
  );
};
