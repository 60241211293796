<script lang="ts">
  import UserField from '$lib/components/UserTextField.svelte';
  import Button from '@smui/button';
  import { Icon } from '@smui/icon-button';

  export let id: string | undefined = undefined;
  export let searchDirty = false;
  export let value: string = '';
  export let loading = false;
  export let submit: ((value: string) => void) | undefined = undefined;

  let tmpValue = value;

  const onSubmit = (e: Event) => {
    e.preventDefault();
    value = tmpValue;
    submit?.(tmpValue);
  };
</script>

<!--style="display: flex;justify-content: flex-start"-->

<div class="col-md-6 d-flex" style="justify-content: flex-start;align-items: center;">
  <div style="width: 100%;">
    <form on:submit={onSubmit}>
      <UserField {id} label="Search" bind:value={tmpValue} type="text" variant="outlined" />
    </form>
  </div>
  <div style="margin-left: .5em">
    <form on:submit={onSubmit}>
      <Button type="submit" variant="raised" disabled={loading}>
        {#if searchDirty}
          <Icon class="material-icons" style="margin-right: 0">refresh</Icon>
        {:else}
          <Icon class="material-icons" style="margin-right: 0">search</Icon>
        {/if}
      </Button>
    </form>
  </div>
</div>
