<script lang="ts">
  import { ApiError } from '$lib/api/errors';
  import * as auth from '$lib/auth';
  import MainBorder from '$lib/components/MainBorder.svelte';
  import PageTitle from '$lib/components/PageTitle.svelte';
  import FieldBlock from '$lib/components/FieldBlock.svelte';
  import ErrorChunk from '$lib/components/ErrorChunk.svelte';
  import {
    acceptInvitationMutation,
    getInvitationDetails,
    type Submission,
    submitInvitationMutation
  } from '$lib/queries/invitations';
  import { capitalise, toMachineDate, toReadableDate } from '$lib/utils';
  import ExternalLink from '$lib/components/ExternalLink.svelte';
  import Avatar from '$lib/components/Images/Avatar.svelte';
  import LoadingButton from '$lib/components/LoadingButton.svelte';
  import { getMyContributorProfile } from '$lib/queries/contributors';
  import { getMyUserData } from '$lib/queries/users';
  import Dialog, { Actions, Content, Title } from '@smui/dialog';
  import Button, { Label } from '@smui/button';
  import UserField from '$lib/components/UserTextField.svelte';
  import { getFieldInfo } from '$lib/queries/common';
  import StretchyTextArea from '$lib/components/StretchyTextArea.svelte';
  import Checkbox from '@smui/checkbox';
  import FormField from '@smui/form-field';
  import HelperText from '@smui/textfield/helper-text';
  import Textfield from '@smui/textfield';
  import Pronouns from '$lib/components/Pronouns.svelte';
  import { getProjectFieldInfo } from '$lib/project-utils';

  export let data;
  const projectRef = data.params.ref;
  const projectToken = data.params.token;

  const statusesAcceptingSubmissions = new Set(['open', 'collecting_responses']);

  let submissionFields: Submission = {
    email: '',
    first_name: '',
    last_name: '',
    pronouns: '',

    job_title: '',
    company_name: '',
    hide_company_name: false,

    submission: '',
    marketing_emails: true
  };

  let countries: Array<Record<string, string>> = [];
  let fieldErrorStates: Record<string, boolean> = {};

  const query = getInvitationDetails(projectRef, projectToken);
  const fieldInfoQuery = getFieldInfo();
  const acceptMutation = acceptInvitationMutation(projectRef, projectToken);
  const submitMutation = submitInvitationMutation(projectRef, projectToken);
  $: userDataQuery = getMyUserData(true, auth.isLoggedIn());
  $: contributorQuery = getMyContributorProfile(!!$userDataQuery.data);

  $: if ($fieldInfoQuery.data && countries.length === 0) {
    countries = Object.entries($fieldInfoQuery.data!.countries).map(([id, label]) => ({
      id,
      label
    }));
  }

  $: noLongerAcceptingSubmissions = !statusesAcceptingSubmissions.has($query.data?.status);
  $: notFound = $query.error instanceof ApiError && $query.error.status === 404;
  $: deadlineHasPassed = $query.data?.deadline && $query.data?.deadline < toMachineDate(new Date());
  $: projectFieldInfo = getProjectFieldInfo($query.data?.type);
  $: isOwnProject = $userDataQuery.data && $userDataQuery.data.user__ref === $query.data?.user__ref;
  $: canAccept = $contributorQuery.data && !isOwnProject;
  $: canSubmit = !auth.isLoggedIn();
  $: hideSubmission = Boolean(
    (!canAccept && !canSubmit) || deadlineHasPassed || noLongerAcceptingSubmissions
  );
  $: loading =
    $query.isLoading ||
    $userDataQuery.isLoading ||
    $contributorQuery.isLoading ||
    $fieldInfoQuery.isLoading;
  $: mutationLoading = loading;
  $: showMissingInfoModal = !loading && !$contributorQuery;

  const submit = (e: Event) => {
    e.preventDefault();
    // ToDo: Handle error and update fieldErrorStates
    $submitMutation.mutate({
      ...submissionFields,
      pronouns: submissionFields.pronouns ?? undefined
    });
  };
</script>

<PageTitle name="Project Invitation" />

<MainBorder>
  <FieldBlock {loading}>
    {#if notFound}
      <h3 class="mb-3">Not found</h3>
      <p>
        We couldn't find what you were looking to add a submission for 😬. Maybe recheck the url, or
        if not please reach out to the person who sent you this link to get you an updated one.
      </p>
    {:else if $query.error instanceof ApiError && $query.error?.status < 500}
      <h3 class="mb-3">{$query.error.title || 'Uh oh'}</h3>
      <p>
        {$query.error.message}
      </p>
      <p>
        {#if $query.error.id === 'already_invited'}
          <a href="/requests/{$query.error.data?.project_contributor_ref}">Go to request</a>
        {/if}
      </p>
    {:else if $query.isError}
      <ErrorChunk />
    {:else if deadlineHasPassed}
      <h3>Deadline passed</h3>
      <p>
        The deadline for this project has passed. You can no longer submit a contribution for this
        project.
      </p>
    {:else if noLongerAcceptingSubmissions}
      <h3>No longer accepting submissions</h3>
      <p>
        This project is no longer accepting submissions. How about instead you <a href="/register"
          >sign up</a
        > to Antiquoted and create a contributor profile? This way other media people can find you and
        reach out about other opportunities.
      </p>

      <p class="text-center">
        <Button href="/register" variant="raised">Create a profile</Button>
      </p>
    {:else}
      <div class="row">
        <div class="col">
          <h3>
            {$query.data?.name}
          </h3>
          {#if $query.data?.deadline}
            <p>
              <strong>{projectFieldInfo.deadlineLabel}:</strong>
              {toReadableDate($query.data?.deadline)}
            </p>
          {/if}
          <p>
            <strong>{projectFieldInfo.outletLabel}:</strong>
            {$query.data?.outlet}
          </p>
          {#if $query.data?.outlet_link}
            <p>
              <strong>{projectFieldInfo.outletLinkLabel}:</strong>
              <ExternalLink href={$query.data.outlet_link}>{$query.data.outlet_link}</ExternalLink>
            </p>
          {/if}
          {#if $query.data?.outlet_viewership}<p>
              <strong
                >{projectFieldInfo.outletViewershipLabel}
                :</strong
              >
              {$query.data?.outlet_viewership}
            </p>
          {/if}
          {#if $query.data?.meeting_link}
            <p>
              <ExternalLink href={$query.data?.meeting_link}
                >{projectFieldInfo.meetingLinkLabel}</ExternalLink
              >
            </p>
          {/if}
          {#if $query.data?.type}
            <p>
              <strong>Type:</strong>
              {capitalise($query.data.type)}
            </p>
          {/if}
          <p style="white-space: pre-line">{$query.data?.description ?? ''}</p>
        </div>
      </div>
      <div class="d-flex mt-3">
        <Avatar src={$query.data?.user__avatar_url} hasAvatar={!!$query.data?.user__avatar_url} />
        <div style="margin-left: 1.25em">
          <p>
            <strong>Name:</strong>
            {$query.data?.user__name}
          </p>
          {#if $query.data?.user__company_name}
            <p>
              <strong>Company:</strong>
              {$query.data.user__company_name}
            </p>
          {/if}
          <p>
            <strong>Job Title:</strong>
            {$query.data?.user__job_title}
          </p>
        </div>
      </div>
    {/if}
  </FieldBlock>
  <FieldBlock loading={mutationLoading} hidden={hideSubmission}>
    {#if canAccept}
      <p class="text-center mt-0 mb-0">
        <LoadingButton loading={false} on:click={() => $acceptMutation.mutate()}>
          ✅ Accept Invitation
        </LoadingButton>
      </p>
    {:else}
      <form on:submit={submit}>
        <h4>About You</h4>
        <Textfield
          style="width: 100%;"
          type="email"
          bind:value={submissionFields.email}
          bind:invalid={fieldErrorStates.email}
          label="Email"
          updateInvalid
          input$autocomplete="email"
          required
        >
          <HelperText validationMsg slot="helper">That's not a valid email address.</HelperText>
        </Textfield>
        <UserField
          id="first_name"
          label="First Name"
          bind:value={submissionFields.first_name}
          helperText="You can't not have a name you know..."
          type="text"
          required
        />
        <UserField
          id="last_name"
          label="Last Name"
          bind:value={submissionFields.last_name}
          helperText="You can't not have a name you know..."
          type="text"
          required
        />
        <Pronouns bind:value={submissionFields.pronouns} />
        <UserField
          id="job_title"
          label="Job Title"
          bind:value={submissionFields.job_title}
          helperText="We need to know this for your contributor profile"
          type="text"
          required
        />
        <UserField
          id="company_name"
          label="Company Name"
          bind:value={submissionFields.company_name}
          helperText="We need to know this for your contributor profile"
          type="text"
          required
        />
        <FormField class="text-center">
          <Checkbox bind:checked={submissionFields.hide_company_name} />
          <span slot="label">Do not reference my company name as part of this submission</span>
        </FormField>
        <hr />
        <StretchyTextArea
          id="submission"
          bind:value={submissionFields.submission}
          invalid={!!fieldErrorStates.submission}
          label={$query.data?.type === 'article' ? 'Your submission goes here' : 'Leave a message'}
          maxLength={100000}
          required
          showWordCount
        />
        <FormField class="text-center">
          <Checkbox bind:checked={submissionFields.marketing_emails} />
          <span slot="label">Let me know about marketing stuff.</span>
        </FormField>
        <div class="mt-3 text-center">
          <LoadingButton loading={$submitMutation.isPending} type="submit" variant="outlined">
            <Label>Submit</Label>
          </LoadingButton>
        </div>
      </form>
    {/if}
  </FieldBlock>
</MainBorder>

<Dialog
  bind:open={showMissingInfoModal}
  aria-labelledby="Warning modal"
  aria-describedby="A Modal used to warn things"
  surface$style="width: 650px; max-width: calc(100vw - 32px);max-width: calc(100vh - 100px);"
  scrimClickAction=""
  escapeKeyAction=""
>
  <Title>Your profile is missing some information</Title>
  <Content>
    You'll need to fill out your profile including your contributor information in order to be able
    to accept this invitation. Please head to the profile page using the button below in order to
    update your information.
  </Content>
  <Actions>
    <a href="/settings/profile?next=/invitations/{projectRef}/r/{projectToken}">
      <LoadingButton loading={false}>
        <Label>Update my settings</Label>
      </LoadingButton>
    </a>
  </Actions>
</Dialog>

<style>
  :global(.mdc-select) {
    width: 100%;
    margin-bottom: 1em;
  }
</style>
