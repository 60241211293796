<script lang="ts">
  import BottomAppBar, { Section } from '@smui-extra/bottom-app-bar';
  import { AnalyticsDecision, getCookie } from '$lib/cookies';
  import Button from '@smui/button';
  import { loadAnalytics } from '$lib/analytics';

  let hasChosen: AnalyticsDecision;

  const refreshCookieChoice = () => {
    hasChosen = getCookie('analyticsCookies', AnalyticsDecision.NONE) as AnalyticsDecision;
    if (hasChosen === AnalyticsDecision.YES) {
      loadAnalytics();
    }
  };
  refreshCookieChoice();

  const setAnalyticsPreference = (decision: AnalyticsDecision) => {
    const now = new Date();
    now.setFullYear(now.getFullYear() + 1);
    document.cookie = `analyticsCookies=${decision};expires=${now.toUTCString()}Secure;SameSite=Strict;path=/`;
    refreshCookieChoice();
  };
</script>

{#if hasChosen === AnalyticsDecision.NONE}
  <div class="bg-light" style="width:100%;line-height: 8em">&nbsp;</div>
  <BottomAppBar color="primary" style="position:fixed; bottom:0;">
    <Section style="flex-direction: column; height:auto;">
      <div class="text-center">
        We use cookies to support some product analytics tools in order to improve antiquoted. If
        that's ok please select "all cookies" below, otherwise we'll only use cookies purely for app
        functionality.
      </div>
      <div class="text-center">
        <Button
          class="mt-3"
          variant="raised"
          color="secondary"
          on:click={() => setAnalyticsPreference(AnalyticsDecision.YES)}
          >Allow All Cookies
        </Button>
        <Button
          class="mt-3"
          variant="outlined"
          color="secondary"
          on:click={() => setAnalyticsPreference(AnalyticsDecision.NO)}
        >
          Necessary Only
        </Button>
      </div>
    </Section>
  </BottomAppBar>
{/if}
