import { writable } from 'svelte/store';
import { ApiError } from '$lib/api/errors';

export type Toast = {
  kls: 'error' | 'success';
  message: string;
};

export const toastStore = writable<Toast>({ kls: 'error', message: '' });

export function showErrorToast(e?: ApiError | Error) {
  toastStore.update((): Toast => {
    if (e instanceof ApiError) {
      return { message: e.toToastMessage(), kls: 'error' };
    }
    return { message: e?.message || '', kls: 'error' };
  });
}

export function showSuccessToast(message: string) {
  toastStore.update((): Toast => {
    return { message, kls: 'success' };
  });
}

export function clearToaster() {
  showErrorToast();
}
