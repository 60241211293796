<script lang="ts">
  import MainBorder from '$lib/components/MainBorder.svelte';
  import FieldBlock from '$lib/components/FieldBlock.svelte';
  import Button from '@smui/button';
  import { verifyMutation } from '$lib/queries/verification';
  import { onMount } from 'svelte';
  import ErrorChunk from '$lib/components/ErrorChunk.svelte';
  import { ApiError } from '$lib/api/errors';
  import { SUPPORT_EMAIL } from '$lib/constants';
  import PageTitle from '$lib/components/PageTitle.svelte';

  export let data;

  const { userRef, token } = data.params;

  const mutation = verifyMutation(new URLSearchParams(window.location.search).get('next'));
  onMount(async () => {
    $mutation.mutate({ userRef, token });
  });
</script>

<PageTitle name="Email verification" />

<MainBorder>
  <FieldBlock loading={$mutation.isPending}>
    {#if $mutation.error instanceof ApiError && $mutation.error.status === 404}
      <h3 class="mb-3">Not found</h3>
      <p>
        We couldn't find anything to verify on this link. Maybe recheck the url, or try a reload? If
        that doesn't work you can re-request the url from your <a href="/">dashboard</a> using the banner
        link.
      </p>
      <p>
        If things still aren’t working, shoot an email over to <a href="mailto:{SUPPORT_EMAIL}"
          >{SUPPORT_EMAIL}</a
        > and we'll get right back to you.
      </p>
    {:else if $mutation.isError}
      <ErrorChunk />
    {:else}
      <p>
        Great, your email is now verified. Thanks for doing that, it helps us make sure the platform
        is nice and secure.
      </p>
      <p>Feel free to get back to whatever you were doing beforehand.</p>
      <p class="text-center mt-5">
        <a href="/">
          <Button variant="outlined">Dashboard</Button>
        </a>
      </p>
    {/if}
  </FieldBlock>
</MainBorder>
