<script>
  import backgroundImage from '$lib/img/bkgrnd.png';
</script>

<div
  class="container-fluid main-container"
  style="background-image: url({backgroundImage}), linear-gradient(310deg, rgb(121, 40, 202), rgb(255, 0, 128));"
>
  <slot />
</div>

<style lang="scss">
  .main-container {
    padding: 0 2em 2em;
    background-size: contain;
    background-repeat: repeat;
    flex-grow: 1;
  }
</style>
