import { createMutation } from '@tanstack/svelte-query';
import { ApiError } from '$lib/api/errors';
import * as api from '$lib/api';
import type { LoginPayload } from '$lib/auth';

export const registerMutation = () =>
  createMutation<
    void,
    Error | ApiError,
    {
      email: string;
      password: string;
      marketing_emails: boolean;
    }
  >({
    mutationFn: (data) => api.post<void>(`/auth/users`, data)
  });

export const loginMutation = () =>
  createMutation<
    LoginPayload,
    Error | ApiError,
    {
      email: string;
      password: string;
    }
  >({
    mutationFn: (data) => api.post<LoginPayload>(`/auth/session`, data)
  });

export const loginWithGoogleMutation = () =>
  createMutation<
    LoginPayload,
    Error | ApiError,
    {
      credential: string;
      marketing_emails: boolean | undefined;
    }
  >({
    mutationFn: (data) => api.post<LoginPayload>(`/auth/session/google`, data)
  });

export const resetPasswordMutation = (uid: string, token: string) =>
  createMutation<
    void,
    Error | ApiError,
    {
      new_password_1: string;
      new_password_2: string;
    }
  >({
    mutationFn: (data) => api.put(`/auth/password/${uid}/${token}`, data)
  });

export const generateResetTokenMutation = () =>
  createMutation<
    void,
    Error | ApiError,
    {
      email: string;
    }
  >({
    mutationFn: (data) => api.post(`/auth/password/reset-token`, data)
  });
