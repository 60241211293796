import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37')
];

export const server_loads = [];

export const dictionary = {
		"/(home)": [8,[2]],
		"/(main)/(authenticated)/contributors": [9,[3,4]],
		"/(main)/(potentiallyauthenticated)/contributors/[ref]": [26,[3]],
		"/(main)/(authenticated)/inbox": [10,[3,4]],
		"/(purple)/invitations": [32,[6]],
		"/(purple)/invitations/[ref]/r/[token]": [33,[6]],
		"/(purple)/invitations/[ref]/r/[token]/set-password/[b64Token]": [34,[6]],
		"/(purple)/(auth)/login": [28,[6,7]],
		"/(purple)/(auth)/login/forgotten-password": [29,[6,7]],
		"/(purple)/(auth)/login/reset-password/[uid]/[token]": [30,[6,7]],
		"/(purple)/notifications": [35,[6]],
		"/(main)/(potentiallyauthenticated)/profiles/[ref]": [27,[3]],
		"/(main)/(authenticated)/profile": [11,[3,4]],
		"/(main)/(authenticated)/profile/type": [12,[3,4]],
		"/(main)/(authenticated)/projects": [13,[3,4,5]],
		"/(main)/(authenticated)/projects/add": [19,[3,4,5]],
		"/(main)/(authenticated)/projects/public": [20,[3,4,5]],
		"/(main)/(authenticated)/projects/public/[ref]": [21,[3,4,5]],
		"/(main)/(authenticated)/projects/[ref]": [14,[3,4,5]],
		"/(main)/(authenticated)/projects/[ref]/contributors/[contributorRef]": [15,[3,4,5]],
		"/(main)/(authenticated)/projects/[ref]/edit": [16,[3,4,5]],
		"/(main)/(authenticated)/projects/[ref]/responses": [17,[3,4,5]],
		"/(main)/(authenticated)/projects/[ref]/shortlist": [18,[3,4,5]],
		"/(purple)/(auth)/register": [31,[6,7]],
		"/(main)/(authenticated)/requests": [22,[3,4]],
		"/(main)/(authenticated)/requests/[ref]": [23,[3,4]],
		"/(main)/(authenticated)/settings": [24,[3,4]],
		"/(main)/(authenticated)/settings/profile": [25,[3,4]],
		"/(purple)/settings/verify": [36,[6]],
		"/(purple)/settings/verify/[userRef]/[token]": [37,[6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';