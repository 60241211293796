<script lang="ts">
  import tippy from 'tippy.js';
  import 'tippy.js/dist/tippy.css';
  import { onMount } from 'svelte';

  export let content: string | null = null;
  export let tag: string = 'div';
  let element: Element;

  onMount(() => {
    if (!content) {
      return;
    }
    const instance = tippy(element, { content });
    return () => {
      instance.unmount();
    };
  });
</script>

<svelte:element this={tag} bind:this={element} {...$$restProps}>
  <slot />
</svelte:element>
