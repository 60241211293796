<script>
  import { toExtras } from '$lib/components/Profiles/utils';
  import Skeleton from '$lib/components/Skeleton.svelte';
  import Button, { Icon } from '@smui/button/src/index';
  import Card, { Content } from '@smui/card/src/index';
  import Avatar from '$lib/components/Images/Avatar.svelte';
  import { getLatestContributors } from '$lib/queries/contributors';
  import * as auth from '$lib/auth';
  import { getFieldInfo } from '$lib/queries/common';
  import { formOptionsToMap } from '$lib/utils';

  const fieldInfo = getFieldInfo();
  $: companySizes = formOptionsToMap($fieldInfo.data?.company_sizes || []);

  const latestContributors = getLatestContributors(auth.isLoggedIn());
</script>

<div class="row d-flex align-items-stretch">
  {#if $latestContributors.isLoading}
    {#each [0, 1, 2, 3, 4] as _}
      <div class="col-lg-2 mb-3">
        <Card style="height:100%">
          <Content style="height:100%">
            <Skeleton style="margin-top: 2em" />
            <Skeleton />
            <Skeleton />
          </Content>
        </Card>
      </div>
    {/each}
  {:else}
    {#each $latestContributors.data || [] as contributor}
      <div class="col-lg-2 mb-3">
        <Card style="height:100%">
          <Content style="height:100%">
            <a
              href="/profiles/{contributor.user__ref}"
              class="d-flex flex-column align-items-center justify-content-center gap-2"
              style="height:100%"
            >
              <Avatar src={contributor.avatar_url} hasAvatar={!!contributor.avatar_url} />
              <h5 class="mt-2">{contributor.first_name} {contributor.last_name}</h5>
              <p class="mb-0">
                {toExtras(contributor, $fieldInfo.data, companySizes)}
              </p>
              <div class="d-flex flex-column justify-content-end" style="flex-grow:1">
                <Button
                  href="/profiles/{contributor.user__ref}"
                  variant="outlined"
                  class="mt-2 d-flex"
                  style="padding: 1.5em 1em !important; background-color: white"
                  >See Profile
                </Button>
              </div>
            </a>
          </Content>
        </Card>
      </div>
    {/each}
  {/if}

  <div class="col-lg-2 mb-3">
    <Card>
      <Content>
        <a href="/contributors" class="d-flex flex-column align-items-center">
          Check out more of our contributors
          <Button
            variant="outlined"
            class="mt-2 d-flex align-items-center justify-content-between"
            style="padding: 1.5em 1em !important; background-color: white"
          >
            <span class="m-2">See more</span>&nbsp;
            <Icon class="material-icons aq-purple-icon-button" title="Next page">
              chevron_right
            </Icon>
          </Button>
        </a>
      </Content>
    </Card>
  </div>
</div>

<style>
  a {
    text-decoration: none;
    color: black;
  }
</style>
