<script lang="ts">
  import MainBorder from '$lib/components/MainBorder.svelte';
  import FieldBlock from '$lib/components/FieldBlock.svelte';
  import * as auth from '$lib/auth';
  import {
    getMyContributorProfile,
    putMyContributorVisibilityQuery
  } from '$lib/queries/contributors';
  import { fieldInfoToIdentifiesAsMap, getFieldInfo } from '$lib/queries/common';
  import {
    PROJECT_CONTACT_STATUSES,
    PROJECT_STATUSES,
    type ProjectContactStatus,
    socialOptions,
    UserType
  } from '$lib/constants';
  import { capitalise, debounce, getSvgTag } from '$lib/utils';
  import SocialSvg from '$lib/img/social-svg.svelte';
  import ExternalLink from '$lib/components/ExternalLink.svelte';
  import ErrorChunk from '$lib/components/ErrorChunk.svelte';
  import Button, { Label } from '@smui/button';
  import { goto } from '$app/navigation';
  import AQTable from '$lib/components/AQTable.svelte';
  import { onClickAndKeydown } from '$lib/directives';
  import Avatar from '$lib/components/Images/Avatar.svelte';
  import { ApiError } from '$lib/api/errors';
  import ShortlistButton from '$lib/components/Projects/ShortlistButton.svelte';
  import InviteButton from '$lib/components/Projects/InviteButton.svelte';
  import { setContext } from 'svelte';
  import { SearchContexts } from '$lib/components/Search/constants';
  import ProjectAddModal from '$lib/components/Projects/ProjectAddModal.svelte';
  import Dialog, { Actions, Content, Title } from '@smui/dialog';
  import LoadingButton from '$lib/components/LoadingButton.svelte';
  import CopyToClipboardIcon from '$lib/components/CopyToClipboardIcon.svelte';
  import FormField from '@smui/form-field';
  import Switch from '@smui/switch';
  import { canChangeVisibility } from '$lib/domain/contributor';
  import {
    getProfileHistory,
    getUserProfileAvatarQuery,
    getUserProfileQuery
  } from '$lib/queries/profiles';
  import About from './About.svelte';
  import Info from './Info.svelte';
  import NotesModal from './NotesModal.svelte';
  import { getMyUser } from '$lib/queries/users';
  import CustomTooltip from '$lib/components/CustomTooltip.svelte';

  export let userRef: string;
  export let backLink: string | undefined = undefined;
  export let projectRef: string | undefined = undefined;
  export let contributionStatus: ProjectContactStatus | undefined = undefined;

  let openNotesModal = false;
  let rowsPerPage = 10;
  let currentPage = 0;

  $: profile = getUserProfileQuery(userRef);
  $: avatar = getUserProfileAvatarQuery(userRef);
  $: fieldInfo = getFieldInfo();
  $: identifiesAsMap = fieldInfoToIdentifiesAsMap($fieldInfo.data);

  $: myUser = getMyUser(auth.isLoggedIn());
  $: myContributorProfile = getMyContributorProfile(auth.isLoggedIn());
  $: visibilityMutation = putMyContributorVisibilityQuery();

  $: isMe = $myUser.data?.ref === $profile.data?.ref;
  $: projectHistoryQuery = getProfileHistory(
    userRef,
    {
      limit: rowsPerPage,
      page: currentPage + 1
    },
    auth.isLoggedIn() && !!$myUser.data && !!$profile.data && !isMe
  );
  $: firstVisitModal = window.location.search.includes('first-visit=true') && isMe;

  const onSwitchVisibility = (e: { detail: { selected: boolean } }) => {
    if (
      $myContributorProfile.data &&
      $profile.data &&
      canChangeVisibility($myContributorProfile.data, $profile.data)
    ) {
      switchVisible(e.detail.selected);
    } else {
      goto('/settings/profile?visible=true');
    }
  };
  const switchVisible = debounce((selected: boolean) => {
    $visibilityMutation.mutate(selected);
  }, 600);

  const modalData: {
    open: boolean;
    contributorRef: string;
    action: 'shortlist' | 'invite';
  } = {
    open: false,
    contributorRef: '',
    action: 'shortlist'
  };
  const openProjectModalFn = (action: 'invite' | 'shortlist') => (ref: string) => {
    modalData.open = true;
    modalData.contributorRef = ref;
    modalData.action = action;
  };
  setContext(SearchContexts.InviteNoProjectFunction, openProjectModalFn('invite'));
  setContext(SearchContexts.ShortlistNoProjectFunction, openProjectModalFn('shortlist'));

  const switchableUserTypes = new Set<string | undefined>(['contributor', 'both']);
  const switchableTooltipContent =
    "If switched off, your profile will no longer show up in search, and people won't be able to invite you to new projects. Existing projects will carry on as normal though.";
  const unSwitchableTooltipContent =
    'You need to edit your profile to be a contributor (or both) before you can be open to accepting new projects. Hit the edit button below to do so.';
</script>

<svelte:head>
  <title
    >Antiquoted | {$profile.data
      ? `${$profile.data?.first_name} ${$profile.data?.last_name}`
      : 'Loading'}
  </title>
</svelte:head>

<MainBorder backButton={backLink} backButtonTitle="Back to Search Results" wide>
  <FieldBlock
    loading={$profile.isLoading ||
      $identifiesAsMap.isLoading ||
      $myUser.isLoading ||
      $myContributorProfile.isLoading}
  >
    {#if $profile.error instanceof ApiError && $profile.error.status === 404}
      <h4>Not Found</h4>
      <p>Sorry, we can't find this profile. Maybe you followed an old link?</p>
    {:else if $profile.error || $avatar.error || $fieldInfo.error}
      <ErrorChunk />
    {:else}
      <h4>{$profile.data?.first_name} {$profile.data?.last_name}</h4>
      <div class="row mt-4">
        <div class="col-md-9 d-md-flex align-items-md-start">
          <div class="mb-md-0 mb-3 d-md-inline-block" style="margin-right: 2em">
            <Avatar
              src={$avatar.data}
              large
              hasAvatar={Boolean($avatar.isLoading || $avatar.data)}
            />
          </div>
          <div class="d-inline-block">
            <Info profile={$profile.data} />
          </div>
        </div>
        <div class="col-md-3 right-chunk">
          {#if isMe}
            <CustomTooltip
              content={switchableUserTypes.has($myContributorProfile.data?.user_type)
                ? switchableTooltipContent
                : unSwitchableTooltipContent}
            >
              <FormField style="margin-bottom: .25em;">
                <Switch
                  checked={$myContributorProfile.data?.visible}
                  style="margin-left: 0;"
                  on:SMUISwitch:change={onSwitchVisibility}
                  disabled={!switchableUserTypes.has($myContributorProfile.data?.user_type)}
                />
                <span slot="label">Accepting new projects</span>
              </FormField>
            </CustomTooltip>
            <CopyToClipboardIcon
              href="/profiles/{userRef}"
              tooltip="Send this link to people who aren't on Antiquoted so they can see your profile to your project."
            >
              Copy public link
            </CopyToClipboardIcon>
            <Button class="mt-3" href="/settings/profile" variant="raised" style="width:100%"
              >Edit
            </Button>
          {:else}
            <CopyToClipboardIcon
              class="mb-2"
              href="/profiles/{userRef}"
              tooltip="Send this link to people who aren't on Antiquoted so they can see your profile to your project."
            >
              Copy public link
            </CopyToClipboardIcon>
            {#if $profile.data?.user_type === 'contributor' || $profile.data?.user_type === 'both'}
              <ShortlistButton
                modelType="contributor"
                itemRef="contributor:{$profile.data.contributor_ref}"
                {projectRef}
                {contributionStatus}
                disabled={!$profile.data.visible}
              />
              <InviteButton
                itemRef="contributor:{$profile.data.contributor_ref}"
                {projectRef}
                {contributionStatus}
                disabled={!$profile.data.visible}
              />
            {/if}
          {/if}
        </div>
      </div>
      <div class="row mt-4">
        <div class:col-sm-8={auth.isLoggedIn() && !isMe} class:col-sm={!auth.isLoggedIn() || isMe}>
          <About profile={$profile.data} />
          {#if $profile.data?.user_type !== 'media_person'}
            {#each $profile.data?.socials || [] as social}
              <p class="pb-0 mb-0">
                <ExternalLink
                  class="d-flex align-items-center"
                  href={social.url}
                  style="text-decoration: none"
                >
                  <SocialSvg fill="rgb(121, 40, 202)" svg={getSvgTag(social.name)} />
                  {capitalise(social.name ?? social.url)}
                  {#if social.follower_count}
                    | Followers: {socialOptions[social.follower_count]}
                  {/if}
                </ExternalLink>
              </p>
            {/each}
          {/if}
        </div>
        {#if auth.isLoggedIn() && !isMe && $profile.data?.user_type !== UserType.media_person}
          <div class="col-sm-4">
            <h5>Private Notes 🔒</h5>
            <p>
              <small><em>Only you can see these notes:</em></small>
            </p>
            {#if $profile.data?.note}
              <p style="white-space: pre">
                {$profile.data?.note}
              </p>
            {/if}
            {#if !isMe}
              <Button class="d-block" on:click={() => (openNotesModal = true)}
                >{$profile.data?.note ? 'Edit' : 'Add'}</Button
              >
            {/if}
          </div>
        {/if}
      </div>
      {#if auth.isLoggedIn() && !isMe}
        <div class="row mt-5">
          <div class="col">
            <h5>Your history with {$profile.data?.first_name}</h5>
            <AQTable
              bind:currentPage
              totalCount={$projectHistoryQuery.data?.total_count ?? 0}
              {rowsPerPage}
              paginationName="Projects"
            >
              <svelte:fragment slot="header">
                <th>Your role</th>
                <th>Project Title</th>
                <th>Contribution Status</th>
                <th>Project Type</th>
                <th>Project Status</th>
              </svelte:fragment>
              <svelte:fragment slot="body">
                {#if $projectHistoryQuery.data?.items.length === 0}
                  <tr>
                    <td colspan={4} class="text-center">
                      You haven't worked with this contributor yet. Invite them to contribute to a
                      project to get started.
                    </td>
                  </tr>
                {/if}
                {#each $projectHistoryQuery.data?.items || [] as item (item.project__ref)}
                  <tr
                    style="cursor:pointer;"
                    use:onClickAndKeydown={() => {
                      if (item.user_type === 'media_person') {
                        goto(`/projects/${item.project__ref}`);
                      } else {
                        goto(`/requests/${item.project_contributor__ref}`);
                      }
                    }}
                  >
                    <td>{capitalise(item.user_type)}</td>
                    <td>{item.project__name}</td>
                    <td>{PROJECT_CONTACT_STATUSES[item.status]}</td>
                    <td>{capitalise(item.project__type)}</td>
                    <td>{PROJECT_STATUSES[item.project__status]}</td>
                  </tr>
                {/each}
              </svelte:fragment>
            </AQTable>
          </div>
        </div>
      {/if}
    {/if}
  </FieldBlock>
</MainBorder>

{#if auth.isLoggedIn()}
  <NotesModal {userRef} note={$profile.data?.note} bind:open={openNotesModal} />
  <ProjectAddModal {modalData} />
  <Dialog
    bind:open={firstVisitModal}
    aria-labelledby="First visit modal"
    aria-describedby="A Modal used to explain the first visit"
    surface$style="width: 650px; max-width: calc(100vw - 32px);max-width: calc(100vh - 100px);"
  >
    <Title>Looking good!</Title>
    <Content>
      <p>Make sure you’ve filled in as much of your information as possible to stand out.</p>
      <p>
        Oh, and if you haven't already, <strong>don't forget to verify your email address!</strong>.
      </p>
      <p>
        You have to verify to do a bunch of things, including for your contributor profile to show
        up in search if you have one.
      </p>
    </Content>
    <Actions>
      <LoadingButton loading={false} action="close">
        <Label>Close</Label>
      </LoadingButton>
    </Actions>
  </Dialog>
{/if}

<style lang="scss">
  .right-chunk {
    @media (min-width: 768px) {
      display: flex;
      justify-content: start;
      flex-direction: column;
    }
  }

  :global(.edit-notes) {
    position: absolute;
    right: 0;
  }
</style>
