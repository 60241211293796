<script lang="ts">
  import TopAppBar, { Row, Section, Title } from '@smui/top-app-bar';
  import IconButton from '@smui/icon-button';
  import Menu from '@smui/menu';
  import NotificationBell from '$lib/components/NotificationBell.svelte';
  import MessageBell from '$lib/components/MessageBell.svelte';
  import EmailVerificationTopBar from '$lib/components/EmailVerificationTopBar/index.svelte';
  import { menuStore } from '$lib/menu';

  let menu: Menu;
  menuStore.subscribe((val: Menu) => {
    menu = val;
  });
</script>

<div>
  <TopAppBar variant="static" color="primary" style="background-color: white">
    <Row>
      <Section style="width:33%">
        <IconButton
          on:click={() => menu.setOpen(true)}
          class="menu-toggle material-icons"
          style="color: rgb(121, 40, 202);"
        >
          menu
        </IconButton>
      </Section>
      <Section style="justify-content: center; width:34%">
        <a href="/">
          <Title
            style="padding-left: 0; background-image: linear-gradient(310deg, rgb(121, 40, 202), rgb(255, 0, 128));-webkit-background-clip: text;
-webkit-text-fill-color: transparent; font-size: 1.5em"
          >
            Antiquoted
          </Title>
        </a>
      </Section>
      <Section style="width:33%; display:flex; justify-content: flex-end">
        <MessageBell />
        <NotificationBell />
      </Section>
    </Row>
  </TopAppBar>
  <EmailVerificationTopBar />
</div>

<slot />

<style lang="scss">
  a {
    color: white;
    text-decoration: none;
  }
</style>
