<div class="separator" {...$$restProps}>
  <slot />
</div>

<style>
  .separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 2em auto;
  }

  .separator::before,
  .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid rgb(128, 128, 128, 0.5);
  }

  .separator:not(:empty)::before {
    margin-right: 0.5em;
  }

  .separator:not(:empty)::after {
    margin-left: 0.5em;
  }
</style>
