<script lang="ts">
  import Textfield from '@smui/textfield';
  import HelperText from '@smui/textfield/helper-text';
  import { Label } from '@smui/button';
  import { generateResetTokenMutation } from '$lib/queries/auth';
  import LoadingButton from '$lib/components/LoadingButton.svelte';
  import { showErrorToast } from '$lib/components/Toaster/stores';

  let email: string | null = null;
  let emailInvalid = false;
  let resetSent = false;

  const mutation = generateResetTokenMutation();

  const submit = async () => {
    if (!email || emailInvalid) {
      showErrorToast(new Error('Please enter a valid email and password.'));
      return;
    }
    $mutation.mutate(
      { email },
      {
        onSuccess: () => {
          resetSent = true;
        }
      }
    );
  };
</script>

<svelte:head>
  <title>Antiquoted | Password Reset</title>
</svelte:head>

{#if resetSent}
  <h1>Password Reset</h1>
  <p>
    If an account exists for that email address, you should receive an email shortly with a link to
    reset your password.
  </p>
{:else}
  <form on:submit={submit}>
    <h1>Password Reset</h1>
    <p>Enter your email address below and we'll send you a password reset link.</p>
    <Textfield
      style="width: 100%;"
      type="email"
      bind:value={email}
      bind:invalid={emailInvalid}
      label="Email"
      updateInvalid
      input$autocomplete="email"
    >
      <HelperText validationMsg slot="helper">That's not a valid email address.</HelperText>
    </Textfield>
    <LoadingButton loading={$mutation.isPending} type="submit">
      <Label>Submit</Label>
    </LoadingButton>
  </form>
{/if}
