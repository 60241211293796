<script lang="ts">
  import * as auth from '$lib/auth';
  import { type ProjectContactStatus } from '$lib/constants';
  import LoadingButton from '$lib/components/LoadingButton.svelte';
  import { putProjectContributor } from '$lib/queries/contributors';
  import { getContext } from 'svelte';
  import { SearchContexts } from '$lib/components/Search/constants';
  import CustomTooltip from '$lib/components/CustomTooltip.svelte';

  export let itemRef: string;
  export let projectRef: string | undefined;
  export let contributionStatus: ProjectContactStatus | undefined;
  export let disabled: boolean = false;

  const nonProjectFn = getContext<(ref: string) => void>(SearchContexts.InviteNoProjectFunction);

  const baseRef = itemRef.split(':')[1];
  const contributorMutation = putProjectContributor(projectRef ?? '');

  const invite = () => {
    if (!auth.isLoggedIn()) {
      window.location.href = `/login?next=${window.location.pathname}`;
    } else if (projectRef) {
      $contributorMutation.mutate({ ref: baseRef, status: 'invited' });
    } else {
      nonProjectFn(baseRef);
    }
  };
  $: tooltipContent = disabled
    ? "This user cannot be invited right now as they're not currently accepting new projects."
    : "Invite a user to contribute to this project, we'll notify them so they can take a look.";
</script>

<CustomTooltip content={tooltipContent} style="width: 100%">
  {#if !contributionStatus || contributionStatus === 'shortlisted'}
    <LoadingButton
      style="break-inside: avoid; width: 100%"
      class="d-block mb-2"
      variant="raised"
      loading={$contributorMutation.isPending}
      on:click={invite}
      {disabled}
      {...$$restProps}
    >
      +Invite
    </LoadingButton>
  {/if}
</CustomTooltip>
