<script lang="ts">
  import SERP from '$lib/components/Search/SERP.svelte';
  import PageTitle from '$lib/components/PageTitle.svelte';
  import { capitalise } from '$lib/utils';
  import { getProjectQuery } from '$lib/queries/projects';
  import FieldBlock from '$lib/components/FieldBlock.svelte';
  import ErrorChunk from '$lib/components/ErrorChunk.svelte';

  export let data;
  const projectRef = data.params.ref;
  const projectQuery = getProjectQuery(projectRef);
</script>

<PageTitle name="Search" />

<SERP {projectRef}>
  <FieldBlock loading={$projectQuery.isLoading} slot="heading">
    {#if $projectQuery.isError}
      <ErrorChunk />
    {:else}
      <h3>
        <a href="/projects/{projectRef}" class="title-link"
          >Project: {$projectQuery.data?.name ?? ''}</a
        >
      </h3>
      <p>
        <strong>Outlet:</strong>
        {$projectQuery.data?.outlet ?? ''}<br />
        <strong>Type:</strong>
        {$projectQuery.data?.type ? capitalise($projectQuery.data?.type) : ''}
      </p>
      <h5>How Antiquoted projects work</h5>
      <ul>
        <li>
          <strong>Projects are only visible to the people you invite</strong> to contribute to them.
        </li>
        <li>Search and filter our list of contributors and hit “Profile” to find out more.</li>
        <li>
          Invite up to 10 contributors at a time to each project. This number is refreshed each time
          a contributor accepts or declines your request.
        </li>
        <li>
          If you aren't ready to invite contributors, you can shortlist people and invite them at a
          later date. Contributors are only notified when you invite them and not when they are
          shortlisted.
        </li>
      </ul>
    {/if}
  </FieldBlock>
</SERP>

<style>
  .title-link {
    text-decoration: none;
    color: inherit;
  }

  .title-link:hover {
    text-decoration: underline;
    color: rgb(121, 40, 202);
  }
</style>
