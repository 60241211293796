<script lang="ts">
  import Select, { Option } from '@smui/select';
  import UserField from '$lib/components/UserTextField.svelte';
  import { getFieldInfo } from '$lib/queries/common';

  export let value: string | undefined;
  let localValue: string | undefined = '';
  let localOtherValue: string | undefined = '';

  const fieldInfoQuery = getFieldInfo();
  let predefined_pronouns = new Set(['']);

  let notYetLoaded = true;
  $: if ($fieldInfoQuery.data && notYetLoaded) {
    notYetLoaded = false;
    [...$fieldInfoQuery.data.pronoun_choices.map((o) => o.value)].forEach((f) =>
      predefined_pronouns.add(f)
    );
    predefined_pronouns = predefined_pronouns;
    localValue = predefined_pronouns.has(value) ? value : 'other';
    localOtherValue = predefined_pronouns.has(value) ? '' : value;
  }

  const onChange = () => {
    value = predefined_pronouns.has(localValue) ? localValue : localOtherValue;
  };
</script>

<Select bind:value={localValue} on:SMUISelect:change={onChange} label="Pronouns" id="pronouns">
  <Option value={''} />
  {#each $fieldInfoQuery.data?.pronoun_choices || [] as option}
    <Option value={option.value}>{option.label}</Option>
  {/each}
  <Option value="other">Other</Option>
</Select>
{#if !predefined_pronouns.has(localValue)}
  <UserField
    id="pronouns_other"
    label="Pronouns"
    bind:value={localOtherValue}
    helperText="If you don't want to select a pronoun, please update the dropdown above"
    type="text"
    required
    {onChange}
  />
{/if}
