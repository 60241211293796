import { createQuery } from '@tanstack/svelte-query';
import { QUERY_KEYS } from '$lib/queries/keys';
import * as api from '$lib/api';
import { oneHourInMilliseconds } from '$lib/constants';

type FAQ = {
  id: number;
  title: string;
  content: string;
};

export const getFAQs = () =>
  createQuery({
    queryKey: QUERY_KEYS.CONTENT_FAQS,
    queryFn: async () => api.get<FAQ[]>('/content/faqs'),
    gcTime: oneHourInMilliseconds,
    staleTime: oneHourInMilliseconds
  });
