/* eslint-disable @typescript-eslint/no-explicit-any */
import { dev } from '$app/environment';
import { getRefreshTokenExpiry, getSessionToken, logout, setSessionToken } from '../auth';
import { ApiError } from './errors';
import { getCookie } from '../cookies';
import { AsyncLock } from '../locks';

const domain = dev ? 'http://localhost:8000' : '';
const baseUrl = `${domain}/api`;
const responseCodesWithNoContent = new Set([201, 204]);

const refreshLock = new AsyncLock();
export const tryRefresh = (failingSessionToken: string | null) =>
  refreshLock.runSerially(async () => {
    const refreshToken = getRefreshTokenExpiry();
    if (!refreshToken) {
      return false;
    }

    // Another request may have already refreshed the token, exit early if so.
    const sessionToken = getSessionToken();
    if (sessionToken && sessionToken !== failingSessionToken) {
      return true;
    }

    const response = await fetch(`${baseUrl}/auth/session/extension`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'x-csrftoken': getCookie('csrftoken', '') },
      credentials: 'include'
    });
    if (!response.ok || !response.headers.get('Content-Type')?.startsWith('application/json')) {
      return false;
    }

    const { token } = await response.json();
    setSessionToken(token);
    return true;
  });

const excludedRefreshPaths = new Set(['/auth/session']);
const call = async <T>(
  ...[path, args, includeContentType = true]: [string, RequestInit, boolean?]
): Promise<T> => {
  if (process.env.NODE_ENV === 'development') {
    console.log(path, args.method);
  }
  const sessionToken = getSessionToken();
  const headers: Record<string, string> = {};
  if (sessionToken) {
    headers['Authorization'] = `Bearer ${getSessionToken()}`;
  }
  if (includeContentType) {
    headers['Content-Type'] = 'application/json';
  }
  const response = await fetch(`${baseUrl}${path}`, {
    ...args,
    headers: { ...headers, ...args.headers }
  });
  if (response.status === 401 && !excludedRefreshPaths.has(path) && getRefreshTokenExpiry()) {
    const sucessfullyRefreshed = await tryRefresh(sessionToken);
    if (sucessfullyRefreshed) {
      return call<T>(path, args);
    } else {
      logout();
    }
  }
  if (!response.headers.get('Content-Type') && responseCodesWithNoContent.has(response.status)) {
    return undefined as any;
  }
  if (response.headers.get('Content-Type')?.startsWith('application/json')) {
    const body = response.status !== 204 && (await response.json());
    if (!response.ok) {
      throw new ApiError(
        response.status,
        body.message || body.detail,
        body.errors,
        body.title,
        body.id,
        body.data
      );
    }
    return body;
  }
  throw new Error('Something went wrong, please try again in a little while.');
};

export const get = async <T>(path: string): Promise<T> => {
  return call(path, {
    method: 'GET',
    credentials: 'include'
  });
};

export const post = async <T>(path: string, payload: Record<string, any>): Promise<T> => {
  return call(path, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(payload)
  });
};

export const put = async <T>(path: string, payload: Record<string, any>): Promise<T> => {
  return call<T>(path, {
    method: 'PUT',
    credentials: 'include',
    body: JSON.stringify(payload)
  });
};

export const patch = async <T>(path: string, payload: Record<string, any>): Promise<T> => {
  return call<T>(path, {
    method: 'PATCH',
    credentials: 'include',
    body: JSON.stringify(payload)
  });
};

export const del = async <T>(path: string, payload?: Record<string, any>): Promise<T> => {
  return call(path, {
    method: 'DELETE',
    credentials: 'include',
    body: payload && JSON.stringify(payload)
  });
};

export const upload = async <T>(path: string, file: File | Blob): Promise<T> => {
  const formData = new FormData();
  formData.append('file', file);
  return call(
    path,
    {
      method: 'POST',
      credentials: 'include',
      body: formData
    },
    false
  );
};
