<script lang="ts">
  import IconButton, { Icon } from '@smui/icon-button';
  import Checkbox from '@smui/checkbox';
  import { Content as AccordianContent, Header, Panel } from '@smui-extra/accordion';
  import FormField from '@smui/form-field';
  import type { FormOption } from '$lib/types.js';

  export let name: string;
  export let options: FormOption[];
  export let open: boolean;
  export let value: string[];
  export let change: () => void;
</script>

<Panel bind:open>
  <Header>
    <strong>{name}</strong>
    <IconButton slot="icon" toggle pressed={open} disabled style="margin-right:0">
      <Icon class="material-icons" on>expand_less</Icon>
      <Icon class="material-icons">expand_more</Icon>
    </IconButton>
  </Header>
  <AccordianContent>
    {#each options ?? [] as option}
      <FormField class="checkbox-wrapper">
        <Checkbox bind:group={value} value={option.value} on:change={change} {...$$restProps} />
        <span slot="label">{option.label}</span>
      </FormField>
    {/each}
  </AccordianContent>
</Panel>

<style>
  :global(
      .smui-accordion .smui-accordion__panel > .smui-accordion__header .smui-accordion__header__icon
    ) {
    margin-right: 0;
  }

  :global(
      .smui-accordion
        .smui-accordion__panel
        > .smui-accordion__header
        .smui-accordion__header__title
    ) {
    padding-right: 0;
  }
</style>
