<script>
  import Button, { Label } from '@smui/button';
  import { getPublicProjectsQuery } from '$lib/queries/projects';
  import { Content } from '@smui/card';
  import Skeleton from '$lib/components/Skeleton.svelte';
  import { goto } from '$app/navigation';
  import { PROJECT_STATUSES } from '$lib/constants';
  import AQTable from '$lib/components/AQTable.svelte';
  import { onClickAndKeydown } from '$lib/directives';
  import AddProjectButton from '$lib/components/AddProjectButton.svelte';
  import * as auth from '$lib/auth';
  import { toReadableDate } from '$lib/utils';

  $: projects = getPublicProjectsQuery(
    {
      limit: 5,
      page: 1,
      sort: 'created_at',
      sort_direction: 'desc'
    },
    auth.isLoggedIn()
  );

  $: loading = $projects.isLoading;
</script>

<Content>
  {#if loading}
    <Skeleton style="margin: 1em auto; width:60%;" />
    <Skeleton />
    <Skeleton />
    <Skeleton style="margin: 2em auto 0; width:60%" />
  {:else if $projects.data?.total_count === 0}
    <p>No public projects right now, come back later or maybe create one yourself:</p>
    <AddProjectButton />
  {:else}
    <AQTable
      currentPage={1}
      totalCount={$projects.data?.total_count ?? 0}
      rowsPerPage={5}
      showPagination={false}
      hardError={$projects.isError}
    >
      <svelte:fragment slot="header">
        <th>Title</th>
        <th>Status</th>
        <th>Publication name</th>
        <th>Deadline</th>
        <th>Description</th>
      </svelte:fragment>
      <svelte:fragment slot="body">
        {#each $projects.data?.items || [] as item (item.ref)}
          <tr
            style="cursor:pointer;"
            use:onClickAndKeydown={() => {
              goto(`/projects/public/${item.ref}`);
            }}
          >
            <td>{item.name}</td>
            <td>{PROJECT_STATUSES[item.status]}</td>
            <td>{item.outlet}</td>
            <td>{item.deadline ? toReadableDate(item.deadline) : 'No deadline'}</td>
            <td
              >{item.description?.slice(0, 75).trim()}{item.description?.length > 75 ? '...' : ''}
            </td>
          </tr>
        {/each}
      </svelte:fragment>
    </AQTable>
    <a href="/projects/public">
      <Button variant="outlined">
        <Label>All public projects</Label>
      </Button>
    </a>
  {/if}
</Content>
