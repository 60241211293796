<script lang="ts">
  import MainBorder from '$lib/components/MainBorder.svelte';
  import FieldBlock from '$lib/components/FieldBlock.svelte';
  import {
    deleteMyUserMutation,
    getMyEmailNotificationPreferences,
    setMyEmailNotificationPreferences
  } from '$lib/queries/settings';
  import { capitalise, debounce } from '$lib/utils';
  import Switch from '@smui/switch';
  import FormField from '@smui/form-field';
  import { onMount } from 'svelte';
  import { EmailNotificationGroupings, EmailNotificationKeyContent } from './constants';
  import * as auth from '$lib/auth';
  import LoadingButton from '$lib/components/LoadingButton.svelte';
  import Dialog, { Actions, Content, Title } from '@smui/dialog';
  import Button, { Label } from '@smui/button';
  import UserField from '$lib/components/UserTextField.svelte';

  let loaded = false;
  let password = '';
  let seeDeleteAccountModal = false;
  type SettingsEntries = Array<[string, boolean]>;
  let emailNotificationsMap: {
    projectEvents: SettingsEntries;
    reminders: SettingsEntries;
    others: SettingsEntries;
  } = { projectEvents: [], reminders: [], others: [] };
  let selectedEmailNotifications: string[] = [];
  const emailNotificationSettingsQuery = getMyEmailNotificationPreferences();
  $: notificationKeys = $emailNotificationSettingsQuery.data
    ? Object.keys($emailNotificationSettingsQuery.data)
    : [];
  $: if ($emailNotificationSettingsQuery.data && !loaded) {
    loaded = true;
    const emailNotificationsArray: SettingsEntries = Object.entries(
      $emailNotificationSettingsQuery.data
    );
    emailNotificationsMap = emailNotificationsArray.reduce(
      (acc, curr) => {
        const key = curr[0];
        if (EmailNotificationGroupings.projectEvents.has(key)) {
          acc.projectEvents.push(curr);
        } else if (EmailNotificationGroupings.reminders.has(key)) {
          acc.reminders.push(curr);
        } else {
          acc.others.push(curr);
        }
        return acc;
      },
      { projectEvents: [], reminders: [], others: [] } as typeof emailNotificationsMap
    );
    selectedEmailNotifications = emailNotificationsArray
      .filter(([_, val]) => val)
      .map(([key]) => key);
  }

  const groupingsAndTitle: Array<{ key: keyof typeof emailNotificationsMap; title: string }> = [
    { key: 'projectEvents', title: 'Project Events' },
    { key: 'reminders', title: 'Reminders' },
    { key: 'others', title: 'Other' }
  ];

  const deleteMutation = deleteMyUserMutation();
  const emailNotificationSettingsMutation = setMyEmailNotificationPreferences();
  const switchVisible = debounce(() => {
    const patchedNotifications = {
      ...notificationKeys.reduce((acc, curr) => {
        acc[curr] = false;
        return acc;
      }, {} as Record<string, false>),
      ...selectedEmailNotifications.reduce((acc, curr) => {
        acc[curr] = true;
        return acc;
      }, {} as Record<string, true>)
    };
    $emailNotificationSettingsMutation.mutate(patchedNotifications);
  }, 600);

  const toName = (key: string) => {
    return EmailNotificationKeyContent[key] ?? { title: capitalise(key), explanation: '' };
  };

  const deleteAccount = () => {
    $deleteMutation.mutate(password);
  };

  onMount(() => {
    loaded = false;
  });
</script>

<svelte:head>
  <title>Antiquoted | Settings</title>
</svelte:head>

<MainBorder>
  <FieldBlock>
    <h2>Your settings</h2>
  </FieldBlock>
  <FieldBlock loading={$emailNotificationSettingsQuery.isLoading}>
    <h4>Email Notifications</h4>
    <p>
      These email notifications are sent based on events happening within the application. We
      recommend that these are all kept on to ensure you don't miss anything, but if you'd prefer a
      different approach then feel free to tinker below.
    </p>

    {#each groupingsAndTitle as chunk}
      {#if emailNotificationsMap[chunk.key].length}
        <h5 class="mt-4 mb-1">{chunk.title}</h5>
      {/if}
      {#each emailNotificationsMap[chunk.key] as [key]}
        <FormField class="d-flex align-items-center pt-1" style="margin-bottom: .25em;">
          <Switch
            style="margin-left: 0;"
            value={key}
            on:SMUISwitch:change={switchVisible}
            bind:group={selectedEmailNotifications}
          />
          <span slot="label"><strong>{toName(key).title}: </strong>{toName(key).explanation}</span>
        </FormField>
      {/each}
    {/each}
  </FieldBlock>

  <FieldBlock
    loading={$emailNotificationSettingsQuery.isLoading}
    style="background-color: rgb(255, 0, 0, .1)"
  >
    <h4>Danger Zone</h4>
    <p>Be careful peeps, these procedures are permanent 😬😬😬</p>
    <LoadingButton
      loading={$deleteMutation.isPending}
      variant="raised"
      on:click={() => {
        seeDeleteAccountModal = true;
      }}
      >Delete my Account
    </LoadingButton>
  </FieldBlock>
</MainBorder>

<Dialog
  bind:open={seeDeleteAccountModal}
  aria-labelledby="Delete account modal"
  aria-describedby="This modal deletes the account"
>
  <Title id="simple-title">DANGER!</Title>
  <Content id="simple-content">
    <p>Are you <i>absolutely</i> sure you want to delete your account?</p>
    <p>There are no take-backs you know 😬... Submit this form and *poof* it's gone forever.</p>
    <p>
      This will delete everything related to your account that people can see on the site,
      including:
    </p>
    <ul>
      <li>Your profile</li>
      <li>Your projects</li>
      <li>Your messages</li>
      <li>Your personal info</li>
      <li>...etc</li>
    </ul>
    <p>
      Enter your password below in order to delete your account <br />
      <small>(sorry, we want to be super-sure it's you)</small>:
    </p>
    <UserField id="password" label="Password" bind:value={password} type="password" />
  </Content>
  <Actions>
    <Button on:click={() => (seeDeleteAccountModal = false)}>
      <Label>Back to safety</Label>
    </Button>
    <Button variant="raised" on:click={deleteAccount}>
      <Label>Delete it now!</Label>
    </Button>
  </Actions>
</Dialog>
